<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div class="mentee-listing-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="mentor.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <ng-container *ngIf="pageMode == 'mentor'">
                <div class="mentee-listing-section">
                    <div class="mentee-listing-details">
                        <div class="mentee-listing-details-title">Mentee Listing</div>
                        <div class="tab-group">
                            <button type="button" [class.active-tab]="currentTabMentor == 'prelims'" class="mentee-listing-details-tab-button" (click)="changeTabMentor('prelims')">Prelims</button>
                            <button type="button" [class.active-tab]="currentTabMentor == 'mains'" class="mentee-listing-details-tab-button" (click)="changeTabMentor('mains')">Mains</button>
                            <button type="button" [class.active-tab]="currentTabMentor == 'csat'" class="mentee-listing-details-tab-button" (click)="changeTabMentor('csat')">CSAT</button>
                        </div>
                    </div>

                    <ng-container *ngIf="currentTabMentor == 'prelims'">
                        <div class="mentees">
                            <ng-container *ngFor="let mentee of prelimsMenteeList; let i = index">
                                <div class="mentee">
                                    <div class="mentee-name">{{ mentee.name }}</div>
                                    <button type="button" class="mentee-button" (click)="openMentee(mentee, i)">View Details</button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentTabMentor == 'mains'">
                        <div class="mentees">
                            <ng-container *ngFor="let mentee of mainsMenteeList; let i = index">
                                <div class="mentee">
                                    <div class="mentee-name">{{ mentee.name }}</div>
                                    <button type="button" class="mentee-button" (click)="openMentee(mentee, i)">View Details</button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentTabMentor == 'csat'">
                        <div class="mentees">
                            <ng-container *ngFor="let mentee of csatMenteeList; let i = index">
                                <div class="mentee">
                                    <div class="mentee-name">{{ mentee.name }}</div>
                                    <button type="button" class="mentee-button" (click)="openMentee(mentee, i)">View Details</button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            
            <ng-container *ngIf="pageMode == 'mentee'">
                <div class="mentee-listing-section">
                    <div class="mentee-listing-details">
                        <div class="mentee-listing-details-title d-flex align-items-center">
                            <span class="material-symbols-outlined fw-bold" style="cursor: pointer;" (click)="closeMentee()">
                                keyboard_backspace
                            </span>
                            <div class="mx-3">
                                {{ mentee.name }}
                            </div>
                        </div>
                        <div class="tab-group">
                            <button type="button" [class.active-tab]="currentTabMentee == 'session'" class="mentee-listing-details-tab-button" (click)="changeTabMentee('session')">Session</button>
                            <button type="button" [class.active-tab]="currentTabMentee == 'study-plan'" class="mentee-listing-details-tab-button" (click)="changeTabMentee('study-plan')">Study Plan</button>
                            <button type="button" [class.active-tab]="currentTabMentee == 'doubts'" class="mentee-listing-details-tab-button" (click)="changeTabMentee('doubts')">Doubts</button>

                            <ng-container *ngIf="currentTabMentor == 'mains'">
                                <button type="button" [class.active-tab]="currentTabMentee == 'mains-copies'" class="mentee-listing-details-tab-button" (click)="changeTabMentee('mains-copies')">Mains Copies</button>
                            </ng-container>

                            <button type="button" [class.active-tab]="currentTabMentee == 'performance'" class="mentee-listing-details-tab-button" (click)="changeTabMentee('performance')">Performance</button>
                            <button type="button" [class.active-tab]="currentTabMentee == 'profile'" class="mentee-listing-details-tab-button" (click)="changeTabMentee('profile')">Profile</button>
                        </div>
                    </div>

                    <ng-container *ngIf="currentTabMentee == 'session'">
                        <ng-container *ngIf="currentSessionTab == 'sessions'">
                            <ng-container *ngIf="menteeSessionList.length">
                                <div class="fw-b h5">Review Meetings:</div><br/>
                            </ng-container>
                            <ng-container *ngFor="let session of menteeSessionList; let i = index">
                                <div class="session mb-5">
                                    <div class="mentor-image">
                                        <img [src]="session.student.profile_photo" alt="Mentor's Name">
                                    </div>

                                    <div class="session-details">
                                        <div class="session-text mb-2">{{ session.title }}</div>
                                        <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                        <div class="session-mode">
                                            <div class="session-text session-mode-text">Choose Mode: </div>
                                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                                <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                    {{ sessionModeFor }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div><br/>
                                        <div class="session-buttons">
                                            <button type="button" class="session-button mb-3 mb-md-0" (click)="editSession(i, 'review')">Edit</button>

                                            <ng-container *ngIf="!(session.analysis_report || session.analysis_report_file)">
                                                <button type="button" class="session-button" (click)="uploadReport(i, 'review')">Upload Report</button>
                                            </ng-container>
                                            <ng-container *ngIf="session.analysis_report || session.analysis_report_file">
                                                <button type="button" class="session-button uploaded-button">Upload Report</button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="menteeChatSessionList.length">
                                <div class="fw-b h5">Chat Sessions:</div><br/>
                            </ng-container>
                            <ng-container *ngFor="let session of menteeChatSessionList; let i = index">
                                <div class="session mb-5">
                                    <div class="mentor-image">
                                        <img [src]="session.student.profile_photo" alt="Mentor's Name">
                                    </div>

                                    <div class="session-details">
                                        <div class="session-text mb-2">{{ session.title }}</div>
                                        <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                        <div class="session-mode">
                                            <div class="session-text session-mode-text">Choose Mode: </div>
                                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                                <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                    {{ sessionModeFor }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div><br/>
                                        <div class="session-buttons">
                                            <button type="button" class="session-button mb-3 mb-md-0" (click)="editSession(i, 'chat')">Edit</button>

                                            <ng-container *ngIf="!(session.analysis_report || session.analysis_report_file)">
                                                <button type="button" class="session-button" (click)="uploadReport(i, 'chat')">Upload Report</button>
                                            </ng-container>
                                            <ng-container *ngIf="session.analysis_report || session.analysis_report_file">
                                                <button type="button" class="session-button uploaded-button">Upload Report</button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="d-flex justify-content-end">
                                <button type="button" class="add-new-session-button" (click)="currentSessionTab = 'add-session'">Add New Mentorship Session</button>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="currentSessionTab == 'add-session' || currentSessionTab == 'update-session'">
                            <div class="material-symbols-outlined fw-bold" style="cursor: pointer;" (click)="closeEditSession()">
                                keyboard_backspace
                            </div>
                            <div class="session-details-common-box">
                                <div class="session-details-common-box-title">Session Details</div>

                                <div class="session-details-common-box-input-fields">
                                    <div class="session-details-common-box-left-column">
                                        <ng-container *ngIf="currentSessionTab == 'add-session'">
                                            <div class="session-details-common-box-input-field">
                                                <input type="text" id="title" name="title" placeholder="Title" [(ngModel)]="sessionTitle">
                                            </div>
                                        </ng-container>
                                        <div class="session-details-common-box-input-field">
                                            <input type="date" id="date" name="date" placeholder="date" [(ngModel)]="sessionDate">
                                        </div>
                                        <div class="session-details-common-box-input-field">
                                            <input type="time" id="time" name="time" placeholder="time" [(ngModel)]="sessionTime">
                                        </div>
                                    </div>
                                    <div class="session-details-common-box-right-column">
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="currentSessionTab == 'add-session'">
                                <button type="button" class="btn btn-light submit-details-button" (click)="addSession()">Add</button>
                            </ng-container>
                            <ng-container *ngIf="currentSessionTab == 'update-session'">
                                <button type="button" class="btn btn-light submit-details-button" (click)="updateSession()">Update</button>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentTabMentee == 'study-plan'">
                        <ng-container *ngIf="currentStudyPlanTab == 'plan'">
                            <ng-container *ngFor="let task of menteeTaskList, let i = index">
                                <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                    <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                    <div class="milestone-date">Upto {{ task.reading_end | date:'d MMM, y' }}</div>
                                </div>
                                <ng-container *ngIf="task.isOpen">
                                    <div class="microtargets">
                                        <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                            <div class="microtarget">
                                                <div class="microtarget-details">
                                                    <div class="microtarget-serial-number" style="cursor: pointer;">MT - {{ j + 1 }}</div>
                                                    <div class="microtarget-title">{{ subtask.subtopic.name }}</div>
                                                </div>
                                                <div class="microtarget-dates">
                                                    <div class="microtarget-date">{{ subtask.reading_end | date:'d MMM, y' }}</div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>

                                <div class="milestone-meet">
                                    <div class="milestone-meet-title">{{ menteeSessionList[i].title }} - {{ i + 1 }} </div>
                                    <div class="milestone-meet-date">{{ menteeSessionList[i].date | date:'d MMM, y' }}</div>
                                </div>
                            </ng-container>

                            <br/><br/>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="add-new-session-button" (click)="addPlanButtonClicked()">Add New Milestone</button>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="currentStudyPlanTab == 'add-plan'">
                            <div class="material-symbols-outlined fw-bold" style="cursor: pointer;" (click)="closeAddPlan()">
                                keyboard_backspace
                            </div>
                            <div class="session-details-common-box basic-details">
                                <div class="session-details-common-box-title">Session Details</div>

                                <div class="session-details-common-box-input-fields">
                                    <div class="session-details-common-box-left-column">
                                        <div class="session-details-common-box-input-field">
                                            <div class="dropdown">
                                                <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {{ planTopic }}
                                                </div>
                                                <div class="dropdown-menu">
                                                    <ng-container *ngFor="let planTopicFor of planTopicValueList; let topicI = index">
                                                        <div class="dropdown-item" (click)="planTopic = planTopicFor; planTopicId = planTopicIdList[topicI];">{{ planTopicFor }}</div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="fw-bold">Reading Start Date</div>
                                        <div class="session-details-common-box-input-field">
                                            <input type="date" id="date" name="date" placeholder="date" [(ngModel)]="planReadingStartDate">
                                        </div>

                                        <div class="fw-bold">Question Start Date</div>
                                        <div class="session-details-common-box-input-field">
                                            <input type="date" id="date" name="date" placeholder="date" [(ngModel)]="planQuestionsStartDate">
                                        </div>
                                    </div>

                                    <div class="session-details-common-box-right-column">
                                        <div class="session-details-common-box-input-field">
                                            <input type="number" id="order" name="order" placeholder="Order" [(ngModel)]="planOrder">
                                        </div>

                                        <div class="fw-bold">Reading End Date</div>
                                        <div class="session-details-common-box-input-field">
                                            <input type="date" id="date" name="date" placeholder="date" [(ngModel)]="planReadingEndDate">
                                        </div>

                                        <div class="fw-bold">Question End Date</div>
                                        <div class="session-details-common-box-input-field">
                                            <input type="date" id="date" name="date" placeholder="date" [(ngModel)]="planQuestionsEndDate">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-light submit-details-button" (click)="addPlan()">Add</button>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentTabMentee == 'doubts'">
                        <div class="chat-section">
                            <div class="chat-title-section">
                                <div class="chat-title">
                                    Ask your Doubts
                                </div>
                            </div>
    
                            <div class="chats">
                                <ng-container *ngFor="let chat of menteeChatList; let i = index">
                                    <ng-container *ngIf="chat.sender != mentee.user.id">
                                        <div class="chat">
                                            <div class="chat-sender">
                                                <div class="chat-sender-image">
                                                    <img [src]="getImageLink(mentor.profile_photo)" alt="Mentee's Name">
                                                </div>
                                                <div class="">{{ mentor.name }}</div>
                                            </div>
                                            <div class="chat-message">
                                                <div class="">
                                                    {{ chat.content }}
                                                </div>
                                            </div>
                                            <div class="chat-blank"></div>
                                        </div>
                                    </ng-container>
    
                                    <ng-container *ngIf="chat.sender == mentee.user.id">
                                        <div class="chat">
                                            <div class="chat-blank"></div>
                                            <div class="chat-message">
                                                <div class="">
                                                    {{ chat.content }}
                                                </div>
                                            </div>
                                            <div class="chat-sender">
                                                <div class="chat-sender-image">
                                                    <img [src]="mentee.profile_photo" alt="Mentor's Name">
                                                </div>
                                                <div class="">{{ mentee.name }}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
    
                                <div class="chat">
                                    <div class="chat-sender">
                                        <div class="chat-sender-image">
                                            <img [src]="getImageLink(mentor.profile_photo)" alt="Mentee's Name">
                                        </div>
                                        <div class="">{{ mentor.name }}</div>
                                    </div>
                                    <div class="chat-message">
                                        <input type="text" id="chatMessage" name="chatMessage" placeholder="Typing..." [(ngModel)]="chatMessage">
                                    </div>
                                    <div class="send-icon" (click)="sendMessage()">
                                        <div class="material-symbols-outlined">
                                            send
                                        </div>
                                    </div>
                                    <div class="chat-blank d-none d-md-block"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentTabMentee == 'mains-copies'">
                        <div class="copies">
                            <ng-container *ngFor="let copy of menteeMainsCopyList; let i = index">
                                <div class="copy">
                                    <div class="copy-details">{{ copy.datetime | date:'d MMM, y' }}</div>
                                    <div class="copy-details" style="flex-basis: 20%;">{{ copy.question.subtopic.topic.name }}</div>
                                    <div class="copy-details" style="flex-basis: 20%;">{{ copy.question.subtopic.name }}</div>
                                    <button type="button" class="copy-button uploaded-button" (click)="downloadAnswer(copy)">Download</button>

                                    <ng-container *ngIf="copy.evaluated_copy || copy.evaluated_file">
                                        <button type="button" class="copy-button upload-button uploaded-button">Upload</button>
                                    </ng-container>
                                    <ng-container *ngIf="!(copy.evaluated_copy || copy.evaluated_file)">
                                        <button type="button" class="copy-button upload-button" (click)="uploadAnswerDialog(i)">Upload</button>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentTabMentee == 'performance'">
                        <ng-container *ngFor="let task of menteeTaskList, let i = index">
                            <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                <div class="progress milestone-progress">
                                    <div class="progress-bar milestone-progress-bar bg-dark" role="progressbar" [ngStyle]="{'width': menteeTopicProgressList[i].score + '%'}" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{ menteeTopicProgressList[i].score.toFixed(2) }}%</div>
                                </div>
                            </div>
                            <ng-container *ngIf="task.isOpen">
                                <div class="graph cdk-virtual-scroll-data-source-example">
                                    <app-chart [option]="getLinearOption(i)"></app-chart>
                                </div>

                                <div class="graph cdk-virtual-scroll-data-source-example">
                                    <app-chart [option]="getPieOption(i)"></app-chart>
                                </div>

                                <div class="topics-tab-group">
                                    <div class="progress-report-topics strong-topics">
                                        <div class="topic-category">
                                            <div class="">Strong Topics</div>
                                        </div><br/>
                                        <ol>
                                            <ng-container *ngFor="let topic of menteeWeakTopicSummaryDetails[i].greater_than_60_percent_subtopic_list">
                                                <li>{{ topic }}</li>
                                            </ng-container>
                                        </ol> 
                                    </div>
                                    <div class="progress-report-topics rivision-topics">
                                        <div class="topic-category">
                                            <div class="">Rivision Topics</div>
                                        </div><br/>
                                        <ol>
                                            <ng-container *ngFor="let topic of menteeWeakTopicSummaryDetails[i].greater_than_equal_to_40_less_than_60_subtopic_list">
                                                <li>{{ topic }}</li>
                                            </ng-container>
                                        </ol>
                                    </div>
                                    <div class="progress-report-topics weak-topics">
                                        <div class="topic-category">
                                            <div class="">Weak Topics</div>
                                        </div><br/>
                                        <ol>
                                            <ng-container *ngFor="let topic of menteeWeakTopicSummaryDetails[i].less_than_40_percent_subtopic_list">
                                                <li>{{ topic }}</li>
                                            </ng-container>
                                        </ol>
                                    </div>
                                </div>
                            </ng-container>
    
                            <div class="milestone-meet">
                                <div class="milestone-meet-title">{{ menteeSessionList[i].title }} - {{ i + 1 }}</div>
                                <div class="milestone-meet-date">{{ menteeSessionList[i].date | date:'d MMM, y' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentTabMentee == 'profile'">
                        <div class="mentee-details">
                            <div class="mentee-image">
                                <img [src]="mentee.profile_photo" alt="Mentee">
                            </div>
                            <div class="mentee-description">
                                <div class="mentee-name-roll">
                                    <div class="mentee-profile-name text-capitalize">{{ mentee.name }}</div>
                                    <div class="mentee-roll">Roll No. - 1347298</div>
                                </div>
                            </div>
                        </div>

                        <div class="mentee-details-common-box basic-details">
                            <div class="mentee-details-common-box-title">Basic Details</div>

                            <div class="mentee-details-common-box-input-fields">
                                <div class="mentee-details-common-box-left-column">
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Name</div>
                                        <div class="input">
                                            {{ mentee.name }}
                                            <ng-container *ngIf="!mentee.name">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Mobile No.</div>
                                        <div class="input">
                                            {{ mentee.mobile_no }}
                                            <ng-container *ngIf="!mentee.mobile_no">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Email</div>
                                        <div class="input">
                                            {{ mentee.user.email }}
                                            <ng-container *ngIf="!mentee.user.email">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Category</div>
                                        <div class="input">
                                            {{ mentee.category }}
                                            <ng-container *ngIf="!mentee.category">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="mentee-details-common-box-right-column">
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Date of Birth</div>
                                        <div class="input">
                                            {{ mentee.dob }}
                                            <ng-container *ngIf="!mentee.dob">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Gender</div>
                                        <div class="input">
                                            {{ mentee.gender }}
                                            <ng-container *ngIf="!mentee.gender">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Residence State</div>
                                        <div class="input">
                                            {{ mentee.residence_state }}
                                            <ng-container *ngIf="!mentee.residence_state">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="mentee-details-common-box upsc-journey">
                            <div class="mentee-details-common-box-title">UPSC Journey</div>
        
                            <div class="mentee-details-common-box-input-fields">
                                <div class="mentee-details-common-box-left-column">
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Preparation Started</div>
                                        <div class="input">
                                            {{ mentee.preparation_started }}
                                            <ng-container *ngIf="!mentee.preparation_started">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Current Level of Preparation</div>
                                        <div class="input">
                                            <ng-container *ngIf="!mentee.current_level_of_preparation.length">
                                                -
                                            </ng-container>
                                            <ol *ngIf="mentee.current_level_of_preparation.length">
                                                <ng-container *ngFor="let weakSubjectAreaFor of mentee.current_level_of_preparation">
                                                    <li>{{ weakSubjectAreaFor.name }}</li>
                                                </ng-container>
                                            </ol>
                                        </div>
                                    </div>
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Any Coacing Joined</div>
                                        <div class="input">
                                            {{ mentee.any_coaching_joined }}
                                            <ng-container *ngIf="!mentee.any_coaching_joined">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="mentee-details-common-box-right-column">
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Optional Subject</div>
                                        <div class="input">
                                            {{ mentee.optional_subject }}
                                            <ng-container *ngIf="!mentee.optional_subject">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
        
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Number of Attempts</div>
                                        <div class="input">
                                            {{ mentee.no_of_attempts }}
                                            <ng-container *ngIf="!mentee.no_of_attempts">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
        
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Next Exam Attempt</div>
                                        <div class="input">
                                            {{ mentee.next_exam_attempt }}
                                            <ng-container *ngIf="!mentee.next_exam_attempt">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
        
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Number of Prelims Attempt</div>
                                        <div class="input">
                                            {{ mentee.no_of_prelims_qualified }}
                                            <ng-container *ngIf="!mentee.no_of_prelims_qualified">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
        
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Number of Mains Attempt</div>
                                        <div class="input">
                                            {{ mentee.no_of_mains_qualified }}
                                            <ng-container *ngIf="!mentee.no_of_mains_qualified">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Weak Subject Area</div>
                                        <div class="input">
                                            <ng-container *ngIf="!mentee.weak_subject_areas.length">
                                                -
                                            </ng-container>
                                            <ol *ngIf="mentee.weak_subject_areas.length">
                                                <ng-container *ngFor="let weakSubjectAreaFor of mentee.weak_subject_areas">
                                                    <li>{{ weakSubjectAreaFor.name }}</li>
                                                </ng-container>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="mentee-details-common-box education-history">
                            <div class="mentee-details-common-box-title">Education History</div>
        
                            <div class="mentee-details-common-box-input-fields">
                                <div class="mentee-details-common-box-left-column">
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Graduation Year</div>
                                        <div class="input">
                                            {{ mentee.graduation_year }}
                                            <ng-container *ngIf="!mentee.graduation_year">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Specialised Subject</div>
                                        <div class="input">
                                            {{ mentee.specialised_subject }}
                                            <ng-container *ngIf="!mentee.specialised_subject">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="mentee-details-common-box-right-column">
                                    <div class="mentee-details-common-box-input-field">
                                        <div class="">Graduation Background</div>
                                        <div class="input">
                                            {{ mentee.graduation_background }}
                                            <ng-container *ngIf="!mentee.graduation_background">
                                                -
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>