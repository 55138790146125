<div class="terms-of-use">
    <div class="title">
        Terms Of Use
    </div><br/>

    <div class="">
        <b>About MentorsHut</b><br/>
        MentorsHut provide mentorship to the students for various competitive exams after the purchase of subscription plan. 
        Each students is provided a 3-Tier mentorship under which students are provided Personalised mentors for daily monitoring of targets, subject-wise Expert Faculties for clearing the students doubts and then at the last level various topper strategy sessions. <br/><br/>

        <b>Limitation of Liability</b><br/>
        While MentQuest Pvt. Ltd. uses reasonable efforts to include accurate advice by the mentors on this website, errors or omissions sometimes occur. 
        MentQuest Pvt. Ltd. makes no warranties or representations as to the accuracy of the mentors advice given on this website. 
        Under no circumstances, including, but not limited to, negligence, shall MentQuest Pvt. Ltd. or any party involved in creating, producing, or delivering the website be liable to you for any direct, incidental, consequential, indirect, or punitive damages that result from the use of, or the inability to use, the advice given by the mentors or the materials on this websites. 
        In no event shall MentQuest Pvt. Ltd. be totally liable to you for all damages, losses, and causes of action exceed the amount paid by you, if any, for accessing this website.<br/><br/>

        MentQuest Pvt. Ltd. also assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer equipment or other property as a result of your access to, use of, or browsing the website or your downloading of any material, data, text, images, video, or audio from the website.<br/><br/>

        <b>Restrictions on Use of Materials</b><br/>
        This website is owned and operated by MentQuest Pvt. Ltd. 
        Except as otherwise expressly permitted by MentQuest Pvt. Ltd. , no material from this website or any website owned, operated, licensed or controlled by MentQuest Pvt. Ltd. may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way. 
        You may download material displayed on this website for your use only, provided that you also retain the clauses pertaining to all copyright and other proprietary notices contained in the material. 
        You shall not distribute, modify, transmit, reuse, repost, or use the content of this website for public or commercial purposes, including text, images, audio, and video without the written permission of MentQuest Pvt. Ltd. 

        MentQuest Pvt. Ltd. neither warrants nor represents that your use of materials displayed on this website will not infringe rights of third parties not owned by or affiliated with MentQuest Pvt. Ltd. <br/><br/>

        <b>Jurisdictional Issues</b>
        Unless otherwise expressly set forth herein, MentQuest Pvt. Ltd.  makes no representation that materials on this website are appropriate or available for use in any location. 
        Those who choose to access this website do so on their own initiative and are responsible for compliance with local laws.<br/><br/>
        
        All disputes shall be subject to the exclusive jurisdiction of New Delhi courts.
    </div>
</div>