import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlogServiceAdapter } from './blog.serviceadapter';
import { Blog } from '../common-classes/blog';
import { ActivatedRoute, Router } from '@angular/router';
import { FRONTEND } from '../environment/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MarketingText } from '../common-classes/marketing-text';
import { MarketingImage } from '../common-classes/marketing-image';
import { DJANGO_SERVER } from 'src/app/environment/environment';


@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

    isLoading: boolean = false;
    user: any;
    
    name: string = "";
    mobileNumber: string = "";
    emailId: string = "";
    examYearDefault: string = "Exam Year";
    examYear: string = this.examYearDefault;
    examYearList: any = [];

    maxBlogsPerPage: number = 3;
    currentPage: number = 1;
    totalPages: number = 0;
    isBlogOpened: boolean = false;
    blogOpenedIndex: number = 0;
    openedBlog: Blog = new Blog();
    blogList: Blog[] = [];
    recentBlogList: Blog[] = [];
    trendingBlogList: Blog[] = [];

    marketingAddList: MarketingText[] = [];
    marketingImageList: MarketingImage[] = [];

    isLoggedIn: boolean = false;
    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new BlogServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();

        this.initializeExamYearList();
    }

    initializeExamYearList(): void {
        let currentYear = (new Date()).getFullYear();
        for(let i = 0; i < 10; i++) {
            let newYear = currentYear + i;
            this.examYearList.push(newYear.toString());
        }
    }

    validateMobileNumber(mobileNumber: string) {
        const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        return regex.test(mobileNumber);
    }

    validateEmail(email: string) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    submitUserDetails(): void {
        // console.log("Name: ", this.name);
        // console.log("Mobile Number: ", this.mobileNumber);
        // console.log("Email Id: ", this.emailId);
        // console.log("Exam Year: ", this.examYear);

        this.name = this.name.trim();
        this.mobileNumber = this.mobileNumber.trim();
        this.emailId = this.emailId.trim();
        this.examYear = this.examYear.trim();

        if (!this.name) {
            alert("Please enter valid name.");
            return;
        }
        if (!this.validateMobileNumber(this.mobileNumber)) {
            alert("Please enter valid mobile number.");
            return;
        }
        if (!this.validateEmail(this.emailId)) {
            alert("Please enter valid email id.");
            return;
        }
        if (this.examYear == this.examYearDefault) {
            alert("Please enter valid exam year.");
            return;
        }
        this.serviceAdapter.submitUserDetails();
    }

    getWrappedBlogDescription(description: string): string {
        let newDescription = "";
        for (let i = 0; i < Math.min(150, description.length); i++) {
            newDescription += description[i];
        }
        for (let i = 0; i < 50; i++) {
            newDescription += "-";
        }
        return newDescription;
    }

    getSafeBlogDescription(description: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(description);
    }

    openBlog(blogId: number): void {
        window.open(FRONTEND + '/blog?id=' + blogId, '_blank');
    }

    closeBlog(): void {
        this.blogOpenedIndex = 0;
        this.isBlogOpened = false;
    }

    setCurrentPage(currentPage: number) {
        this.currentPage = currentPage;
    }

    previousPage() {
        if (this.currentPage > 1) {
            this.currentPage -= 1;
        }
    }

    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage += 1;
        }
    }
    
    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }



    getImageLink(image_link: string): string {
        return DJANGO_SERVER + image_link
    }

    openImageLink(add: MarketingImage): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }
}
