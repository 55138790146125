// ---> For Test
// export const DJANGO_SERVER = 'http://127.0.0.1:8000';
// export const GLOGIN_SECRETS = '394306901565-k1782kt2380u9pcm8hjf35fcjti66642';
// export const WEBSOCKET_DOMAIN = 'ws://127.0.0.1:8000';
// export const FRONTEND = 'http://localhost:4200';

// ---> For Production
export const DJANGO_SERVER = 'https://api.mentorshut.com';
export const GLOGIN_SECRETS = '245243900537-f2a7mntcuitsar2qj7ted1urtg8tds0t.apps.googleusercontent.com';
export const WEBSOCKET_DOMAIN = 'wss://api.mentorshut.com';
export const FRONTEND = 'https://mentorshut.com';
