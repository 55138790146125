import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { CountAllRoutingModule } from './count-all.routing';
import { BlogComponent } from './blog.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BasicComponentsModule } from '../basic-components/basic-components.module';

@NgModule({
    declarations: [BlogComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        BasicComponentsModule
    ],
    exports: [
        BlogComponent,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule
    ],
    providers: [],
    entryComponents: [],
})
export class BlogModule {}
