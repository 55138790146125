import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-submit-answer-modal',
    templateUrl: './submit-answer-modal.component.html',
    styleUrls: ['./submit-answer-modal.component.css']
})
export class SubmitAnswerModalComponent {
    link: string = "";
    file: any;
    constructor(
        public dialogRef: MatDialogRef<SubmitAnswerModalComponent>,
    ) {}

    submitModal(): void {
        this.dialogRef.close({file: this.file});
    }

    onFileSelected(event: any): void {
        const newFile: File = event.target.files[0];
        if (newFile) {
            this.file = newFile;
        }
    }
}
