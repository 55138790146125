import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DemoSessionServiceAdapter } from './demo-session.serviceadapter';
import { Router } from '@angular/router';
import { MarketingText } from '../common-classes/marketing-text';
import { Testimonial } from '../common-classes/testimonial';
import { DJANGO_SERVER } from '../environment/environment';


@Component({
    selector: 'app-demo-session',
    templateUrl: './demo-session.component.html',
    styleUrls: ['./demo-session.component.css']
})
export class DemoSessionComponent implements OnInit, OnDestroy {

    isLoading: boolean = true;
    isLoggedIn: boolean = false;
    user: any;

    name: string = "";
    mobileNumber: string = "";
    emailId: string = "";

    examYearDefault: string = "Exam Year";
    examYear: string = this.examYearDefault;
    examYearList: any = [];
    serviceAdapter: any;
    marketingAddList: MarketingText[] = [];
    testimonialList: Testimonial[] = [];

    interval: any;
    currentIndex = 0;

    images = [
        {
            url: '../../assets/Images/Home_Page_Image_1.png',
            text: 'India’s First MenTech platform for civil service exam that brings mentors and technology together in providing Personalised, Real Time and Objective Mentorship.',
        },
        {
            url: '../../assets/Images/Home_Page_Image_2.png',
            text: '3-Tier Mentorship: Mentors, Expert Faculties and Toppers coming together to give a holistic and most practical mentorship.',
        },
        {
            url: '../../assets/Images/Home_Page_Image_3.png',
            text: 'Real-Time Mentorship: Daily monitoring of targets along with the accuracy of students with respect to the syllabus.',
        },
        {
            url: '../../assets/Images/Home_Page_Image_4.png',
            text: 'Personalised Time Table: Designed to meet the unique needs of a student according to her/his strengths and weakness.',
        },
        {
            url: '../../assets/Images/Home_Page_Image_5.png',
            text: 'Question Bank: 1000s of questions mapped to the syllabus are provided for the assessment of student preparation.',
        },
    ];

    constructor(
        private http: HttpClient,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new DemoSessionServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
        this.initializeExamYearList();
        this.startSlider();
    }

    ngOnDestroy(): void {
        this.stopSlider();
    }

    startSlider() {
        this.interval = setInterval(() => {
            this.nextSlide();
        }, 3000); // Adjust the interval time (in milliseconds) as per your preference
    }

    stopSlider() {
        clearInterval(this.interval);
    }

    prevSlide() {
        const sliderContainer = document.querySelector('.slider-container') as HTMLElement;
        const activeSlide = document.querySelector('.slider-item.active') as HTMLElement;

        if (activeSlide.previousElementSibling) {
            activeSlide.classList.remove('active');
            activeSlide.previousElementSibling.classList.add('active');
            this.currentIndex =
                Array.from(sliderContainer.children).indexOf(
                    activeSlide.previousElementSibling
                );
            sliderContainer.style.transform = `translateX(-${this.currentIndex * 100}%)`;
        } else {
            const lastSlide = sliderContainer.lastElementChild as HTMLElement;
            activeSlide.classList.remove('active');
            lastSlide.classList.add('active');
            this.currentIndex = Array.from(sliderContainer.children).indexOf(lastSlide);
            sliderContainer.style.transform = `translateX(-${this.currentIndex * 100}%)`;
        }
    }

    nextSlide() {
        const sliderContainer = document.querySelector('.slider-container') as HTMLElement;
        const activeSlide = document.querySelector('.slider-item.active') as HTMLElement;

        if (activeSlide.nextElementSibling) {
            activeSlide.classList.remove('active');
            activeSlide.nextElementSibling.classList.add('active');
            this.currentIndex =
                Array.from(sliderContainer.children).indexOf(
                    activeSlide.nextElementSibling
                );
            sliderContainer.style.transform = `translateX(-${this.currentIndex * 100}%)`;
        } else {
            const firstSlide = sliderContainer.firstElementChild as HTMLElement;
            activeSlide.classList.remove('active');
            firstSlide.classList.add('active');
            this.currentIndex = Array.from(sliderContainer.children).indexOf(firstSlide);
            sliderContainer.style.transform = `translateX(-${this.currentIndex * 100}%)`;
        }
    }

    initializeExamYearList(): void {
        let currentYear = (new Date()).getFullYear();
        for (let i = 0; i < 10; i++) {
            let newYear = currentYear + i;
            this.examYearList.push(newYear.toString());
        }
    }

    validateMobileNumber(mobileNumber: string) {
        const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        return regex.test(mobileNumber);
    }

    validateEmail(email: string) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    navigateToPricing() {
        this.router.navigate(['/'], { queryParams: { id: "subscription-plans" } });
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    submitUserDetails(): void {
        this.name = this.name.trim();
        this.mobileNumber = this.mobileNumber.trim();
        this.emailId = this.emailId.trim();
        this.examYear = this.examYear.trim();

        if (!this.name) {
            alert("Please enter valid name.");
            return;
        }
        if (!this.validateMobileNumber(this.mobileNumber)) {
            alert("Please enter valid mobile number.");
            return;
        }
        if (!this.validateEmail(this.emailId)) {
            alert("Please enter valid email id.");
            return;
        }
        if (this.examYear == this.examYearDefault) {
            alert("Please enter valid exam year.");
            return;
        }
        this.serviceAdapter.submitUserDetails();
    }

    getTestimonialImage(imageLink: string): string {
        return DJANGO_SERVER + imageLink;
    }
}
