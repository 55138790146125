<!-- Sidebar for Desktop View -->
<div class="sidebar">
    <div class="home-tab" [class.active-tab]="currentSidebarTab == ''">
        <div class="material-icons tab-icon">home</div>
        <div class="tab-title" (click)="changeSidebarTab('/')">Home</div>
    </div>

    <div class="sidebar-title">MY ACCOUNT</div>

    <ng-container *ngIf="userType == 'Student'">
        <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'mentorship'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('mentorship/')">Mentorship</div>
        </div>
        <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'study-plan'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('study-plan/')">Study Plan</div>
        </div>
        <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'question-bank'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('question-bank/')">Question Bank</div>
        </div>
        <!-- <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'study-material'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('study-material/')">Study Material</div>
        </div> -->
        <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'progress-report'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('progress-report/')">Progress Report</div>
        </div>
        <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'view-plans'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('view-plans/')">View Plans</div>
        </div>
    </ng-container>

    <ng-container *ngIf="userType == 'Mentor'">
        <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'upcoming-sessions'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('upcoming-sessions/')">Mentorship Sessions</div>
        </div>
        <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'mentee-listing'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('mentee-listing/')">Mentee Listing</div>
        </div>
        <!-- <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'doubts'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('doubts/')">Doubts</div>
        </div> -->
        <div class="sidebar-tab" [class.active-tab]="currentSidebarTab == 'mains-copies'">
            <div class="material-icons tab-icon">home</div>
            <div class="tab-title" (click)="changeSidebarTab('mains-copies/')">Mains Copies</div>
        </div>
    </ng-container>
</div>
