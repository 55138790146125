import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component';
import { BlogComponent } from './blog/blog.component';
import { DemoSessionComponent } from './demo-session/demo-session.component';
import { MenteeProfileComponent } from './mentee-profile/mentee-profile.component';
import { MentorsPageComponent } from './mentors-page/mentors-page.component';
import { MentorshipComponent } from './tabs/mentorship/mentorship.component';
import { StudyPlanComponent } from './tabs/study-plan/study-plan.component';
import { QuestionBankComponent } from './tabs/question-bank/question-bank.component';
import { ProgressReportComponent } from './tabs/progress-report/progress-report.component';
import { ViewPlansComponent } from './tabs/view-plans/view-plans.component';
import { ChartComponent } from './basic-components/chart/chart.component';
import { MentorProfileComponent } from './mentor-profile/mentor-profile.component';

import { UpcomingSessionsComponent } from './tabs/upcoming-sessions/upcoming-sessions.component';
import { MenteeListingComponent } from './tabs/mentee-listing/mentee-listing.component';
import { MainsCopiesComponent } from './tabs/mains-copies/mains-copies.component';
import { ResourcesComponent } from './resources/resources.component';
import { RefundPolicyComponent } from './basic-components/refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './basic-components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './basic-components/terms-of-use/terms-of-use.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
    { path: '', redirectTo: 'upsc', pathMatch: 'full' },
    { path: 'upsc', component: HomepageComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'demo-session', component: DemoSessionComponent },
    { path: 'mentee-profile', component: MenteeProfileComponent },
    { path: 'mentors-page', component: MentorsPageComponent },
    { path: 'mentorship', component: MentorshipComponent },
    { path: 'study-plan', component: StudyPlanComponent },
    { path: 'question-bank', component: QuestionBankComponent },
    { path: 'progress-report', component: ProgressReportComponent },
    { path: 'view-plans', component: ViewPlansComponent },
    { path: 'chart', component: ChartComponent },
    { path: 'mentor-profile', component: MentorProfileComponent },
    { path: 'upcoming-sessions', component: UpcomingSessionsComponent },
    { path: 'mentee-listing', component: MenteeListingComponent },
    { path: 'mains-copies', component: MainsCopiesComponent },
    { path: 'resources', component: ResourcesComponent },
    { path: 'refund-policy', component: RefundPolicyComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-of-use', component: TermsOfUseComponent },
    { path: 'login', component: LoginComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
