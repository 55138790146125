<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="menteeDetails.profile_photo"></app-navbar>
    </ng-container>
    <ng-container *ngIf="!isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
    </ng-container>
    <div class="mentee-profile-page">
        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <div class="sidebar">
                <div class="home-tab">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="navigateToHome()">Home</div>
                </div>

                <div class="d-none d-md-block sidebar-title">MY ACCOUNT</div>
                <div class="d-none d-md-flex sidebar-tab active-tab">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="scrollTo('basic-details')">Basic Details</div>
                </div>
                <div class="d-none d-md-flex sidebar-tab">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="scrollTo('upsc-journey')">UPSC Journey</div>
                </div>
                <div class="d-none d-md-flex sidebar-tab">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="scrollTo('education-history')">Education History</div>
                </div>
                <div class="d-none d-md-flex sidebar-tab">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="scrollTo('my-subscription')">My Subscription</div>
                </div>
            </div>

            <div class="profile-section">
                <div class="mentee-details">
                    <div class="mentee-image">
                        <img src="{{menteeDetails.profile_photo}}" alt="Mentee profile">
                    </div>
                    <div class="mentee-description">
                        <div class="mentee-name-roll">
                            <div class="mentee-name text-capitalize">{{ menteeDetails.name }}</div>
                            <div class="mentee-roll">Roll No. - {{ rollNumber + menteeDetails.id }}</div>
                        </div>
                        <!-- <button type="button" class="edit-profile-button" name="button">Edit Profile Picture</button> -->
                    </div>
                </div>

                <div class="mentee-details-common-box basic-details">
                    <div class="mentee-details-common-box-title">Basic Details</div>

                    <div class="mentee-details-common-box-input-fields">
                        <div class="mentee-details-common-box-left-column">
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Name</div>
                                <input type="text" id="name" name="name" placeholder="Name" [(ngModel)]="menteeDetails.name">
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Mobile No.</div>
                                <input type="text" id="phone-no" name="phone-no" placeholder="Phone No." [(ngModel)]="menteeDetails.mobile_no">
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Email</div>
                                <div class="input">{{ menteeDetails.user.email }}</div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Category</div>
                                <input type="text" id="category" name="category" placeholder="Category" [(ngModel)]="menteeDetails.category">
                            </div>
                        </div>

                        <div class="mentee-details-common-box-right-column">
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Date of Birth</div>
                                <input type="date" id="dob" name="dob" placeholder="Date of Birth" [(ngModel)]="menteeDetails.dob">
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Gender</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.gender }}
                                        <ng-container *ngIf="!menteeDetails.gender">{{ genderDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let genderFor of genderValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.gender = genderFor">{{ genderFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Residence State</div>
                                <input type="text" id="residence-state" name="residence-state" placeholder="Residence State" [(ngModel)]="menteeDetails.residence_state">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mentee-details-common-box upsc-journey">
                    <div class="mentee-details-common-box-title">UPSC Journey</div>

                    <div class="mentee-details-common-box-input-fields">
                        <div class="mentee-details-common-box-left-column">
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Preparation Started</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.preparation_started }}
                                        <ng-container *ngIf="!menteeDetails.preparation_started">{{ preparationStartedDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let preparationStartedFor of preparationStartedValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.preparation_started = preparationStartedFor">{{ preparationStartedFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Current Level of Preparation</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ currentPreparationLevelDefaultValue }}
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let currentPreparationLevelFor of currentPreparationLevelValueList">
                                            <mat-checkbox class="checkbox" [(ngModel)]="currentPreparationLevelFor.isSelected">{{ currentPreparationLevelFor.value }}</mat-checkbox><br />
                                        </ng-container>
                                    </div>
                                </div><br *ngIf="showBrTag(currentPreparationLevelValueList)"/>
                                <ol>
                                    <ng-container *ngFor="let currentPreparationLevelFor of currentPreparationLevelValueList">
                                        <li *ngIf="currentPreparationLevelFor.isSelected">{{ currentPreparationLevelFor.value }}</li>
                                    </ng-container>
                                </ol>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Any Coacing Joined</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.any_coaching_joined }}
                                        <ng-container *ngIf="!menteeDetails.any_coaching_joined">{{ anyCoachingJoinedDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let anyCoachingJoinedFor of anyCoachingJoinedValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.any_coaching_joined = anyCoachingJoinedFor">{{ anyCoachingJoinedFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Optional Subject</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.optional_subject }}
                                        <ng-container *ngIf="!menteeDetails.optional_subject">{{ optionalSubjectDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let optionalSubjectFor of optionalSubjectValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.optional_subject = optionalSubjectFor">{{ optionalSubjectFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mentee-details-common-box-right-column">
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Number of Attempts</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.no_of_attempts }}
                                        <ng-container *ngIf="!menteeDetails.no_of_attempts">{{ numberOfAttemptsDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let numberOfAttemptsFor of numberOfAttemptsValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.no_of_attempts = numberOfAttemptsFor">{{ numberOfAttemptsFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Next Exam Attempt</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.next_exam_attempt }}
                                        <ng-container *ngIf="!menteeDetails.next_exam_attempt">{{ nextExamAttemptDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let nextExamAttemptFor of nextExamAttemptValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.next_exam_attempt = nextExamAttemptFor">{{ nextExamAttemptFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Number of Prelims Attempt</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.no_of_prelims_qualified }}
                                        <ng-container *ngIf="!menteeDetails.no_of_prelims_qualified">{{ numberOfPrelimsQualifiedDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let numberOfPrelimsQualifiedFor of numberOfPrelimsQualifiedValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.no_of_prelims_qualified = numberOfPrelimsQualifiedFor">{{ numberOfPrelimsQualifiedFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Number of Mains Attempt</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.no_of_mains_qualified }}
                                        <ng-container *ngIf="!menteeDetails.no_of_mains_qualified">{{ numberOfMainsQualifiedDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let numberOfMainsQualifiedFor of numberOfMainsQualifiedValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.no_of_mains_qualified = numberOfMainsQualifiedFor">{{ numberOfMainsQualifiedFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Weak Subject Area</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ weakSubjectAreaDefaultValue }}
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let weakSubjectAreaFor of weakSubjectAreaValueList">
                                            <mat-checkbox class="checkbox" [(ngModel)]="weakSubjectAreaFor.isSelected">{{ weakSubjectAreaFor.value }}</mat-checkbox><br />
                                        </ng-container>
                                    </div>
                                </div><br *ngIf="showBrTag(weakSubjectAreaValueList)"/>
                                <ol>
                                    <ng-container *ngFor="let weakSubjectAreaFor of weakSubjectAreaValueList">
                                        <li *ngIf="weakSubjectAreaFor.isSelected">{{ weakSubjectAreaFor.value }}</li>
                                    </ng-container>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mentee-details-common-box education-history">
                    <div class="mentee-details-common-box-title">Education History</div>

                    <div class="mentee-details-common-box-input-fields">
                        <div class="mentee-details-common-box-left-column">
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Graduation Year</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.graduation_year }}
                                        <ng-container *ngIf="!menteeDetails.graduation_year">{{ graduationYearDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let graduationYearFor of graduationYearValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.graduation_year = graduationYearFor">{{ graduationYearFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Specialised Subject</div>
                                <input type="text" id="specialised-subject" name="specialised-subject" placeholder="Specialised Subject" [(ngModel)]="menteeDetails.specialised_subject">
                            </div>
                        </div>

                        <div class="mentee-details-common-box-right-column">
                            <div class="mentee-details-common-box-input-field">
                                <div class="">Graduation Background</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ menteeDetails.graduation_background }}
                                        <ng-container *ngIf="!menteeDetails.graduation_background">{{ graduationBackgroundDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let graduationBackgroundFor of graduationBackgroundValueList">
                                            <div class="dropdown-item" (click)="menteeDetails.graduation_background = graduationBackgroundFor">{{ graduationBackgroundFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mentee-details-common-box my-subscription">
                    <div class="mentee-details-common-box-title">My Subscription</div>
                    <div class="mentee-details-common-box-input-fields">
                        <div class="">
                            You are Currently suscribe to:
                            <ol>
                                <ng-container *ngFor="let plan of menteeDetails.subscription_plan">
                                    <li>{{ plan.name }}</li>
                                </ng-container>
                            </ol>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-light submit-details-button" (click)="updateStudentDetails()">Update</button>
            </div>
        </div>
    </div>
</ng-container>
