import { Component, OnInit } from '@angular/core';
import { MentorshipServiceAdapter } from './mentorship.service.adapter';
import { HttpClient } from '@angular/common/http';
import { Student } from 'src/app/common-classes/student';
import { ActivatedRoute, Router } from '@angular/router';
import { Chat } from 'src/app/common-classes/chat';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { SubscriptionPlan } from 'src/app/common-classes/subscription-plan';
import { DJANGO_SERVER } from 'src/app/environment/environment';
import { MarketingText } from 'src/app/common-classes/marketing-text';


@Component({
    selector: 'app-mentorship',
    templateUrl: './mentorship.component.html',
    styleUrls: ['./mentorship.component.css'],
})
export class MentorshipComponent implements OnInit {

    isLoading: boolean = false;
    isLoggedIn: boolean = false;
    userId: number = 0;
    roomId: number = 0;
    student: Student = new Student();
    subscriptionPlanList: SubscriptionPlan[] = [];

    sessionId: number = 0;
    sessionIdx: number = 0;
    sessionMode: string = "";
    sessionModeList: string[] = ['Google Meet', 'Phone Call'];

    currentTab: string = "upcoming-sessions";
    currentSidebarTab: string = "mentorship";

    chatMessage: string = "";
    chatList: Chat[] = [];

    upcommingSessionList: MentorshipSession[] = [];
    freeSessionList: any = [];
    menteePastSessionList: MentorshipSession[] = [];
    freePastSessionList: any = [];
    marketingAddList: MarketingText[] = [];
    socket: any;
    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new MentorshipServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    checkEve(event: any) {
        event.preventDefault();
    }

    updateSessionMode(event: string, session: MentorshipSession, sessionIdx: number) {
        this.sessionId = session.id;
        this.sessionIdx = sessionIdx;
        this.sessionMode = event;
        this.serviceAdapter.updateSession();
    }

    updateFreeSessionMode(event: string, session: MentorshipSession, sessionIdx: number) {
        this.sessionId = session.id;
        this.sessionIdx = sessionIdx;
        this.sessionMode = event;
        this.serviceAdapter.updateFreeSession();
    }

    downloadAnswer(session: MentorshipSession): void {
        if (session.analysis_report) {
            window.open(session.analysis_report, '_blank');
        } else if (session.analysis_report_file) {
            window.open(DJANGO_SERVER + session.analysis_report_file, '_blank');
        }
    }

    changeTab(tabName: string): void {
        this.currentTab = tabName;
    }

    confirmSession(sessionIndex: number): void {
        this.serviceAdapter.confirmSession(sessionIndex);
    }

    confirmFreeSession(sessionIndex: number): void {
        this.serviceAdapter.confirmFreeSession(sessionIndex);
    }

    cancelSession(sessionIndex: number): void {
        this.serviceAdapter.cancelSession(sessionIndex);
    }

    cancelFreeSession(sessionIndex: number): void {
        this.serviceAdapter.cancelFreeSession(sessionIndex);
    }

    getImageLink(image_link: string): string {
        return DJANGO_SERVER + image_link
    }

    sendMessage() {
        if (this.chatMessage) {
            this.serviceAdapter.send(this.chatMessage);
            // this.chatList.push({
            //     content: this.chatMessage,
            //     id: 0,
            //     sender: this.userId,
            //     timestamp: (new Date()).toString(),
            //     room: this.roomId
            // });
            this.chatMessage = "";
        }
    }

    navigateToMentorsPage(): void {
        this.router.navigate(['mentors-page/']);
    }

    navigateToPricing() {
        this.router.navigate(['/'], { queryParams: { id: "subscription-plans" } });
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }
}
