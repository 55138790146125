<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div *ngIf="!isLoggedIn" class="logged-out">
        <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="feature-box main-feature" [ngStyle]="{'background-image': 'linear-gradient(to right, rgba(255,0,0,0)' + gradientPercentage + '%, #B0A8EF 100%)'}">
            <div class="feature-box-image">
                <img src="../../assets/Images/Home_Page_Image_1.png" alt="3-Tier Model">
            </div>
            <div class="feature-box-content mt-0">
                <span class="feature-box-content-title-1">#1 Real Time</span><br />
                <span class="feature-box-content-title-1">Mentorship Platform</span>
                <div class="feature-box-content-text">
                    India’s <b>First MenTech platform</b> for civil service exam that brings mentors and technology together in providing <b>Personalised, Real Time and Objective Mentorship.</b>
                </div>
                <button type="button" class="btn btn-dark feature-box-content-button" (click)="navigateToPricing()">Get your Mentor</button>
            </div>
        </div>

        <div class="features">
            <div class="features-title">
                Crack UPSC CSE 2025
            </div>
            <div class="features-row">
                <div class="feature">
                    <div class="feature-img">
                        <img src="../../assets/Images/3_Tier_Mentorship.png" alt="MentorsHut">
                    </div>
                    <div class="feature-description">
                        <div class="feature-description-title">
                            3-Tier Mentorship
                        </div>
                        <div class="feature-description-text">
                            Mentors, Expert Faculties and Toppers coming together to give a holistic and most practical mentorship.
                        </div>
                    </div>
                </div>
                <div class="feature">
                    <div class="feature-img">
                        <img src="../../assets/Images/Question_Bank.png" alt="MentorsHut">
                    </div>
                    <div class="feature-description">
                        <div class="feature-description-title">
                            Question Bank
                        </div>
                        <div class="feature-description-text">
                            1000s of Questions for the application of concepts and assessment of student.
                        </div>
                    </div>
                </div>
            </div>
            <div class="features-row">
                <div class="feature">
                    <div class="feature-img">
                        <img src="../../assets/Images/Personalised_Study_Planner.png" alt="MentorsHut">
                    </div>
                    <div class="feature-description">
                        <div class="feature-description-title">
                            Personalised Time Table
                        </div>
                        <div class="feature-description-text">
                            Designed to meet the unique needs of a student according to  her/his strengths and weakness. 
                        </div>
                    </div>
                </div>
                <div class="feature">
                    <div class="feature-img">
                        <img src="../../assets/Images/Real_Time_Progess.png" alt="MentorsHut">
                    </div>
                    <div class="feature-description">
                        <div class="feature-description-title">
                            Real Time Progress Report
                        </div>
                        <div class="feature-description-text">
                            Daily monitoring of targets along with the accuracy of students with respect to the syllabus. 
                        </div>
                    </div>
                </div>
            </div>
            <div class="features-row">
                <div class="feature">
                    <div class="feature-img">
                        <img src="../../assets/Images/Syllabus_Tracker.png" alt="MentorsHut">
                    </div>
                    <div class="feature-description">
                        <div class="feature-description-title">
                            Syllabus Tracker
                        </div>
                        <div class="feature-description-text">
                            Helping the students in completing the full syllabus along with hidden topics on time. 
                        </div>
                    </div>
                </div>
                <div class="feature">
                    <div class="feature-img">
                        <img src="../../assets/Images/24x7.png" alt="MentorsHut">
                    </div>
                    <div class="feature-description">
                        <div class="feature-description-title">
                            24x7 Doubt Platform
                        </div>
                        <div class="feature-description-text">
                            Mentee can ask its doubts or any queries with the mentor on 24x7 basis.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Big Banner'">
                <div class="advertisement-row">
                    <div class="advertisement">
                        <div>
                            <ng-container *ngFor="let str of add.text.split('/n')">
                                <div class="title">{{str}}</div>
                            </ng-container>
                        </div>
                        <button type="button" class="advertisement-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="feature-box">
            <div class="feature-box-image d-md-none">
                <img src="../../assets/Images/Home_Page_Image_2.png" alt="3-Tier Model">
            </div>
            <div class="feature-box-content">
                <span class="feature-box-content-title-1">3-Tier Model of</span><br />
                <span class="feature-box-content-title-2">Mentorship</span>
                <div class="feature-box-content-text">
                    First of its kind, <b>Mentors, Subject Faculties and Toppers</b> coming together and integrated with <b>Technology</b> to give students a holistic and most practical mentorship. This makes an India’s truly MenTech platform.
                </div>
                <!-- <button type="button" class="btn btn-dark feature-box-content-button">View More</button> -->
            </div>
            <div class="feature-box-image d-none d-md-block">
                <img src="../../assets/Images/Home_Page_Image_2.png" alt="3-Tier Model">
            </div>
        </div>

        <div class="feature-box">
            <div class="feature-box-image">
                <img src="../../assets/Images/Home_Page_Image_3.png" alt="Real Time">
            </div>
            <div class="feature-box-content">
                <span class="feature-box-content-title-1">Real Time</span><br />
                <span class="feature-box-content-title-2">Mentorship</span>
                <div class="feature-box-content-text">
                    This MenTech platform aims to revolutionise the mentorship by making it real time where students can see their <b>progress on daily targets</b> and can <b>discuss any doubts with the mentors on 24x7 basis.</b>
                </div>
                <!-- <button type="button" class="btn btn-dark feature-box-content-button">View More</button> -->
            </div>
        </div>

        <div class="feature-box">
            <div class="feature-box-image d-md-none">
                <img src="../../assets/Images/Home_Page_Image_4.png" alt="3-Tier Model">
            </div>
            <div class="feature-box-content">
                <span class="feature-box-content-title-2">Syllabus </span>
                <span class="feature-box-content-title-1">Tracker</span>
                <div class="feature-box-content-text">
                    Each student will be getting a <b>Personalised Study Plan</b> consisting of milestones and micro-targets based on their <b>strengths and weakness.</b> The tracker will help the students in completing the maximum syllabus.
                </div>
                <!-- <button type="button" class="btn btn-dark feature-box-content-button">View More</button> -->
            </div>
            <div class="feature-box-image d-none d-md-block">
                <img src="../../assets/Images/Home_Page_Image_4.png" alt="Syllabus Tracker">
            </div>
        </div>

        <div class="feature-box">
            <div class="feature-box-image">
                <img src="../../assets/Images/Home_Page_Image_5.png" alt="Question Bank">
            </div>
            <div class="feature-box-content">
                <span class="feature-box-content-title-2">Question </span>
                <span class="feature-box-content-title-1">Bank</span>
                <div class="feature-box-content-text">
                    <b>1000s of questions mapped to the syllabus</b> are provided for the assessment of student preparation. Test results are <b>reflected to the mentor on daily basis.</b>
                </div>
                <!-- <button type="button" class="btn btn-dark feature-box-content-button">View More</button> -->
            </div>
        </div>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Big Banner'">
                <div class="advertisement-row">
                    <div class="advertisement">
                        <div>
                            <ng-container *ngFor="let str of add.text.split('/n')">
                                <div class="title">{{str}}</div>
                            </ng-container>
                        </div>
                        <button type="button" class="advertisement-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="testimonial d-none d-md-block">
            <div class="testimonial-title">
                What <span>Toppers</span> Say?
            </div>

            <div class="testimonial-main" *ngIf="testimonialList.length > 0">
                <div class="testimonial-main-video">
                    <img [src]="getTestimonialImage(testimonialList[0].image_link)" alt="Testimonial">
                </div>
                <div class="testimonial-main-content">
                    <i class="fa fa-quote-left" aria-hidden="true"></i>
                    <div class="testimonial-main-content-text">
                        {{testimonialList[0].text}}
                    </div>
                    <div class="testimonial-main-content-person-details">
                        <div class="testimonial-main-content-person-details-name">
                            {{testimonialList[0].full_name}}
                        </div>
                        <div class="testimonial-main-content-person-details-rank">
                            {{testimonialList[0].sub_title}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="sub-testimonials">
                <ng-container *ngFor="let testimonial of testimonialList, let i = index">
                    <div class="sub-testimonial" *ngIf="i > 0">
                        <div class="sub-testimonial-content">
                            {{testimonial.text}}
                        </div>
                        <div class="sub-testimonial-details">
                            <div class="sub-testimonial-details-img">
                                <img [src]="getTestimonialImage(testimonial.image_link)" alt="Testimonial">
                            </div>
                            <div class="sub-testimonial-details-content">
                                <div class="sub-testimonial-details-content-name">
                                    {{testimonial.full_name}}
                                </div>
                                <div class="sub-testimonial-details-content-rank">
                                    {{testimonial.sub_title}}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="testimonial-title d-md-none mt-4">
            What <span>Toppers</span> Say?
        </div>
        <div class="sub-testimonials d-md-none mx-4" style="align-items: stretch;">
            <ng-container *ngFor="let testimonial of testimonialList, let i = index">
                <div class="sub-testimonial">
                    <div class="sub-testimonial-content">
                        {{testimonial.text}}
                    </div>
                    <div class="sub-testimonial-details">
                        <div class="sub-testimonial-details-img">
                            <img [src]="getTestimonialImage(testimonial.image_link)" alt="Testimonial">
                        </div>
                        <div class="sub-testimonial-details-content">
                            <div class="sub-testimonial-details-content-name">
                                {{testimonial.full_name}}
                            </div>
                            <div class="sub-testimonial-details-content-rank">
                                {{testimonial.sub_title}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="subscription-plans" id="subscription-plans">
            <div class="subscription-title">
                <div class="">
                    Subscription Plans
                </div>
            </div>

            <table>
                <tr class="subscription-plan-row headers-row">
                    <td class="features-column" style="background-color: #6E5DF8; color: #F2F2F2;">FEATURES</td>
                    <td class="plans-column" style="background-color: #B3AEEF;">PRELIMS</td>
                    <td class="plans-column" style="background-color: #C9C5F4;">MAINS</td>
                    <td class="plans-column" style="background-color: #DCDAF1;">CSAT</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Stage of Exam</td>
                    <td class="plans-column gray-row">GS Prelims</td>
                    <td class="plans-column gray-row">GS Mains</td>
                    <td class="plans-column gray-row">CSAT Prelims</td>
                </tr>
            
                <tr class="subscription-plan-row feature-row">
                    <td class="">Study Plan</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column white-row">Personalised Study Planner</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Flexible Schedule</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column white-row">Milestones & Micro-targets</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Precies sources of each Topic</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row feature-row">
                    <td class="">Mentorship</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column white-row">1-1 Live Mentorship</td>
                    <td class="plans-column white-row">3-Tier</td>
                    <td class="plans-column white-row">3-Tier</td>
                    <td class="plans-column white-row">3-Tier</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Doubt Solving Facility</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column white-row">Live Interaction with the Faculty</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Regular Strategy Sessions</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row feature-row">
                    <td class="">Assesment</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column white-row">Question Bank with Answers</td>
                    <td class="plans-column white-row">5000+ Qs</td>
                    <td class="plans-column white-row">1000+ Qs</td>
                    <td class="plans-column white-row">2000+ Qs</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Daily Practice</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column white-row">Simulator Tests</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Mains Evaluation</td>
                    <td class="material-symbols-outlined plans-column gray-row">cancel</td>
                    <td class="plans-column gray-row">Mentor = Evaluator</td>
                    <td class="material-symbols-outlined plans-column gray-row">cancel</td>
                </tr>
            
                <tr class="subscription-plan-row feature-row">
                    <td class="">Comprehensive Performance Analysis</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column white-row">Syllabus Tracker</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Daily Progress</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column white-row">Topic-wise Analysis</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column white-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row">
                    <td class="features-column gray-row">Peer Comparison</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                    <td class="material-symbols-outlined plans-column gray-row">task_alt</td>
                </tr>
            
                <tr class="subscription-plan-row" style="border-bottom: solid 4px #6E5DF8;">
                    <td class="features-column white-row subscription-plan-button-column">
                        <div class="subscription-plan-button" style="background-color: #F2F2F2; color: #F2F2F2; cursor: default;">₹ 00/-</div>
                    </td>
                    <ng-container *ngFor="let plan of planList; let i = index">
                        <td class="plans-column white-row subscription-plan-button-column">
                            <div class="subscription-plan-button" (click)="createRzpayOrder(plan.amount, plan.plan_id)">₹ {{plan.amount}}/-</div>
                        </td>
                    </ng-container>
                </tr><br/><br/>
            </table>
        </div>
    </div>

    <div *ngIf="isLoggedIn" class="logged-in">
        <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="user.profile_photo"></app-navbar>
        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar></app-sidebar>

            <div class="profile-section">
                <div class="mentee-details">
                    <div class="mentee-name">Hi, {{ user.name }}</div>
                    <div class="mentee-quote">Let's Mentor You To Fullfill Your Dreams</div>
                </div>

                <div class="work-flow-image">
                    <img src="../../assets/Images/login_homepage_subscription.png" alt="Work Flow">
                </div><br/><br/>

                <!-- <div class="advertisement-row">
                    <div class="advertisement">
                        <div class="title">
                            Get a Mentor with <br/><br/> Personalised Study Plan
                        </div>
                        <button type="button" class="advertisement-button" (click)="checkRouting()">Subscription Plan</button>
                    </div>
                </div><br/><br/> -->

                <div class="subscription-plans" style="padding: 0rem" id="subscription-plans">
                    <div class="subscription-title">
                        <div class="">
                            Subscription Plans
                        </div>
                    </div>
                    
                    <table>
                        <tr class="subscription-plan-row headers-row">
                            <div class="features-column" style="background-color: #6E5DF8; color: #F2F2F2;">FEATURES</div>
                            <div class="plans-column" style="background-color: #B3AEEF;">PRELIMS</div>
                            <div class="plans-column" style="background-color: #C9C5F4;">MAINS</div>
                            <div class="plans-column" style="background-color: #DCDAF1;">CSAT</div>
                        </tr>

                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Stage of Exam</div>
                            <div class="plans-column gray-row">GS Prelims</div>
                            <div class="plans-column gray-row">GS Mains</div>
                            <div class="plans-column gray-row">CSAT Prelims</div>
                        </tr>
        
                        <tr class="subscription-plan-row feature-row">
                            <div class="">Study Plan</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column white-row">Personalised Study Planner</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Flexible Schedule</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column white-row">Milestones & Micro-targets</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Precies sources of each Topic</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row feature-row">
                            <div class="">Mentorship</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column white-row">1-1 Live Mentorship</div>
                            <div class="plans-column white-row">3-Tier</div>
                            <div class="plans-column white-row">3-Tier</div>
                            <div class="plans-column white-row">3-Tier</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Doubt Solving Facility</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column white-row">Live Interaction with the Faculty</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Regular Strategy Sessions</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row feature-row">
                            <div class="">Assesment</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column white-row">Question Bank with Answers</div>
                            <div class="plans-column white-row">5000+ Qs</div>
                            <div class="plans-column white-row">1000+ Qs</div>
                            <div class="plans-column white-row">2000+ Qs</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Daily Practice</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column white-row">Simulator Tests</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Mains Evaluation</div>
                            <div class="material-symbols-outlined plans-column gray-row">cancel</div>
                            <div class="plans-column gray-row">Mentor = Evaluator</div>
                            <div class="material-symbols-outlined plans-column gray-row">cancel</div>
                        </tr>
        
                        <tr class="subscription-plan-row feature-row">
                            <div class="">Comprehensive Performance Analysis</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column white-row">Syllabus Tracker</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Daily Progress</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column white-row">Topic-wise Analysis</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column white-row">task_alt</div>
                        </tr>
        
                        <tr class="subscription-plan-row">
                            <div class="features-column gray-row">Peer Comparison</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                            <div class="material-symbols-outlined plans-column gray-row">task_alt</div>
                        </tr>

                        <tr class="subscription-plan-row" style="border-bottom: solid 4px #6E5DF8;">
                            <div class="features-column white-row subscription-plan-button-column">
                                <div class="subscription-plan-button" style="background-color: #F2F2F2; color: #F2F2F2; cursor: default;">₹ 00/-</div>
                            </div>
                            <ng-container *ngFor="let plan of planList; let i = index">
                                <div class="plans-column white-row subscription-plan-button-column">
                                    <div class="subscription-plan-button" (click)="createRzpayOrder(plan.amount, plan.plan_id)">₹ {{plan.amount}}/-</div>
                                </div>
                            </ng-container>
                        </tr><br/><br/>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</ng-container>