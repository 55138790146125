import { Component, OnInit } from '@angular/core';
import { MentorProfileServiceAdapter } from './mentor-profile.service.adapter';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Mentor } from '../common-classes/mentor';
import { MarketingText } from '../common-classes/marketing-text';
import { DJANGO_SERVER } from '../environment/environment';
declare var $: any;  


@Component({
    selector: 'app-mentee-profile',
    templateUrl: './mentor-profile.component.html',
    styleUrls: ['./mentor-profile.component.css']
})
export class MentorProfileComponent implements OnInit {

    isLoading: boolean = false;
    checked = false;
    mentorDetails: Mentor = new Mentor();
    currentSection: string = "mentors-bio";

    marketingAddList: MarketingText[] = [];

    genderDefaultValue: string = "Gender";
    gender: string = this.genderDefaultValue;
    genderValueList: string[] = ['Male', 'Female', 'Transgender'];

    preparationStartedDefaultValue: string = "Preparation Started";
    preparationStarted: string = this.preparationStartedDefaultValue;
    preparationStartedValueList: string[] = [];

    optionalSubjectDefaultValue: string = "Optional Subject";
    optionalSubject: string = this.optionalSubjectDefaultValue;
    optionalSubjectValueList: string[] = [
        'Public Administration', 
        'Political Science & International Relations',
        'Sociology',
        'Geography',
        'Anthropology',
        'Agriculture',
        'Commerce & Accountancy',
        'Economics',
        'History ',
        'Law',
        'Philosophy',
        'Others',
    ];

    nextExamAttemptDefaultValue: string = "Next Exam Attempt";
    nextExamAttempt: string = this.nextExamAttemptDefaultValue;
    nextExamAttemptValueList: string[] = [];

    previousCoachingDefaultValue: string = "Any Coaching Joined";
    previousCoaching: string = this.previousCoachingDefaultValue;
    previousCoachingValueList: string[] = [
        'Vajiram and Ravi',
        'Vision IAS',
        'Next IAS',
        'Sunya IAS',
        'Unacademy',
        'Forum IAS',
        'Others',
    ];

    numberOfAttemptsDefaultValue: string = "No. of Attempts";
    numberOfAttempts: string = this.numberOfAttemptsDefaultValue;
    numberOfAttemptsValueList: string[] = [
        '1', 
        '2',
        '3',
        '4',
        '5',
        '6',
    ];

    numberOfPrelimsQualifiedDefaultValue: string = "No. of Prelims Qualified";
    numberOfPrelimsQualified: string = this.numberOfPrelimsQualifiedDefaultValue;
    numberOfPrelimsQualifiedValueList: string[] = [
        '1', 
        '2',
        '3',
        '4',
        '5',
        '6',
    ];

    numberOfMainsQualifiedDefaultValue: string = "No. of Mains Qualified";
    numberOfMainsQualified: string = this.numberOfMainsQualifiedDefaultValue;
    numberOfMainsQualifiedValueList: string[] = [
        '1', 
        '2',
        '3',
        '4',
        '5',
    ];

    graduationYearDefaultValue: string = "Graduation Year";
    graduationYear: string = this.graduationYearDefaultValue;
    graduationYearValueList: string[] = [];

    graduationBackgroundDefaultValue: string = "Graduation Background";
    graduationBackground: string = this.graduationBackgroundDefaultValue;
    graduationBackgroundValueList: string[] = [
        'Engineering', 
        'Humanities',
        'Science',
        'Medical Science',
    ];

    isLoggedIn: boolean = false;
    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new MentorProfileServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
        this.initializeDropdowns();
    }

    scrollTo(className: string): void {
        $('html, body').animate({ scrollTop: $("." + className).offset().top });
        
        if (className == 'mentors-bio' || className == 'basic-details') {
            this.currentSection = className;
        }
    }

    initializeDropdowns(): void {
        let currentYear: number = (new Date()).getFullYear();

        for (let yearI: number = 0; yearI < 6; yearI++) {
            let newYear = currentYear - yearI;
            this.preparationStartedValueList.push(newYear.toString());
        }
        this.preparationStartedValueList.push('Others');

        for (let yearI: number = 0; yearI < 3; yearI++) {
            let newYear = currentYear + yearI;
            this.nextExamAttemptValueList.push(newYear.toString());
        }
        this.nextExamAttemptValueList.push('Others');

        for (let yearI: number = 0; yearI < 5; yearI++) {
            let newYear = currentYear - yearI;
            this.graduationYearValueList.push(newYear.toString());
        }
        this.graduationYearValueList.push('Others');
    }

    updateMentorDetails() {  
        if (this.gender != this.genderDefaultValue) this.mentorDetails.gender = this.gender;
        if (this.preparationStarted != this.preparationStartedDefaultValue) this.mentorDetails.preparation_started = this.preparationStarted;
        if (this.optionalSubject != this.optionalSubjectDefaultValue) this.mentorDetails.optional_subject = this.optionalSubject;
        if (this.nextExamAttempt != this.nextExamAttemptDefaultValue) this.mentorDetails.next_exam_attempt = this.nextExamAttempt;
        if (this.previousCoaching != this.previousCoachingDefaultValue) this.mentorDetails.previous_coaching = this.previousCoaching;
        if (this.numberOfAttempts != this.numberOfAttemptsDefaultValue) this.mentorDetails.no_of_attempts = this.numberOfAttempts;
        if (this.numberOfPrelimsQualified != this.numberOfPrelimsQualifiedDefaultValue) this.mentorDetails.no_of_prelims_qualified = this.numberOfPrelimsQualified;
        if (this.numberOfMainsQualified != this.numberOfMainsQualifiedDefaultValue) this.mentorDetails.no_of_mains_qualified = this.numberOfMainsQualified;
        if (this.graduationYear != this.graduationYearDefaultValue) this.mentorDetails.graduation_year = this.graduationYear;
        if (this.graduationBackground != this.graduationBackgroundDefaultValue) this.mentorDetails.graduation_background = this.graduationBackground;
        // console.log("Mentor: ", this.mentorDetails);
        this.serviceAdapter.updateMentorDetails();
    }

    navigateToHome() {
        this.router.navigate(['/']);
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    getImageLink(image_link: string): string {
        return DJANGO_SERVER + image_link
    }
}
