import { Component, OnInit } from '@angular/core';
import { UpcomingSessionsServiceAdapter } from './upcoming-sessions.service.adapter';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { MatDialog } from '@angular/material/dialog';
import { UploadReportComponent } from '../mentee-listing/local-components/upload-report/upload-report.component';
import { Mentor } from 'src/app/common-classes/mentor';
import { DJANGO_SERVER } from 'src/app/environment/environment';


@Component({
    selector: 'app-upcoming-sessions',
    templateUrl: './upcoming-sessions.component.html',
    styleUrls: ['./upcoming-sessions.component.css']
})
export class UpcomingSessionsComponent implements OnInit {

    isLoading: boolean = false;
    isLoggedIn: boolean = false;
    currentSidebarTab: string = "upcoming-sessions";
    currentTab: string = "upcoming";
    mentor: Mentor = new Mentor();

    currentSessionTab: string = "sessions";
    currentSessionType: string = "";
    sessionDate: string = "";
    sessionTime: string = "";
    sessionId: number = 0;
    sessionIdx: number = 0;
    menteeSessionList: MentorshipSession[] = [];
    selectedSessionMode: string = "Phone Call";
    sessionModeList: string[] = ['Google Meet', 'Phone Call'];
    freeSessionList: any = [];

    menteePastSessionList: MentorshipSession[] = [];
    freePastSessionList: any = [];

    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        public dialog: MatDialog,
        ) { }

    ngOnInit(): void {
        this.serviceAdapter = new UpcomingSessionsServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    changeTab(tabName: string) {
        this.currentTab = tabName;
    }

    checkEve(event: any) {
        event.preventDefault();
    }

    editSession(sessionIdx: number): void {
        this.sessionIdx = sessionIdx;
        this.sessionId = this.menteeSessionList[sessionIdx].id;
        this.sessionDate = this.menteeSessionList[sessionIdx].date;
        this.sessionTime = this.menteeSessionList[sessionIdx].time;
        this.currentSessionTab = 'update-session';
        this.currentSessionType = 'paid-sessions';
    }

    updateSession(): void {
        if (!this.sessionDate) {
            alert("Please select the date");
            return;
        }
        if (!this.sessionTime) {
            alert("Please select the time");
            return;
        }

        if (this.currentSessionType == 'free-session') {
            this.serviceAdapter.updateFreeSession();
        } else {
            this.serviceAdapter.updateSession();
        }
    }

    uploadReport(sessionIdx: number) {
        const dialogRef = this.dialog.open(UploadReportComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.hasOwnProperty("file")) {
                // console.log("result: ", result);
                this.serviceAdapter.uploadSessionReport(result.file, this.menteePastSessionList[sessionIdx].id, sessionIdx);
            }
        });
    }

    closeEditSession() : void {
        this.sessionId = 0;
        this.sessionIdx = 0;
        this.sessionDate = "";
        this.sessionTime = "";
        this.currentSessionTab = "sessions";
    }

    editFreeSession(sessionIdx: number): void {
        this.sessionIdx = sessionIdx;
        this.sessionId = this.freeSessionList[sessionIdx].id;
        this.sessionDate = this.freeSessionList[sessionIdx].date;
        this.sessionTime = this.freeSessionList[sessionIdx].time;
        this.currentSessionTab = 'update-session';
        this.currentSessionType = 'free-sessions';
    }

    uploadFreeReport(sessionIdx: number) {
        const dialogRef = this.dialog.open(UploadReportComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.hasOwnProperty("file")) {
                // console.log("result: ", result);
                this.serviceAdapter.uploadFreeSessionReport(result.file, this.freePastSessionList[sessionIdx].id, sessionIdx);
            }
        });
    }

    downloadFreeReport(sessionIdx: number): void {
        if (this.freePastSessionList[sessionIdx].analysis_report) {
            window.open(this.freePastSessionList[sessionIdx].analysis_report, '_blank');
        } else if (this.freePastSessionList[sessionIdx].analysis_report_file) {
            window.open(DJANGO_SERVER + this.freePastSessionList[sessionIdx].analysis_report_file, '_blank');
        }
    }

    downloadReport(sessionIdx: number): void {
        if (this.menteePastSessionList[sessionIdx].analysis_report) {
            window.open(this.menteePastSessionList[sessionIdx].analysis_report, '_blank');
        } else if (this.menteePastSessionList[sessionIdx].analysis_report_file) {
            window.open(DJANGO_SERVER + this.menteePastSessionList[sessionIdx].analysis_report_file, '_blank');
        }
    }
}
