<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div class="resources-base-class">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="user.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!isResourceOpened">
            <div class="resources">
                <ng-container *ngFor="let resource of resourceList; let i = index">
                    <div class="resource" (click)="openResource(i)">
                        <div class="resource-header">
                            <div class="resource-header-icon material-symbols-outlined">style</div>
                            <div class="resource-header-title">{{ resource.topic.title }}</div>
                        </div><br/>
                        <div class="resource-text">{{ resource.topic.description }}</div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="isResourceOpened">
            <div class="fw-bold d-flex align-items-center" style="cursor: pointer; margin-left: 3rem; margin-top: 2rem;" (click)="closeResource()">
                <div class="material-symbols-outlined">
                    keyboard_backspace
                </div>
                <div> Go Back</div>
            </div>
            
            <div class="sub-resources">
                <ng-container *ngFor="let material of openedResource.material">
                    <div class="sub-resource">
                        <div class="sub-resource-header">
                            <div class="sub-resource-header-icon material-symbols-outlined">style</div>
                            <div class="sub-resource-header-title">{{ material.title }}</div>
                        </div><br/>
                        <div class="d-flex justify-content-center align-items-center">
                            <button type="button" class="sub-resource-button" (click)="downloadMaterial(material.link)">Download</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>