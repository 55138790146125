import { CurrentPrepLevel } from './current-prep-level';
import { Mentor } from './mentor';
import { SubscriptionPlan } from './subscription-plan';
import { User } from './user';
import { WeakSubjectArea } from './weak-subject-area';

export class Student {
    id: number = 0;
    user: User = new User();
    mentor: Mentor = new Mentor();
    subscription_plan: SubscriptionPlan[] = [];
    name: string = "";
    profile_photo: string = "";
    mobile_no: string = "";
    exam_year: number = 0;
    dob: string = "";

    gender: string = "";
    residence_state: string = "";
    category: string = "";
    preparation_started: string = "";
    any_coaching_joined: string = "";
    optional_subject: string = "";
    no_of_attempts: string = "";
    next_exam_attempt: string = "";
    no_of_prelims_qualified: string = "";
    no_of_mains_qualified: string = "";
    graduation_year: string = "";
    graduation_background: string = "";
    specialised_subject: string = "";

    roll_no: string = "";
    weak_subject_areas: WeakSubjectArea[] = [];
    current_level_of_preparation: CurrentPrepLevel[] = [];
}
