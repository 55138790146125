<!-- <div mat-dialog-content class="modal-container">
    <div class="modal-logo">
        <img src="../assets/Images/logo.png" alt="MentorsHut">
        <img src="../assets/Images/combined_logo.svg" alt="MentorsHut">
    </div>

    <div class="modal-title">
        3-Tier Mentorship consisting of Civil Servants, Expert Faculties and Best Mentors
    </div>

    <asl-google-signin-button type='icon' size='medium'></asl-google-signin-button>

    <div class="feature-box">
        <div class="feature-box-image">
            <img src="../../assets/Images/Home_Page_Image_2.png" alt="Real Time">
        </div>
        <div class="feature-box-content">
            <ng-container *ngIf="pageMode == 'current'">
                <div class="phone-input-field">
                    <div class="phone-prefix">
                        <div class="form-sub-prefix">+91</div>
                        <div class="form-sub-prefix">+91</div>
                    </div>
                    <input type="text" id="phone" name="phone" placeholder="Enter Phone Number" [(ngModel)]="mobileNumber">
                </div>
                <button type="button" class="next-button">Next</button>
                <div class="or-tag">Or</div>
                <button type="button" class="next-button">Continue With GOOGLE</button>

                <div class="bottom-title">
                    Enter your mobile number to Sign Up/Sign In to your Mentors Hut account
                </div>
            </ng-container>
            
            <ng-container *ngIf="pageMode == 'next'">
                <div class="bottom-title">
                    Please enter the OTP sent to your mobile number
                </div><br/>
                <div class="otp-input-field">
                    <input type="text" id="otp" name="otp" placeholder="One Time Password" [(ngModel)]="otp">
                </div>
                <button type="button" class="next-button">Verify and Proceed</button>
                
                <div class="back-resend-buttons">
                    <div class="back-resend-button">
                        Back
                    </div>
                    <div class="back-resend-button">
                        Resend OTP
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div> -->

<div class="">
    <div class="modal-logo">
        <img src="../assets/Images/combined_logo.svg" alt="MentorsHut">
    </div>

    <div class="feature-box">
        <div class="d-flex justify-content-center align-items-center mt-3 mb-5">
            <asl-google-signin-button type='standard' size='medium' data-text='signin_with'></asl-google-signin-button>
        </div>
        <div class="modal-title">
            3-Tier Mentorship consisting of Civil Servants, Expert Faculties and Best Mentors
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <div class="feature-box-image">
                <img src="../../assets/Images/Home_Page_Image_2.png" alt="Real Time">
            </div>
        </div>
    </div>
</div>
