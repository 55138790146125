<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length">
    <div class="mentorship-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="mentorship-section">
                <div class="mentorship-details">
                    <div class="mentorship-details-title">Mentor Name - 
                        <ng-container *ngIf="student.mentor">
                            {{ student.mentor.name }}
                        </ng-container>
                        <ng-container *ngIf="!student.mentor">
                            Not Allocated
                        </ng-container>
                    </div>
                    <div class="tab-group">
                        <button type="button" [class.active-tab]="currentTab == 'upcoming-sessions'" class="mentorship-details-tab-button" (click)="changeTab('upcoming-sessions')">Upcoming Sessions</button>
                        
                        <ng-container *ngIf="student.mentor">
                            <button type="button" [class.active-tab]="currentTab == 'doubt-corner'" class="mentorship-details-tab-button" (click)="changeTab('doubt-corner')">Doubt Corner</button>
                        </ng-container>
                        <ng-container *ngIf="!student.mentor">
                            <button type="button" style="cursor: not-allowed" [class.active-tab]="currentTab == 'doubt-corner'" class="mentorship-details-tab-button">Doubt Corner</button>
                        </ng-container>
                        
                        <button type="button" [class.active-tab]="currentTab == 'previous-reports'" class="mentorship-details-tab-button" (click)="changeTab('previous-reports')">Previous Reports</button>
                        
                        <ng-container *ngIf="student.mentor">
                            <button type="button" [class.active-tab]="currentTab == 'mentors-profile'" class="mentorship-details-tab-button" (click)="changeTab('mentors-profile')">Mentors Profile</button>
                        </ng-container>
                        <ng-container *ngIf="!student.mentor">
                            <button type="button" style="cursor: not-allowed" [class.active-tab]="currentTab == 'mentors-profile'" class="mentorship-details-tab-button">Mentors Profile</button>
                        </ng-container>
                    </div>
                </div>

                <ng-container *ngIf="currentTab == 'upcoming-sessions'">

                    <ng-container *ngIf="!(freeSessionList.length + upcommingSessionList.length)">
                        No Sessions
                    </ng-container>

                    <ng-container *ngFor="let session of freeSessionList; let i = index">
                        <div class="session mb-5">
                            <!-- <div class="mentor-image">
                                <img src="../../assets/Images/Home_Page_Image_7.jpg" alt="Mentor">
                            </div> -->

                            <div class="session-details">
                                <div class="session-text mb-2">Free Session - {{ session.email_id }}</div>
                                <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                <div class="session-mode">
                                    <div class="session-text session-mode-text">Choose Mode: </div>
                                    <ng-container *ngIf="!session.is_confirmed">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode"  (ngModelChange)="updateFreeSessionMode($event, session, i)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                    <ng-container *ngIf="session.is_confirmed">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                </div><br/>
                                <div class="session-buttons">
                                    <ng-container *ngIf="!session.is_confirmed">
                                        <button type="button" class="session-button" (click)="confirmFreeSession(i)">Confirm</button>
                                    </ng-container>
                                    <ng-container *ngIf="session.is_confirmed">
                                        <button type="button" class="session-button">Confirmed</button>
                                    </ng-container>
                                    <button type="button" class="session-button" (click)="cancelFreeSession(i)">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngFor="let session of upcommingSessionList; let i = index">
                        <div class="session mb-5">
                            <div class="mentor-image">
                                <img [src]="getImageLink(session.student.mentor.profile_photo)" [alt]="session.student.mentor.name">
                            </div>

                            <div class="session-details">
                                <div class="session-text mb-2">{{ session.title }}</div>
                                <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                <div class="session-mode">
                                    <div class="session-text session-mode-text">Choose Mode: </div>
                                    <!-- <div class="session-mode-radio">
                                        <input type="radio" name="session-mode" value="Google Meet" [(ngModel)]="session.mode">
                                        <label class="session-text">Google Meet</label>
                                    </div>
                                    <div class="session-mode-radio">
                                        <input type="radio" name="session-mode" value="Phone Call" [(ngModel)]="session.mode">
                                        <label class="session-text">Phone Call</label>
                                    </div> -->

                                    <ng-container *ngIf="!session.is_confirmed">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode"  (ngModelChange)="updateSessionMode($event, session, i)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                    <ng-container *ngIf="session.is_confirmed">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                </div><br/>
                                <div class="session-buttons">
                                    <ng-container *ngIf="!session.is_confirmed">
                                        <button type="button" class="session-button" (click)="confirmSession(i)">Confirm</button>
                                    </ng-container>
                                    <ng-container *ngIf="session.is_confirmed">
                                        <button type="button" class="session-button">Confirmed</button>
                                    </ng-container>
                                    <button type="button" class="session-button" (click)="cancelSession(i)">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'doubt-corner'">
                    <div class="chat-section">
                        <div class="chat-title-section">
                            <div class="chat-title">
                                Ask your Doubts
                            </div>
                        </div>

                        <div class="chats">
                            <ng-container *ngFor="let chat of chatList; let i = index">
                                <ng-container *ngIf="chat.sender == student.user.id">
                                    <div class="chat">
                                        <div class="chat-sender">
                                            <div class="chat-sender-image">
                                                <img [src]="student.profile_photo" alt="Mentee's Name">
                                            </div>
                                            <div class="">{{ student.name }}</div>
                                        </div>
                                        <div class="chat-message">
                                            <div class="">
                                                {{ chat.content }}
                                            </div>
                                        </div>
                                        <div class="chat-blank"></div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="chat.sender != student.user.id">
                                    <div class="chat">
                                        <div class="chat-blank"></div>
                                        <div class="chat-message">
                                            <div class="">
                                                {{ chat.content }}
                                            </div>
                                        </div>
                                        <div class="chat-sender">
                                            <div class="chat-sender-image">
                                                <img [src]="getImageLink(student.mentor.profile_photo)" alt="Mentor's Name">
                                            </div>
                                            <div class="">{{ student.mentor.name }}</div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <div class="chat">
                                <div class="chat-sender">
                                    <div class="chat-sender-image">
                                        <img [src]="student.profile_photo" alt="Mentee's Name">
                                    </div>
                                    <div class="">{{ student.name }}</div>
                                </div>
                                <div class="chat-message">
                                    <input type="text" id="chatMessage" name="chatMessage" placeholder="Typing..." [(ngModel)]="chatMessage">
                                </div>
                                <div class="send-icon" (click)="sendMessage()">
                                    <div class="material-symbols-outlined">
                                        send
                                    </div>
                                </div>
                                <div class="chat-blank d-none d-md-block"></div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'previous-reports'">
                    <ng-container *ngFor="let session of freePastSessionList; let i = index">
                        <ng-container *ngIf="session.analysis_report || session.analysis_report_file">
                            <div class="report">
                                <div class="report-date">{{ session.date | date: 'd MMM, y' }}</div>
                                <div class="report-title">Free Session - {{ session.email_id }}</div>
                                <button type="button" class="report-button" (click)="downloadAnswer(session)">Download</button>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngFor="let session of menteePastSessionList; let i = index">
                        <ng-container *ngIf="session.analysis_report || session.analysis_report_file">
                            <div class="report">
                                <div class="report-date">{{ session.date | date: 'd MMM, y' }}</div>
                                <div class="report-title">{{ session.title }}</div>
                                <button type="button" class="report-button" (click)="downloadAnswer(session)">Download</button>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'mentors-profile'">
                    <div class="mentor">
                        <div class="mentor-image">
                            <img [src]="getImageLink(student.mentor.profile_photo)" alt="Mentor's Name">
                        </div>

                        <div class="mentor-details">
                            <div class="mentor-name">
                                {{ student.mentor.name }}
                            </div>
                            <div class="mentor-description">
                                {{ student.mentor.description }}
                            </div><br/><br/>
                            <div class="mentor-expertise"><b>Expertise - </b> {{ student.mentor.expertise }} </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && !subscriptionPlanList.length">
    <div class="mentorship-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="mentorship-section">
                <div class="mentorship-details">
                    <div class="mentorship-details-title">Mentor Name - 
                        <ng-container *ngIf="student.mentor">
                            {{ student.mentor.name }}
                        </ng-container>
                        <ng-container *ngIf="!student.mentor">
                            Not Allocated
                        </ng-container>
                    </div>
                    <div class="tab-group">
                        <button type="button" [class.active-tab]="currentTab == 'upcoming-sessions'" class="mentorship-details-tab-button" (click)="changeTab('upcoming-sessions')">Upcoming Sessions</button>
                        <button type="button" [class.active-tab]="currentTab == 'doubt-corner'" class="mentorship-details-tab-button"  (click)="changeTab('doubt-corner')">Doubt Corner</button>
                        <button type="button" [class.active-tab]="currentTab == 'previous-reports'" class="mentorship-details-tab-button" (click)="changeTab('previous-reports')">Previous Reports</button>
                        <button type="button" [class.active-tab]="currentTab == 'mentors-profile'" class="mentorship-details-tab-button"  (click)="changeTab('mentors-profile')">Mentors Profile</button>
                    </div>
                </div>

                <ng-container *ngIf="currentTab == 'upcoming-sessions'">
                    <ng-container *ngIf="!freeSessionList.length">
                        No Sessions
                    </ng-container>
                    
                    <ng-container *ngFor="let session of freeSessionList; let i = index">
                        <div class="session mb-5">
                            <!-- <div class="mentor-image">
                                <img [src]="getImageLink(session.student.mentor.profile_photo)" alt="Mentor's Name">
                            </div> -->

                            <div class="session-details">
                                <div class="session-text mb-2">Free Session - {{ session.email_id }}</div>
                                <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                <div class="session-mode">
                                    <div class="session-text session-mode-text">Choose Mode: </div>
                                    <ng-container *ngIf="!session.is_confirmed">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode"  (ngModelChange)="updateFreeSessionMode($event, session, i)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                    <ng-container *ngIf="session.is_confirmed">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                </div><br/>
                                <div class="session-buttons">
                                    <ng-container *ngIf="!session.is_confirmed">
                                        <button type="button" class="session-button" (click)="confirmFreeSession(i)">Confirm</button>
                                    </ng-container>
                                    <ng-container *ngIf="session.is_confirmed">
                                        <button type="button" class="session-button">Confirmed</button>
                                    </ng-container>
                                    <button type="button" class="session-button" (click)="cancelFreeSession(i)">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'doubt-corner'">
                    <div class="chat-section">
                        <div class="chat-title-section">
                            <div class="chat-title">
                                Ask your Doubts
                            </div>
                        </div>
                    </div>
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Personal Mentor</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'previous-reports'">
                    <ng-container *ngFor="let session of freeSessionList; let i = index">
                        <ng-container *ngIf="session.analysis_report">
                            <div class="report">
                                <div class="report-date">{{ session.date | date: 'd MMM, y' }}</div>
                                <div class="report-title">Free Session - {{ session.email_id }}</div>
                                <button type="button" class="report-button" (click)="downloadAnswer(session.analysis_report)">Download</button>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'mentors-profile'">
                    <div class="chat-title-section">
                        <div class="chat-title">
                            <div class="material-symbols-outlined search-icon">
                                search
                            </div>
                            <div class="" (click)="navigateToMentorsPage()">
                                Explore Mentors
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>