<div class="refund-policy">
    <div class="title">
        Refund Policy
    </div><br/>

    <div class="">
        Once enrolled for any subscription plan with MentorsHut, MentorsHut shall not refund the money or transfer the money to be adjusted against any other student.<br/><br/>

        However, in some cases, the student can change the subscription plan. In such cases, MentorsHut will deduct administrative charges (Minimum 20% of the total payment) and put the remaining money as retained money. Such cases are subject to the approval of management of MentorsHut.<br/><br/>

        There will be a refund in case if you have made payment more than once for the same item.
    </div>
</div>