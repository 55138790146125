<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="user.profile_photo"></app-navbar>
    </ng-container>
    <ng-container *ngIf="!isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
    </ng-container>
    <div class="mentors-page">
        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="feature-box">
            <div class="feature-box-image d-md-none">
                <img src="../../assets/Images/Home_Page_Image_2.png" alt="3-Tier Model">
            </div>
            <div class="feature-box-content">
                <span class="feature-box-content-title-1">3-Tier Model of</span><br />
                <span class="feature-box-content-title-2">Mentorship</span>
                <div class="feature-box-content-text">
                    First of its kind, <b>Mentors, Subject Faculties and Toppers</b> coming together and integrated with <b>Technology</b> to give students a holistic and most practical mentorship. This makes an India’s truly MenTech platform.
                </div>
                <!-- <button type="button" class="btn btn-dark feature-box-content-button">
                    <div class="">
                        Know your Mentors
                    </div>
                    <span class="material-symbols-outlined">
                        south
                    </span>
                </button> -->
            </div>
            <div class="feature-box-image d-none d-md-block">
                <img src="../../assets/Images/Home_Page_Image_2.png" alt="3-Tier Model">
            </div>
        </div>

        <div class="mentors">
            <ng-container *ngFor="let mentor of mentorList">
                <div class="mentor">
                    <div class="mentor-image">
                        <img [src]="getImageLink(mentor.profile_photo)" [alt]="mentor.name">
                    </div>
                    <div class="mentor-details">
                        <div class="mentor-name">{{ mentor.name }}</div>
                        <div class="mentor-description d-none d-md-block">
                            {{ mentor.description }}
                        </div>
                        <div class="mentor-exprt-area">
                            <b>Expert Area:</b> {{ mentor.expertise }}
                        </div>
                    </div>
                    <div class="mentor-description d-md-none">
                        {{ mentor.description }}
                    </div>
                </div>
            </ng-container>

            <!-- <div class="mentors-button">
                <button type="button" class="view-more-mentors-button">
                    <div class="">
                        View more Mentors
                    </div>
                    <span class="material-symbols-outlined">
                        south
                    </span>
                </button>
            </div> -->
        </div>
    </div>
</ng-container>
