import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';

import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ChartComponent } from './chart/chart.component';

import { LoginModule } from '../login/login.module';
import { LoginComponent } from '../login/login.component';
import { FooterComponent } from './footer/footer.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

// import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
// import { GoogleLoginProvider, GoogleInitOptions, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
// import { GLOGIN_SECRETS } from '../environment/environment';


@NgModule({
    declarations: [
        NavbarComponent,
        SidebarComponent,
        SpinnerComponent,
        ChartComponent,
        FooterComponent,
        RefundPolicyComponent,
        PrivacyPolicyComponent,
        TermsOfUseComponent,
    ],
    entryComponents: [
        LoginComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgxEchartsModule.forRoot({
            echarts
        }),
        LoginModule,
        // GoogleSigninButtonModule,
    ],
    exports: [
        NavbarComponent,
        SidebarComponent,
        SpinnerComponent,
        ChartComponent,
        FooterComponent,
        RefundPolicyComponent,
        PrivacyPolicyComponent,
        TermsOfUseComponent,
        
        CommonModule,
        FormsModule,
        MatFormFieldModule,
    ],
    // providers: [
    //     {
    //         provide: 'SocialAuthServiceConfig',
    //         useValue: {
    //             autoLogin: false,
    //             providers: [
    //                 {
    //                     id: GoogleLoginProvider.PROVIDER_ID,
    //                     provider: new GoogleLoginProvider(
    //                         GLOGIN_SECRETS,
    //                         {
    //                             oneTapEnabled: false,
    //                         }
    //                     )
    //                 }
    //             ],
    //             onError: (err) => {
    //                 console.error(err);
    //             }
    //         } as SocialAuthServiceConfig
    //     }
    // ],
})
export class BasicComponentsModule {}
