import { Component, OnInit } from '@angular/core';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginServiceAdapter } from './login.service.adapter';
// import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    isLoading: boolean = false;
    pageMode: string = "current";
    mobileNumber: string = "";
    otp: string = "";

    socialUser!: SocialUser;
    isLoggedin?: boolean;

    serviceAdapter: any;

    constructor(
        private socialAuthService: SocialAuthService,
        private http: HttpClient,
        private router: Router,
        // public dialogRef: MatDialogRef<LoginComponent>
    ) { }

    ngOnInit() {
        this.serviceAdapter = new LoginServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();

        document.cookie = 'g_state' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        this.socialAuthService.authState.subscribe((user) => {
            this.socialUser = user;
            this.isLoggedin = user != null;
            // console.log(this.socialUser);

            if (this.socialUser) {
                this.serviceAdapter.loggedInUser();
            }
        });
    }

    loginWithGoogle(): void {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    logOut(): void {
        this.socialAuthService.signOut();
    }
}
