<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length">
    <div class="view-plan-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>
        
        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>
            <div class="view-plan-section">
                <div class="view-plan-header">
                    <div class="view-plan-header-title">MY SUBSCRIPTION PLAN</div>
                    <!-- <div class="view-plan-header-subtitle">Validity of the subscription plan till 31 May 2023</div> -->
                    <div class="tab-group">
                        <ng-container *ngIf="isPrelimsSubscribed">
                            <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'prelims'" (click)="currentTab = 'prelims'">Prelims</button>
                        </ng-container>
                        <ng-container *ngIf="isMainsSubscribed">
                            <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'mains'" (click)="currentTab = 'mains'">Mains</button>
                        </ng-container>
                        <ng-container *ngIf="isCSATSubscribed">
                            <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'csat'" (click)="currentTab = 'csat'">CSAT</button>
                        </ng-container>

                        <ng-container *ngIf="!isPrelimsSubscribed">
                            <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'prelims'" (click)="currentTab = 'prelims'">Prelims</button>
                        </ng-container>
                        <ng-container *ngIf="!isMainsSubscribed">
                            <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'mains'" (click)="currentTab = 'mains'">Mains</button>
                        </ng-container>
                        <ng-container *ngIf="!isCSATSubscribed">
                            <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'csat'" (click)="currentTab = 'csat'">CSAT</button>
                        </ng-container>
                    </div>
                </div>

                <ng-container *ngIf="currentTab == 'prelims' && isPrelimsSubscribed">
                    <div class="plan-name-section">
                        <div class="plan-name">
                            Detailed Features
                        </div>
                    </div>

                    <div class="plan-features-section">
                        <ng-container *ngFor="let feature of prelimsFeatureList; let i = index">
                            <div class="feature-name">{{ feature }}</div>
                        </ng-container>
                    </div>

                    <div class="plan-name-section" style="margin-top: 2rem;">
                        <div class="plan-name" style="background-color: #B0A8EF" (click)="navigateToStudyPlan()">
                            Happy Learning
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'mains' && isMainsSubscribed">
                    <div class="plan-name-section">
                        <div class="plan-name">
                            Detailed Features
                        </div>
                    </div>

                    <div class="plan-features-section">
                        <ng-container *ngFor="let feature of mainsFeatureList; let i = index">
                            <div class="feature-name">{{ feature }}</div>
                        </ng-container>
                    </div>

                    <div class="plan-name-section" style="margin-top: 2rem;">
                        <div class="plan-name" style="background-color: #B0A8EF" (click)="navigateToStudyPlan()">
                            Happy Learning
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'csat' && isCSATSubscribed">
                    <div class="plan-name-section">
                        <div class="plan-name">
                            Detailed Features
                        </div>
                    </div>

                    <div class="plan-features-section">
                        <ng-container *ngFor="let feature of csatFeatureList; let i = index">
                            <div class="feature-name">{{ feature }}</div>
                        </ng-container>
                    </div>

                    <div class="plan-name-section" style="margin-top: 2rem;">
                        <div class="plan-name" style="background-color: #B0A8EF" (click)="navigateToStudyPlan()">
                            Happy Learning
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'prelims' && !isPrelimsSubscribed">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Prelims Subscription</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'mains' && !isMainsSubscribed">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Mains Subscription</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'csat' && !isCSATSubscribed">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your CSAT Subscription</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && !subscriptionPlanList.length">
    <div class="view-plan-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>
        
        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>
            <div class="view-plan-section">
                <div class="view-plan-header">
                    <div class="view-plan-header-title">MY SUBSCRIPTION PLAN</div>
                    <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'prelims'" (click)="currentTab = 'prelims'">Prelims</button>
                    <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'mains'" (click)="currentTab = 'mains'">Mains</button>
                    <button type="button" class="view-plan-details-tab-button" [class.active-tab]="currentTab == 'csat'" (click)="currentTab = 'csat'">CSAT</button>
                </div>

                <ng-container *ngIf="currentTab == 'prelims'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Prelims Subscription</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'mains'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Mains Subscription</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'csat'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your CSAT Subscription</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>