import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse, HttpClient
} from '@angular/common/http';
import {catchError, Observable, switchMap, throwError} from 'rxjs';
import { DJANGO_SERVER } from 'src/app/environment/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    refresh = false;
    constructor(
        private http: HttpClient,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let access_token: any = localStorage.getItem('mentorshut_user_data_access_token') ? JSON.parse(localStorage.getItem('mentorshut_user_data_access_token') as string) : '';
        const req = request.clone({
            setHeaders: {
                Authorization: `Bearer ${access_token}`
            }
        });

        return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
            console.log("Error Status: ", err.status);
            if (err.status === 403 && !this.refresh) {
                this.refresh = true;
                let refresh_token: any = localStorage.getItem('mentorshut_user_data_refresh_token') ? JSON.parse(localStorage.getItem('mentorshut_user_data_refresh_token') as string) : '';
                let requestData = {
                    "refresh_token": refresh_token
                }

                let apiString = DJANGO_SERVER + "/api/users/refresh/";
                return this.http.post(apiString, requestData).pipe(
                    switchMap((response: any) => {
                        localStorage.setItem('mentorshut_user_data_access_token', JSON.stringify(response.access_token));
                        localStorage.setItem('mentorshut_user_data_refresh_token', JSON.stringify(response.refresh_token));
                        // console.log("New Token: ", response);

                        return next.handle(request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${response.access_token}`
                            }
                        }));
                    })
                );
            }
            this.refresh = false;
            return throwError(() => err);
        }));
    }
}
