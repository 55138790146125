<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="user.profile_photo"></app-navbar>
    </ng-container>
    <ng-container *ngIf="!isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
    </ng-container>
    <div class="blog-page">
        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <div class="column-left">
                <ng-container *ngIf="!isBlogOpened">
                    <div class="blog-section-title">
                        Blog
                    </div>
                    <div class="blog-section-subtitle">
                        Strategy and practical insights by the Mentors, Toppers & Expert Faculties of civil service examination
                    </div>

                    <!-- <div class="blog-section-dropdowns">
                        <div class="dropdown">
                            <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                Stage of Exam
                            </div>
                            <div class="dropdown-menu">
                                <div class="dropdown-item">Option 1</div>
                                <div class="dropdown-item">Option 2</div>
                                <div class="dropdown-item">Option 3</div>
                                <div class="dropdown-item">Option 4</div>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                Optional
                            </div>
                            <div class="dropdown-menu">
                                <div class="dropdown-item">Option 1</div>
                                <div class="dropdown-item">Option 2</div>
                                <div class="dropdown-item">Option 3</div>
                                <div class="dropdown-item">Option 4</div>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                Experts
                            </div>
                            <div class="dropdown-menu">
                                <div class="dropdown-item">Option 1</div>
                                <div class="dropdown-item">Option 2</div>
                                <div class="dropdown-item">Option 3</div>
                                <div class="dropdown-item">Option 4</div>
                            </div>
                        </div>
                    </div><br/> -->

                    <ng-container *ngFor="let blog of blogList; let i = index">
                        <ng-container *ngIf="(currentPage - 1) * maxBlogsPerPage <= i && i < currentPage * maxBlogsPerPage">
                            <div class="blog">
                                <div class="blog-image" (click)="openBlog(blog.id)" style="cursor: pointer;">
                                    <img [src]="getImageLink(blog.image_link)" alt="Blog">
                                </div>
                                <div class="blog-title" (click)="openBlog(blog.id)" style="cursor: pointer;">
                                    {{ blog.title }}
                                </div>
                                <div class="blog-subtitle">
                                    {{ blog.date }}    |    {{ blog.author }}
                                </div>
                                <div class="blog-description" [innerHTML]="getWrappedBlogDescription(blog.description)"></div>
                                <!-- <b class="read-more" (click)="openBlog(i)">Read More</b> -->
                            </div>
                        </ng-container>
                    </ng-container>

                    <div class="blog-pagination">
                        <div class="material-symbols-outlined left-icon" (click)="previousPage()">
                            chevron_left
                        </div>
                        
                        <!-- Starts: Format - < 1 2 3 4 5 6 > -->
                        <ng-container *ngIf="totalPages <= 6">
                            <div class="page" [class.active]="currentPage === 1" (click)="setCurrentPage(1)">1</div>
                            <div class="page" *ngIf="totalPages >= 2" [class.active]="currentPage === 2" (click)="setCurrentPage(2)">2</div>
                            <div class="page" *ngIf="totalPages >= 3" [class.active]="currentPage === 3" (click)="setCurrentPage(3)">3</div>
                            <div class="page" *ngIf="totalPages >= 4" [class.active]="currentPage === 4" (click)="setCurrentPage(4)">4</div>
                            <div class="page" *ngIf="totalPages >= 5" [class.active]="currentPage === 5" (click)="setCurrentPage(5)">5</div>
                            <div class="page" *ngIf="totalPages >= 6" [class.active]="currentPage === 6" (click)="setCurrentPage(6)">6</div>
                        </ng-container>
                        <!-- Ends: Format - < 1 2 3 4 5 6 > -->
                        
                        <!-- Starts: Format - < 1 ... (x - 1) x (x + 1) ... lastPage > -->
                        <ng-container *ngIf="totalPages > 6">
                            <div class="page" [class.active]="currentPage === 1" (click)="setCurrentPage(1)">1</div>
                            <ng-container *ngIf="currentPage <= 3">
                                <div class="page" [class.active]="currentPage === 2" (click)="setCurrentPage(2)">2</div>
                                <div class="page" [class.active]="currentPage === 3" (click)="setCurrentPage(3)">3</div>
                                <div class="page" [class.active]="currentPage === 4" (click)="setCurrentPage(4)">4</div>
                                <div class="page" style="text-decoration: none">...</div>
                            </ng-container>
                            <ng-container *ngIf="currentPage > 3 && currentPage <= totalPages - 3">
                                <div class="page" style="text-decoration: none">...</div>
                                <div class="page" [class.active]="currentPage === currentPage - 1" (click)="setCurrentPage(currentPage - 1)">{{ currentPage - 1 }}</div>
                                <div class="page" [class.active]="currentPage === currentPage" (click)="setCurrentPage(currentPage)">{{ currentPage }}</div>
                                <div class="page" [class.active]="currentPage === currentPage + 1" (click)="setCurrentPage(currentPage + 1)">{{ currentPage + 1 }}</div>
                                <div class="page" style="text-decoration: none">... </div>
                            </ng-container>
                            <ng-container *ngIf="currentPage > totalPages - 3 && currentPage <= totalPages">
                                <div class="page" style="text-decoration: none">... </div>
                                <div class="page" [class.active]="currentPage === totalPages - 3" (click)="setCurrentPage(totalPages - 3)">{{ totalPages - 3 }}</div>
                                <div class="page" [class.active]="currentPage === totalPages - 2" (click)="setCurrentPage(totalPages - 2)">{{ totalPages - 2 }}</div>
                                <div class="page" [class.active]="currentPage === totalPages - 1" (click)="setCurrentPage(totalPages - 1)">{{ totalPages - 1 }}</div>
                            </ng-container>
                            <div class="page" [class.active]="currentPage === totalPages" (click)="setCurrentPage(totalPages)">{{ totalPages }}</div>
                        </ng-container>
                        <!-- Ends: Format - < 1 ... (x - 1) x (x + 1) ... lastPage > -->
                        
                        <div class="material-symbols-outlined page" (click)="nextPage()">
                            chevron_right
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isBlogOpened">
                    <div class="blog-title">
                        {{ openedBlog.title }}
                    </div>
                    <div class="blog-subtitle">
                        {{ openedBlog.date }}    |    {{ openedBlog.author }}
                    </div>
                    <div class="blog-image">
                        <img [src]="getImageLink(openedBlog.image_link)" alt="Blog">
                    </div>
                    <div class="blog-description" [innerHTML]="getSafeBlogDescription(openedBlog.description)"></div>
                    <!-- <b class="read-more" (click)="closeBlog()">Read Less</b> -->
                </ng-container>
            </div>

            <div class="column-right">
                <div class="free-session-form">
                    <div class="free-session-form-title">
                        Book Free Mentoring Session
                    </div>

                    <div class="free-session-form-input">
                        <input type="text" id="name" name="name" placeholder="Your Name" [(ngModel)]="name">
                    </div>
                    <div class="free-session-form-input">
                        <input type="text" id="mobile-number" name="mobile-number" placeholder="Mobile Number" [(ngModel)]="mobileNumber">
                    </div>
                    <div class="free-session-form-input">
                        <input type="text" id="email" name="email" placeholder="Email Id" [(ngModel)]="emailId">
                    </div>
                    <div class="free-session-form-input">
                        <div class="dropdown">
                            <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ examYear }}
                            </div>
                            <div class="dropdown-menu">
                                <ng-container *ngFor="let examYearFor of examYearList">
                                    <div class="dropdown-item" (click)="examYear = examYearFor">{{ examYearFor }}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-dark free-session-form-register-button" (click)="submitUserDetails()">Register</button>
                </div>

                <div class="recent-blogs">
                    <div class="recent-blogs-title">Recent Blogs</div>

                    <ng-container *ngFor="let blog of recentBlogList">
                        <div class="recent-blog">
                            <div class="recent-blog-image" (click)="openBlog(blog.id)">
                                <img [src]="getImageLink(blog.image_link)" alt="Blog">
                            </div>
                            <div class="recent-blog-description">
                                <div class="recent-blog-title">
                                    {{ blog.title }}
                                </div><br/>
                                <div class="recent-blog-subtitle">
                                    {{ blog.date }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div>
                    <ng-container *ngFor="let add of marketingImageList">
                        <div class="recent-blog">
                            <div class="recent-blog-image" style="flex-basis: 100%;" (click)="openImageLink(add)">
                                <img [src]="getImageLink(add.image_link)" alt="add">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- <div class="trending-blogs">
            <div class="trending-blogs-title">Trending Blogs</div>
            <div class="trending-blog-group">
                <ng-container *ngFor="let blog of trendingBlogList">
                    <div class="trending-blog">
                        <div class="trending-blog-image">
                            <img [src]="getImageLink(blog.image_link)" alt="Blog">
                        </div>
                        <div class="trending-blog-title">
                            {{ blog.title }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div> -->
    </div>

    <app-footer></app-footer>
</ng-container>
