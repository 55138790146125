<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div class="mains-copies-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="mentor.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="mains-copies-section">
                <div class="mains-copies-details">
                    <div class="mains-copies-details-title">Mains Copies</div>
                    <div class="tab-group">
                        <button type="button" [class.active-tab]="currentTab == 'unevaluated'" class="mains-copies-details-tab-button" (click)="changeTab('unevaluated')">Unevaluated</button>
                        <button type="button" [class.active-tab]="currentTab == 'evaluated'" class="mains-copies-details-tab-button"  (click)="changeTab('evaluated')">Evaluated</button>
                    </div>
                </div>

                <ng-container *ngIf="currentTab == 'unevaluated'">
                    <div class="copies">
                        <ng-container *ngFor="let copy of menteeCopyList; let i = index">
                            <ng-container *ngIf="!(copy.evaluated_copy || copy.evaluated_file)">
                                <div class="copy">
                                    <div class="copy-details">{{ copy.student.name }}</div>
                                    <div class="copy-details">{{ copy.datetime | date: 'd MMM, y' }}</div>
                                    <div class="copy-details copy-name">{{ copy.question.subtopic.name }}</div>
                                    <button type="button" class="copy-button uploaded-button" (click)="downloadAnswer(copy)">Download</button>
                                    <button type="button" class="copy-button upload-button" (click)="uploadAnswerDialog(i)">Upload</button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'evaluated'">
                    <div class="copies">
                        <ng-container *ngFor="let copy of menteeCopyList; let i = index">
                            <ng-container *ngIf="copy.evaluated_copy || copy.evaluated_file">
                                <div class="copy">
                                    <div class="copy-details">{{ copy.student.name }}</div>
                                    <div class="copy-details">{{ copy.datetime | date: 'd MMM, y' }}</div>
                                    <div class="copy-details copy-name">{{ copy.question.subtopic.name }}</div>
                                    <button type="button" class="copy-button uploaded-button" (click)="downloadAnswer(copy)">Download</button>
                                    <button type="button" class="copy-button upload-button uploaded-button">Upload</button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>