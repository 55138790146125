import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-upload-mains-copy',
    templateUrl: './upload-mains-copy.component.html',
    styleUrls: ['./upload-mains-copy.component.css']
})
export class UploadMainsCopyComponent {
    link: string = "";
    file: any;
    score: number = 0;

    constructor(
        public dialogRef: MatDialogRef<UploadMainsCopyComponent>,
    ) {}

    submitModal(): void {
        this.dialogRef.close({file: this.file, score: this.score});
    }

    onFileSelected(event: any): void {
        const newFile: File = event.target.files[0];
        if (newFile) {
            this.file = newFile;
        }
    }
}
