import { HttpClient } from '@angular/common/http';
import { MainsCopiesComponent } from './mains-copies.component';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class MainsCopiesServiceAdapter {

    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: MainsCopiesComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/mentor_profile/";
        const getMentorPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorPromise         // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.mentor = value[0];
                this.vm.isLoggedIn = true;
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.isLoading = false;
                this.vm.router.navigate(['/']);
                return;
            }
        );

        apiString = DJANGO_SERVER + "/api/question_bank/get_mentees_mains_copies/";
        const getAllMainsCopies = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getAllMainsCopies,         // 0
            getMarketingAdds,          // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.menteeCopyList = value[0];
                this.vm.marketingAddList = value[1];
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async uploadEvaluatedCopy(copy: File, copyScore: number, copyId: number, copyIndex: number, studentId: number) {
        this.vm.isLoading = true;

        // let responseData = {
        //     evaluated_copy: copyLink,
        //     score: copyScore,
        //     response_id: copyId
        // };

        let responseFormData = new FormData();
        responseFormData.append('evaluated_file', copy)
        responseFormData.append('score', copyScore.toString())
        responseFormData.append('response_id', copyId.toString())

        let apiString = DJANGO_SERVER + "/api/question_bank/upload_evaluated_mains_copy/" + studentId;
        const getStudentStudyPlan = this.http.put(apiString, responseFormData).toPromise();

        await Promise.all([
            getStudentStudyPlan,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.menteeMainsCopyList[copyIndex].evaluated_file = value[0].evaluated_file;
                this.vm.menteeMainsCopyList[copyIndex].score = value[0].score;
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }
}
