<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="user.profile_photo"></app-navbar>
    </ng-container>
    <ng-container *ngIf="!isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
    </ng-container>
    <div class="demo-session-page">
        <div class="feature-form">
            <div class="feature">
                <div class="feature-image">
                    <div class="slider" (mouseenter)="stopSlider()" (mouseleave)="startSlider()">
                        <div class="slider-container" [style.transform]="'translateX(-' + currentIndex * 100 + '%)'">
                            <div class="slider-item" *ngFor="let image of images; let i = index" [class.active]="i === currentIndex">
                                <img id="carousel-image" style="height: 350px;" [src]="image.url" alt="Image {{ i + 1 }}" />
                                <div class="slider-text">{{ image.text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="feature-title">
                    Syllabus Tracker
                </div>
                <div class="feature-description">
                    I'm a tech savy individual with a Bachelor's degree in Software Development, seeking employment.
                </div> -->
            </div>
            <div class="free-session-form">
                <div class="free-session-form-title">
                    Book Free Mentoring Session
                </div>

                <div class="free-session-form-input">
                    <input type="text" id="name" name="name" placeholder="Your Name" [(ngModel)]="name">
                </div>
                <div class="free-session-form-input">
                    <input type="text" id="mobile-number" name="mobile-number" placeholder="Mobile Number" [(ngModel)]="mobileNumber">
                </div>
                <div class="free-session-form-input">
                    <input type="text" id="email" name="email" placeholder="Email Id" [(ngModel)]="emailId">
                </div>
                <div class="free-session-form-input">
                    <div class="dropdown">
                        <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ examYear }}
                        </div>
                        <div class="dropdown-menu">
                            <ng-container *ngFor="let examYearFor of examYearList">
                                <div class="dropdown-item" (click)="examYear = examYearFor">{{ examYearFor }}</div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <button (click)="submitUserDetails()" type="button" class="btn btn-dark free-session-form-register-button">Register</button>
            </div>
        </div>

        <div class="testimonial d-none d-md-block">
            <div class="testimonial-title">
                What <span>Toppers</span> Say?
            </div>

            <div class="testimonial-main" *ngIf="testimonialList.length > 0">
                <div class="testimonial-main-video">
                    <img [src]="getTestimonialImage(testimonialList[0].image_link)" alt="Testimonial">
                </div>
                <div class="testimonial-main-content">
                    <i class="fa fa-quote-left" aria-hidden="true"></i>
                    <div class="testimonial-main-content-text">
                        {{testimonialList[0].text}}
                    </div>
                    <div class="testimonial-main-content-person-details">
                        <div class="testimonial-main-content-person-details-name">
                            {{testimonialList[0].full_name}}
                        </div>
                        <div class="testimonial-main-content-person-details-rank">
                            {{testimonialList[0].sub_title}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="sub-testimonials">
                <ng-container *ngFor="let testimonial of testimonialList, let i = index">
                    <div class="sub-testimonial" *ngIf="i > 0">
                        <div class="sub-testimonial-content">
                            {{testimonial.text}}
                        </div>
                        <div class="sub-testimonial-details">
                            <div class="sub-testimonial-details-img">
                                <img [src]="getTestimonialImage(testimonial.image_link)" alt="Testimonial">
                            </div>
                            <div class="sub-testimonial-details-content">
                                <div class="sub-testimonial-details-content-name">
                                    {{testimonial.full_name}}
                                </div>
                                <div class="sub-testimonial-details-content-rank">
                                    {{testimonial.sub_title}}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="testimonial-title d-md-none mt-4">
            What <span>Toppers</span> Say?
        </div>
        <div class="sub-testimonials d-md-none mx-4" style="align-items: stretch;">
            <ng-container *ngFor="let testimonial of testimonialList, let i = index">
                <div class="sub-testimonial">
                    <div class="sub-testimonial-content">
                        {{testimonial.text}}
                    </div>
                    <div class="sub-testimonial-details">
                        <div class="sub-testimonial-details-img">
                            <img [src]="getTestimonialImage(testimonial.image_link)" alt="Testimonial">
                        </div>
                        <div class="sub-testimonial-details-content">
                            <div class="sub-testimonial-details-content-name">
                                {{testimonial.full_name}}
                            </div>
                            <div class="sub-testimonial-details-content-rank">
                                {{testimonial.sub_title}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Big Banner'">
                <div class="advertisement-row">
                    <div class="advertisement">
                        <div>
                            <ng-container *ngFor="let str of add.text.split('/n')">
                                <div class="title">{{str}}</div>
                            </ng-container>
                        </div>
                        <button type="button" class="advertisement-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
