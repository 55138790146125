<div class="privacy-policy">
    <div class="title">
        Privacy Policy
    </div><br/>

    <div class="">
        Any personal information that you transmit to MentQuest Private Limited will be used only for purposes of processing your application, the assessment and processing of any other administration relevant to MentQuest Private Limited Pvt. Ltd. 
        This privacy policy applies to the services made available at <b style="cursor: pointer;" (click)="navigateToHome()">https://mentorshut.com/</b> and its subdomains. 
        The website will be hereinafter collectively referred to as Platform.<br/><br/>

        Statistical information derived from any personal information transmitted to MentQuest Private Limited via this Platform may be shared with third parties but will not include any personally identifiable data.<br/><br/>

        <b>Location information</b> - Access to location/GPS data is optional and doesn't effect the services at all. 
        If the user has granted access to the location/GPS data, we may collect and use this information to create a heatmap or to improve our services in a particular geographical region.<br/><br/>

        We collects Name, Email and Profile picture during Google Login/Signup feature. 
        This information is used to create the user's account and to send emails regarding services provided by us.<br/><br/>

        A user can send an email on <b>mentorshut.it@gmail.com</b> to get his/her account delete from our system. 
        We will erase data of the user from our system. However, in some cases, we will retain the information for as long as it is required for the purpose of complying with legal obligation or business compliances.
    </div>
</div>