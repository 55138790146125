import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';

import { BasicComponentsModule } from '../basic-components/basic-components.module';
import { MentorshipComponent } from './mentorship/mentorship.component';
import { StudyPlanComponent } from './study-plan/study-plan.component';
import { QuestionBankComponent } from './question-bank/question-bank.component';
import { ProgressReportComponent } from './progress-report/progress-report.component';
import { ViewPlansComponent } from './view-plans/view-plans.component';

import { LocalComponentsModule as LocalComponentsModuleQuestionBank} from './question-bank/local-components/local-components.module';
import { SubmitAnswerModalComponent } from './question-bank/local-components/submit-answer-modal/submit-answer-modal.component';
import { ViewEvaluatedCopyModalComponent } from './question-bank/local-components/view-evaluated-copy-modal/view-evaluated-copy-modal.component';

import { UpcomingSessionsComponent } from './upcoming-sessions/upcoming-sessions.component';
import { MainsCopiesComponent } from './mains-copies/mains-copies.component';
import { MenteeListingComponent } from './mentee-listing/mentee-listing.component';
import { LocalComponentsModule as LocalComponentsModuleMenteeListing} from './mentee-listing/local-components/local-components.module';


@NgModule({
    declarations: [
        MentorshipComponent,
        StudyPlanComponent,
        QuestionBankComponent,
        ProgressReportComponent,
        ViewPlansComponent,
        
        UpcomingSessionsComponent,
        MenteeListingComponent,
        MainsCopiesComponent,
    ],
    entryComponents: [
        SubmitAnswerModalComponent,
        ViewEvaluatedCopyModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatRadioModule,
        
        BasicComponentsModule,
        LocalComponentsModuleQuestionBank,
        LocalComponentsModuleMenteeListing,
    ],
    exports: [
        MentorshipComponent,
        StudyPlanComponent,
        QuestionBankComponent,
        ProgressReportComponent,
        ViewPlansComponent,

        UpcomingSessionsComponent,
        MenteeListingComponent,
        MainsCopiesComponent,

        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatRadioModule,
    ],
})
export class TabsModule {}
