import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

import { UploadMainsCopyComponent } from './upload-mains-copy/upload-mains-copy.component';
import { UploadReportComponent } from './upload-report/upload-report.component';


@NgModule({
    declarations: [
        UploadMainsCopyComponent,
        UploadReportComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
    ],
    exports: [
        UploadMainsCopyComponent,
        UploadReportComponent,
    ],
})
export class LocalComponentsModule { }