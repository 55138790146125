<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div class="upcoming-session-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="mentor.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="mentee-listing-section">
                <div class="mentee-listing-details">
                    <div class="mentee-listing-details-title">Mentorship Sessions</div>
                    <div class="tab-group">
                        <button type="button" [class.active-tab]="currentTab == 'upcoming'" class="mentee-listing-details-tab-button" (click)="changeTab('upcoming')">Upcoming</button>
                        <button type="button" [class.active-tab]="currentTab == 'previous'" class="mentee-listing-details-tab-button"  (click)="changeTab('previous')">Previous</button>
                    </div>
                </div>

                <ng-container *ngIf="currentTab == 'upcoming'">
                    <ng-container *ngFor="let session of freeSessionList; let i = index">
                        <ng-container *ngIf="currentSessionTab == 'sessions'">
                            <div class="session mb-5">
                                <!-- <div class="mentor-image">
                                    <img src="../../assets/Images/Home_Page_Image_7.jpg" alt="Mentor's Name">
                                </div> -->
    
                                <div class="session-details">
                                    <div class="session-text mb-2">Free Session - {{ session.email_id }}</div>
                                    <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                    <div class="session-mode">
                                        <div class="session-text session-mode-text">Choose Mode: </div>
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div><br/>
                                    <div class="session-buttons">
                                        <button type="button" class="session-button" (click)="editFreeSession(i)">Edit</button>
                                        <!-- <ng-container *ngIf="!session.analysis_report">
                                            <button type="button" class="session-button" (click)="uploadFreeReport(i)">Upload Report</button>
                                        </ng-container>
                                        <ng-container *ngIf="session.analysis_report">
                                            <button type="button" class="session-button uploaded-button">Upload Report</button>
                                        </ng-container> -->
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngFor="let session of menteeSessionList; let i = index">
                        <ng-container *ngIf="currentSessionTab == 'sessions'">
                            <div class="session mb-5">
                                <div class="mentor-image">
                                    <img [src]="session.student.profile_photo" alt="student">
                                </div>
    
                                <div class="session-details">
                                    <div class="session-text mb-2">{{ session.title }}</div>
                                    <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                    <div class="session-mode">
                                        <div class="session-text session-mode-text">Choose Mode: </div>
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div><br/>
                                    <div class="session-buttons">
                                        <button type="button" class="session-button" (click)="editSession(i)">Edit</button>
                                        <!-- <ng-container *ngIf="!session.analysis_report">
                                            <button type="button" class="session-button" (click)="uploadReport(i)">Upload Report</button>
                                        </ng-container>
                                        <ng-container *ngIf="session.analysis_report">
                                            <button type="button" class="session-button uploaded-button">Upload Report</button>
                                        </ng-container> -->
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentSessionTab == 'update-session'">
                        <div class="material-symbols-outlined fw-bold" style="cursor: pointer;" (click)="closeEditSession()">
                            keyboard_backspace
                        </div>
                        <div class="session-details-common-box">
                            <div class="session-details-common-box-title">Session Details</div>
    
                            <div class="session-details-common-box-input-fields">
                                <div class="session-details-common-box-left-column">
                                    <div class="session-details-common-box-input-field">
                                        <input type="date" id="date" name="date" placeholder="date" [(ngModel)]="sessionDate">
                                    </div>
                                    <div class="session-details-common-box-input-field">
                                        <input type="time" id="time" name="time" placeholder="time" [(ngModel)]="sessionTime">
                                    </div>
                                </div>
                                <div class="session-details-common-box-right-column">
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-light submit-details-button" (click)="updateSession()">Update</button>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'previous'">
                    <ng-container *ngFor="let session of freePastSessionList; let i = index">
                        <ng-container *ngIf="currentSessionTab == 'sessions'">
                            <div class="session mb-5">
                                <!-- <div class="mentor-image">
                                    <img src="../../assets/Images/Home_Page_Image_7.jpg" alt="Mentor's Name">
                                </div> -->
    
                                <div class="session-details">
                                    <div class="session-text mb-2">Free Session - {{ session.email_id }}</div>
                                    <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                    <div class="session-mode">
                                        <div class="session-text session-mode-text">Choose Mode: </div>
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div><br/>
                                    <div class="session-buttons">
                                        <!-- <button type="button" class="session-button" (click)="editFreeSession(i)">Edit</button> -->
                                        <ng-container *ngIf="!(session.analysis_report || session.analysis_report_file)">
                                            <button type="button" class="session-button" (click)="uploadFreeReport(i)">Upload Report</button>
                                        </ng-container>
                                        <ng-container *ngIf="session.analysis_report || session.analysis_report_file">
                                            <button type="button" class="session-button uploaded-button">Upload Report</button>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngFor="let session of menteePastSessionList; let i = index">
                        <ng-container *ngIf="currentSessionTab == 'sessions'">
                            <div class="session mb-5">
                                <div class="mentor-image">
                                    <img [src]="session.student.profile_photo" alt="student">
                                </div>
    
                                <div class="session-details">
                                    <div class="session-text mb-2">{{ session.title }}</div>
                                    <div class="session-text mb-2">Date & Time: {{ (session.date + " " + session.time) | date:'d MMM, h:mm a' }}</div>
                                    <div class="session-mode">
                                        <div class="session-text session-mode-text">Choose Mode: </div>
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [ngModel]="session.mode" (click)="checkEve($event)">
                                            <mat-radio-button class="example-radio-button" *ngFor="let sessionModeFor of sessionModeList" [value]="sessionModeFor">
                                                {{ sessionModeFor }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div><br/>
                                    <div class="session-buttons">
                                        <!-- <button type="button" class="session-button" (click)="editSession(i)">Edit</button> -->
                                        <ng-container *ngIf="!(session.analysis_report || session.analysis_report_file)">
                                            <button type="button" class="session-button" (click)="uploadReport(i)">Upload Report</button>
                                        </ng-container>
                                        <ng-container *ngIf="session.analysis_report || session.analysis_report_file">
                                            <button type="button" class="session-button uploaded-button">Upload Report</button>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>