import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BasicComponentsModule } from '../basic-components/basic-components.module';
import { ResourcesComponent } from './resources.component';


@NgModule({
    declarations: [ResourcesComponent],
    imports: [
        FormsModule,
        BasicComponentsModule
    ],
    exports: [
        ResourcesComponent,
    ],
    providers: [],
    entryComponents: [],
})
export class ResourcesModule {}
