<div class="modal-container">
    <h1 mat-dialog-title>Upload Evaluated Copy</h1>
    <div mat-dialog-content>
        <div class="form-input">
            <input type="file" id="file" name="file" placeholder="PDF" (change)="onFileSelected($event)" accept=".pdf"><br/><br/>
            <!-- <input type="text" id="link" name="link" placeholder="PDF Link" [(ngModel)]="link"><br/><br/> -->
            <input type="text" id="score" name="score" placeholder="Score" [(ngModel)]="score">
        </div>
        <button type="button" class="submit-button" (click)="submitModal()">Upload</button>
    </div>
</div>