import { SubTask } from 'src/app/common-classes/subtask';
import { HttpClient } from '@angular/common/http';
import { ViewPlansComponent } from './view-plans.component';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class ViewPlansServiceAdapter {
    
    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: ViewPlansComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/student_profile/";
        const getStudentPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentPromise         // 0
        ]).then(
            (value: any) => {
                // console.log("Student: ", value);
                this.vm.student = value[0];
                this.vm.isLoggedIn = true;
                this.vm.subscriptionPlanList = value[0].subscription_plan;

                for (let planI = 0; planI < this.vm.subscriptionPlanList.length; planI++) {
                    if (this.vm.subscriptionPlanList[planI].plan_id == 1) {
                        this.vm.isPrelimsSubscribed = true;
                        this.vm.currentTab = "prelims";
                    } else if (this.vm.subscriptionPlanList[planI].plan_id == 2) {
                        this.vm.isMainsSubscribed = true;
                        if (!this.vm.currentTab) this.vm.currentTab = "mains";
                    } else if (this.vm.subscriptionPlanList[planI].plan_id == 3) {
                        this.vm.isCSATSubscribed = true;
                        if (!this.vm.currentTab) this.vm.currentTab = "csat";
                    }
                }
                if (!this.vm.currentTab) this.vm.currentTab = 'prelims';
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.isLoading = false;
                this.vm.router.navigate(['/']);
                return;
            }
        );

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getMarketingAdds,                  // 0
        ]).then(
            (value) => {
                this.vm.marketingAddList = value[0];
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
            }
        );
        this.vm.isLoading = false;
    }
}
