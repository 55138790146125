import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomepageComponent } from './homepage.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BasicComponentsModule } from '../basic-components/basic-components.module';
import { LoginComponent } from '../login/login.component';
import { LoginModule } from '../login/login.module';


@NgModule({
    declarations: [HomepageComponent],
    entryComponents: [
        LoginComponent,
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        BasicComponentsModule,
        LoginModule,
    ],
    exports: [
        HomepageComponent,
        MatFormFieldModule,
        MatSelectModule,
    ],
    providers: [],
})
export class HomepageModule {}
