<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="mentorDetails.profile_photo"></app-navbar>
    </ng-container>
    <ng-container *ngIf="!isLoggedIn">
        <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
    </ng-container>
    <div class="mentor-profile-page">
        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <div class="sidebar">
                <div class="home-tab">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="navigateToHome()">Home</div>
                </div>

                <div class="d-none d-md-block sidebar-title">MY ACCOUNT</div>
                <div class="d-none d-md-flex sidebar-tab" [class.active-tab]="currentSection == 'mentors-bio'">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="scrollTo('mentors-bio')">Mentors Bio</div>
                </div>
                <div class="d-none d-md-flex sidebar-tab" [class.active-tab]="currentSection == 'basic-details'">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="scrollTo('basic-details')">Basic Details</div>
                </div>
                <div class="d-none d-md-flex sidebar-tab">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="scrollTo('upsc-journey')">UPSC Journey</div>
                </div>
                <div class="d-none d-md-flex sidebar-tab">
                    <div class="material-icons tab-icon">home</div>
                    <div class="tab-title" (click)="scrollTo('education-history')">Education History</div>
                </div>
            </div>

            <div class="profile-section">
                <div class="mentor-details">
                    <div class="mentor-image">
                        <img [src]="getImageLink(mentorDetails.profile_photo)" alt="Mentor Name">
                    </div>
                    <div class="mentor-description">
                        <div class="mentor-name-roll">
                            <div class="mentor-name">{{ mentorDetails.name }}</div>
                            <div class="mentor-roll">Mentor Id. - 1347298</div>
                        </div>
                        <!-- <button type="button" class="edit-profile-button" name="button">Edit Profile Picture</button> -->
                    </div>
                </div>

                <div class="mentor-details-common-box mentors-bio">
                    <div class="mentors-bio-title-box">
                        <div class="mentors-bio-title-box-title" >Mentors Bio</div>
                        <!-- <div class="mentors-bio-title-box-title mentors-bio-edit-button">Edit</div> -->
                    </div>

                    <div class="mentor-details-common-box-input-fields">
                        <div style="width: 100%;">
                            <textarea class="mentor-details-common-box-input-field mentors-profile-bio" rows="5" cols="100%" id="description" name="description" placeholder="Description" [(ngModel)]="mentorDetails.description"></textarea>
                            <br/>
                            <div class="mentor-exprt-area">
                                <b>Expert Area:</b> <br/>
                                <textarea class="mentor-details-common-box-input-field mentors-profile-bio" style="margin-top: 10px;" rows="3" cols="100%" id="expertise" name="expertise" placeholder="Expertise" [(ngModel)]="mentorDetails.expertise"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mentor-details-common-box basic-details">
                    <div class="mentor-details-common-box-title">Basic Details</div>

                    <div class="mentor-details-common-box-input-fields">
                        <div class="mentor-details-common-box-left-column">
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Name</div>
                                <input type="text" id="name" name="name" placeholder="Name" [(ngModel)]="mentorDetails.name">
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Mobile No.</div>
                                <input type="text" id="phone-no" name="phone-no" placeholder="Phone No." [(ngModel)]="mentorDetails.mobile_no">
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Email</div>
                                <div class="input">{{ mentorDetails.user.email }}</div>
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Category</div>
                                <input type="text" id="category" name="category" placeholder="Category" [(ngModel)]="mentorDetails.category">
                            </div>
                        </div>

                        <div class="mentor-details-common-box-right-column">
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Date of Birth</div>
                                <input type="date" id="dob" name="dob" placeholder="Date of Birth" [(ngModel)]="mentorDetails.dob">
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Gender</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.gender }}
                                        <ng-container *ngIf="!mentorDetails.gender">{{ genderDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let genderFor of genderValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.gender = genderFor">{{ genderFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Residence State</div>
                                <input type="text" id="residence-state" name="residence-state" placeholder="Residence State" [(ngModel)]="mentorDetails.residence_state">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mentor-details-common-box upsc-journey">
                    <div class="mentor-details-common-box-title">UPSC Journey</div>

                    <div class="mentor-details-common-box-input-fields">
                        <div class="mentor-details-common-box-left-column">
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Preparation Started</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.preparation_started }}
                                        <ng-container *ngIf="!mentorDetails.preparation_started">{{ preparationStartedDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let preparationStartedFor of preparationStartedValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.preparation_started = preparationStartedFor">{{ preparationStartedFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Optional Subject</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.optional_subject }}
                                        <ng-container *ngIf="!mentorDetails.optional_subject">{{ optionalSubjectDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let optionalSubjectFor of optionalSubjectValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.optional_subject = optionalSubjectFor">{{ optionalSubjectFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Next Exam Attempt</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.next_exam_attempt }}
                                        <ng-container *ngIf="!mentorDetails.next_exam_attempt">{{ nextExamAttemptDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let nextExamAttemptFor of nextExamAttemptValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.next_exam_attempt = nextExamAttemptFor">{{ nextExamAttemptFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Any Coacing Joined</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.previous_coaching }}
                                        <ng-container *ngIf="!mentorDetails.previous_coaching">{{ previousCoachingDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let previousCoachingFor of previousCoachingValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.previous_coaching = previousCoachingFor">{{ previousCoachingFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mentor-details-common-box-right-column">
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Number of Attempts</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.no_of_attempts }}
                                        <ng-container *ngIf="!mentorDetails.no_of_attempts">{{ numberOfAttemptsDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let numberOfAttemptsFor of numberOfAttemptsValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.no_of_attempts = numberOfAttemptsFor">{{ numberOfAttemptsFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="mentor-details-common-box-input-field">
                                <div class="">Number of Prelims Qualified</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.no_of_prelims_qualified }}
                                        <ng-container *ngIf="!mentorDetails.no_of_prelims_qualified">{{ numberOfPrelimsQualifiedDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let numberOfPrelimsQualifiedFor of numberOfPrelimsQualifiedValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.no_of_prelims_qualified = numberOfPrelimsQualifiedFor">{{ numberOfPrelimsQualifiedFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="mentor-details-common-box-input-field">
                                <div class="">Number of Mains Qualified</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.no_of_mains_qualified }}
                                        <ng-container *ngIf="!mentorDetails.no_of_mains_qualified">{{ numberOfMainsQualifiedDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let numberOfMainsQualifiedFor of numberOfMainsQualifiedValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.no_of_mains_qualified = numberOfMainsQualifiedFor">{{ numberOfMainsQualifiedFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Latest Mains Score</div>
                                <input type="text" id="latest-mains-score" name="latest-mains-score" placeholder="Latest Mains Score" [(ngModel)]="mentorDetails.last_mains_score">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mentor-details-common-box education-history">
                    <div class="mentor-details-common-box-title">Education History</div>

                    <div class="mentor-details-common-box-input-fields">
                        <div class="mentor-details-common-box-left-column">
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Graduation Year</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.graduation_year }}
                                        <ng-container *ngIf="!mentorDetails.graduation_year">{{ graduationYearDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let graduationYearFor of graduationYearValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.graduation_year = graduationYearFor">{{ graduationYearFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Specialised Subject</div>
                                <input type="text" id="specialised-subject" name="specialised-subject" placeholder="Specialised Subject" [(ngModel)]="mentorDetails.specialised_subject">
                            </div>
                        </div>

                        <div class="mentor-details-common-box-right-column">
                            <div class="mentor-details-common-box-input-field">
                                <div class="">Graduation Background</div>
                                <div class="dropdown">
                                    <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ mentorDetails.graduation_background }}
                                        <ng-container *ngIf="!mentorDetails.graduation_background">{{ graduationBackgroundDefaultValue }}</ng-container>
                                    </div>
                                    <div class="dropdown-menu">
                                        <ng-container *ngFor="let graduationBackgroundFor of graduationBackgroundValueList">
                                            <div class="dropdown-item" (click)="mentorDetails.graduation_background = graduationBackgroundFor">{{ graduationBackgroundFor }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-light submit-details-button" (click)="updateMentorDetails()">Update</button>
            </div>
        </div>
    </div>
</ng-container>
