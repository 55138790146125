<div class="manual-navbar">
    <div class="navbar-left-column">
        <div class="navbar-logo">
            <img src="../../assets/Images/combined_logo_transparent.svg" alt="MentorsHut" (click)="navigateToHome()" style="cursor: pointer">
        </div>

        <div class="dropdown d-none d-md-block">
            <div class="dropdown-toggle dropdown-button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ examType }}
            </div>
        </div>

        <div class="phone-number-details">
            <div class="material-symbols-outlined call-icon">call</div>
            <div class="navbar-phone-number">9821373633</div>
        </div>
    </div>

    <ng-container *ngIf="isLoggedIn">
        <div class="navbar-right-column-loggedIn">
            <div (click)="navigateToCourses()" class="navbar-tab">Courses</div>
            <div (click)="navigateToMentors()" class="navbar-tab">Mentors</div>
            <div (click)="navigateToResources()" class="navbar-tab">Resources</div>
            <div (click)="navigateToBlog()" class="navbar-tab">Blog</div>
            <div (click)="navigateToPricing()" class="navbar-tab">Pricing</div>
            <div class="dropdown login">
                <div class="dropdown-toggle dropdown-button user-image" data-bs-toggle="dropdown" aria-expanded="false">
                    <img [src]="getUserImage()" alt="Mentee profile">
                </div>
                <div class="dropdown-menu">
                    <div class="dropdown-item" (click)="navigateToUserProfile()">My Profile</div>
                    <div class="dropdown-item" (click)="logOutUser()">Logout</div>
                </div>
            </div>

            <!-- <div class="user-image" (click)="navigateToUserProfile()">
                <img [src]="userImage" alt="Mentee profile">
            </div> -->
            <!-- <a [routerLink]="['/']" [queryParams]="{ 'id': 'subscription-plan' }">Pricing</a> -->
            <!-- <div class="far fa-bell notification-icon"></div> -->
        </div>
        <div class="dropdown login mobile-login-dropdown">
            <div class="dropdown-toggle dropdown-button user-image" data-bs-toggle="dropdown" aria-expanded="false">
                <img [src]="getUserImage()" alt="Mentee profile">
            </div>
            <div class="dropdown-menu">
                <div class="dropdown-item" (click)="navigateToUserProfile()">My Profile</div>
                <div class="dropdown-item" (click)="logOutUser()">Logout</div>
            </div>
        </div>
        <!-- Mobile menu items -->
        <div class="menu-button" (click)="toggleMenu()">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="mobile-menu" *ngIf="isMenuOpen">
            <div class="navbar-tab">Call 9821373633</div>
            <div (click)="navigateToCourses()" class="navbar-tab">Courses</div>
            <div (click)="navigateToMentors()" class="navbar-tab">Mentors</div>
            <div (click)="navigateToResources()" class="navbar-tab">Resources</div>
            <div (click)="navigateToBlog()" class="navbar-tab">Blog</div>
            <div (click)="navigateToPricing()" class="navbar-tab">Pricing</div>
        </div>
    </ng-container>

    <ng-container *ngIf="!isLoggedIn">
        <div class="log-in mobile-login-dropdown" (click)="openDialog()">Login</div>
        <div class="menu-button" (click)="toggleMenu()">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="navbar-right-column-loggedOut">
            <div (click)="navigateToCourses()" class="navbar-tab">Courses</div>
            <div (click)="navigateToMentors()" class="navbar-tab">Mentors</div>
            <div (click)="navigateToResources()" class="navbar-tab">Resources</div>
            <div (click)="navigateToBlog()" class="navbar-tab">Blog</div>
            <div (click)="navigateToPricing()" class="navbar-tab">Pricing</div>
            <div class="log-in" (click)="openDialog()">Login</div>
        </div>
        <div class="mobile-menu" *ngIf="isMenuOpen">
            <div class="navbar-tab">Call 9821373633</div>
            <div (click)="navigateToMentors()" class="navbar-tab">Mentors</div>
            <div (click)="navigateToCourses()" class="navbar-tab">Courses</div>
            <div (click)="navigateToResources()" class="navbar-tab">Resources</div>
            <div (click)="navigateToBlog()" class="navbar-tab">Blog</div>
            <div (click)="navigateToPricing()" class="navbar-tab">Pricing</div>
        </div>
    </ng-container>
</div>