import { Component } from '@angular/core';
import { FRONTEND } from 'src/app/environment/environment';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent {
    navigateToHome() {
        let url = FRONTEND + "/";
        window.open(url, '_blank');
    }
}
