import { BlogComponent } from './blog.component';
import { HttpClient } from '@angular/common/http';
import { DJANGO_SERVER } from 'src/app/environment/environment';

export class BlogServiceAdapter {
    vm: any;

    constructor(
        public http: HttpClient
    ) { }

    initializeAdapter(vm: BlogComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/mentor_or_student_profile/";
        const getMentorOrStudentPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorOrStudentPromise         // 0
        ]).then(
            (value) => {
                // console.log(value);
                this.vm.user = value[0];
                this.vm.isLoggedIn = true;
            },
            (error) => {
                // console.log(error);
                this.vm.isLoggedIn = false;
            }
        );

        apiString = DJANGO_SERVER + "/api/blog/get_all_blogs/";
        const getBlogList = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_image/";
        const getMarketingImages = this.http.get(apiString).toPromise();

        await Promise.all([
            getBlogList,                       // 0
            getMarketingAdds,                  // 1
            getMarketingImages,                // 1
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.blogList = value[0];
                this.vm.totalPages = Math.ceil(this.vm.blogList.length / this.vm.maxBlogsPerPage);

                for (let blogI = 0; blogI < Math.min(3, this.vm.blogList.length); blogI++) {
                    this.vm.recentBlogList.push(this.vm.blogList[blogI]);
                }
                this.vm.marketingAddList = value[1];
                this.vm.marketingImageList = value[2];
            },
            (error) => {
                // console.log(error);
            }
        );

        this.vm.route.queryParamMap.subscribe(async (params: any) => {
            if (params.hasOwnProperty('params') && params.params.hasOwnProperty('id')) {
                apiString = DJANGO_SERVER + "/api/blog/get_blog_by_id/" + params.params.id;
                const getBlog = this.http.get(apiString).toPromise();

                await Promise.all([
                    getBlog         // 0
                ]).then(
                    (value: any) => {
                        // console.log(value);
                        this.vm.openedBlog = value[0];
                        this.vm.isBlogOpened = true;
                    },
                    (error) => {
                        // console.log(error);
                    }
                );
            }
        });
        this.vm.isLoading = false;
    }

    async submitUserDetails() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/free_sessions/bookings/";
        let sessionData = {
            "name": this.vm.name,
            "mobile_no": this.vm.mobileNumber,
            "email_id": this.vm.emailId,
            "exam_year": this.vm.examYear
        };

        const bookFreeSession = this.http.post(apiString, sessionData).toPromise()
        await Promise.all([
            bookFreeSession         // 0
        ]).then(
            (value: any) => {
                if (value[0]['detail']) {
                    alert(value[0]['detail']);
                    return;
                }
                this.vm.name = "";
                this.vm.mobileNumber = "";
                this.vm.emailId = "";
                this.vm.examYear = "";
                alert("Session booked successfully.");
            },
            (error) => {
                // console.log(error);
            }
        );
        this.vm.isLoading = false;
    }
}
