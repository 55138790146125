import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StudyPlanServiceAdapter } from './study-plan.service.adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from 'src/app/common-classes/task';
import { SubTask } from 'src/app/common-classes/subtask';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { Student } from 'src/app/common-classes/student';
import { MarketingText } from 'src/app/common-classes/marketing-text';

@Component({
    selector: 'app-study-plan',
    templateUrl: './study-plan.component.html',
    styleUrls: ['./study-plan.component.css']
})
export class StudyPlanComponent implements OnInit {

    isLoading: boolean = false;
    currentSidebarTab: string = "study-plan";
    currentTab: string = "";
    isLoggedIn: boolean = false;
    student: Student = new Student();

    subscriptionPlanList: number[] = [];
    isPrelimsSubscribed: boolean = false;
    isMainsSubscribed: boolean = false;
    isCSATSubscribed: boolean = false;

    openedSubtask: SubTask = new SubTask();
    openedSubtaskIdx: number = 0;
    previousSubscriptionType: string = "";

    prelimsTaskList: Task[] = [];
    prelimsSessionsList: MentorshipSession[] = [];

    mainsTaskList: Task[] = [];
    mainsSessionsList: MentorshipSession[] = [];

    csatTaskList: Task[] = [];
    csatSessionsList: MentorshipSession[] = [];
    marketingAddList: MarketingText[] = [];

    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new StudyPlanServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    changeTab(tabName: string): void {
        this.currentTab = tabName;
    }

    doneSubtask(subTask: SubTask, i: number, j: number): void {
        this.serviceAdapter.doneSubtask(subTask, i, j);
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    backToStudyPlan(): void {
        if (this.previousSubscriptionType == 'Prelims') {
            this.currentTab = "prelims";
        }
        if (this.previousSubscriptionType == 'Mains') {
            this.currentTab = "mains";
        }
        if (this.previousSubscriptionType == 'CSAT') {
            this.currentTab = "csat";
        }
        this.previousSubscriptionType = "";
        this.openedSubtask = new SubTask();
        this.openedSubtaskIdx = 0;
    }

    openTopicLIsting(subTask: SubTask, subTaskIdx: number, previousSubscriptionType: string) {
        this.openedSubtask = subTask;
        this.openedSubtaskIdx = subTaskIdx + 1;
        this.previousSubscriptionType = previousSubscriptionType;
        this.currentTab = "topicsListing";
    }

    navigateToPricing() {
        this.router.navigate(['/'], { queryParams: { id: "subscription-plans" } });
    }
}
