import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResourcesServiceAdapter } from './resources.service.adapter';
import { Resource } from '../common-classes/resource';
import { MarketingText } from '../common-classes/marketing-text';


@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {
    
    isLoading: boolean = true;
    isLoggedIn: boolean = false;

    isResourceOpened: boolean = false;
    openedResource: Resource = new Resource();
    resourceList: Resource[] = [];
    marketingAddList: MarketingText[] = [];

    serviceAdapter: any;
    user: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        ) { }

    ngOnInit(): void {
        this.serviceAdapter = new ResourcesServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    openResource(index: number): void {
        this.isResourceOpened = true;
        this.openedResource = this.resourceList[index];
    }

    closeResource(): void {
        this.isResourceOpened = false;
    }

    downloadMaterial(response: string): void {
        window.open(response, '_blank');
    }
}
