import { SubTopic } from "./subtopic";

export class SubTask {
    id: number = 0;
    task: number = 0;
    subtopic: SubTopic = new SubTopic();
    deadline: string = "";
    completion_date: string = "";
    
    questions_start: string = "";
    questions_end: string = "";
    questions_completion_date: string = "";
    questions_completed: boolean = false;
    
    reading_start: string = ""; 
    reading_end: string = "";
    reading_completion_date: string = "";
    reading_completed: boolean = false;
}