<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length">
    <div class="progress-report-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="progress-report-section">
                <ng-container *ngIf="pageMode == 'subscription'">
                    <div class="progress-report-details">
                        <div class="progress-report-details-title">PROGRESS REPORT</div>
                        <div class="tab-group">
                            <ng-container *ngIf="isPrelimsSubscribed">
                                <button type="button" [class.active-tab]="currentTab == 'prelims'" class="progress-report-details-tab-button" (click)="currentTab = 'prelims'">Prelims</button>
                            </ng-container>
                            <ng-container *ngIf="isMainsSubscribed">
                                <button type="button" [class.active-tab]="currentTab == 'mains'" class="progress-report-details-tab-button" (click)="currentTab = 'mains'">Mains</button>
                            </ng-container>
                            <ng-container *ngIf="isCSATSubscribed">
                                <button type="button" [class.active-tab]="currentTab == 'csat'" class="progress-report-details-tab-button" (click)="currentTab = 'csat'">CSAT</button>
                            </ng-container>

                            <ng-container *ngIf="!isPrelimsSubscribed">
                                <button type="button" [class.active-tab]="currentTab == 'prelims'" class="progress-report-details-tab-button" (click)="currentTab = 'prelims'">Prelims</button>
                            </ng-container>
                            <ng-container *ngIf="!isMainsSubscribed">
                                <button type="button" [class.active-tab]="currentTab == 'mains'" class="progress-report-details-tab-button" (click)="currentTab = 'mains'">Mains</button>
                            </ng-container>
                            <ng-container *ngIf="!isCSATSubscribed">
                                <button type="button" [class.active-tab]="currentTab == 'csat'" class="progress-report-details-tab-button" (click)="currentTab = 'csat'">CSAT</button>
                            </ng-container>
                        </div>
                    </div>

                    <ng-container *ngIf="currentTab == 'prelims'">
                        <ng-container *ngIf="isPrelimsSubscribed">
                            <div class="d-flex justify-content-center">
                                <div class="explore-button" (click)="openFeatures();">
                                    <div class="d-flex justify-content-center">
                                        <div class="material-symbols-outlined search-icon">search</div><div class="">Explore</div>
                                    </div>
                                    <div class="">
                                        Detailed Progress Analysis
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isPrelimsSubscribed">
                            <div class="locked-screen">
                                <div class="background-image">
                                    <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                                </div>
                                <div class="lock">
                                    <div class="material-symbols-outlined lock-icon">lock</div>
                                    <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get a Personal Mentor</button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentTab == 'mains'">
                        <ng-container *ngIf="isMainsSubscribed">
                            <div class="d-flex justify-content-center">
                                <div class="explore-button" (click)="openFeatures();">
                                    <div class="d-flex justify-content-center">
                                        <div class="material-symbols-outlined search-icon">search</div><div class="">Explore</div>
                                    </div>
                                    <div class="">
                                        Detailed Progress Analysis
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isMainsSubscribed">
                            <div class="locked-screen">
                                <div class="background-image">
                                    <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                                </div>
                                <div class="lock">
                                    <div class="material-symbols-outlined lock-icon">lock</div>
                                    <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get a Personal Mentor</button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentTab == 'csat'">
                        <ng-container *ngIf="isCSATSubscribed">
                            <div class="d-flex justify-content-center">
                                <div class="explore-button" (click)="openFeatures();">
                                    <div class="d-flex justify-content-center">
                                        <div class="material-symbols-outlined search-icon">search</div><div class="">Explore</div>
                                    </div>
                                    <div class="">
                                        Detailed Progress Analysis
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isCSATSubscribed">
                            <div class="locked-screen">
                                <div class="background-image">
                                    <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                                </div>
                                <div class="lock">
                                    <div class="material-symbols-outlined lock-icon">lock</div>
                                    <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get a Personal Mentor</button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="pageMode == 'feature'">
                    <div class="progress-report-details">
                        <div class="progress-report-details-title">PROGRESS REPORT</div>
                        <div class="tab-group">
                            <button type="button" class="material-symbols-outlined fw-bold active-tab progress-report-details-tab-button" style="cursor: pointer;" (click)="closeFeatures()">
                                keyboard_backspace
                            </button>
                            <button type="button" [class.active-tab]="currentFeatureTab == 'syllabus-tracker'" class="progress-report-details-tab-button" (click)="currentFeatureTab = 'syllabus-tracker'">Syllabus Tracker</button>
                            <button type="button" [class.active-tab]="currentFeatureTab == 'weak-areas'" class="progress-report-details-tab-button"  (click)="currentFeatureTab = 'weak-areas'">Weak Areas</button>
                            <button type="button" [class.active-tab]="currentFeatureTab == 'peer-comparison'" class="progress-report-details-tab-button"  (click)="currentFeatureTab = 'peer-comparison'">Peer Comparison</button>
                        </div>
                    </div>

                    <ng-container *ngIf="currentFeatureTab == 'syllabus-tracker'">
                        <ng-container *ngFor="let task of menteeTaskList, let i = index">
                            <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                <div class="progress milestone-progress">
                                    <div class="progress-bar milestone-progress-bar bg-dark" role="progressbar" [ngStyle]="{'width': menteeTopicProgressList[i].score.toFixed(2) + '%'}" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{ menteeTopicProgressList[i].score.toFixed(2) }}%</div>
                                </div>
                            </div>
                            <ng-container *ngIf="task.isOpen">
                                <div class="graph cdk-virtual-scroll-data-source-example">
                                    <app-chart [option]="getLinearOption(i)"></app-chart>
                                </div>
                            </ng-container>

                            <div class="milestone-meet">
                                <div class="milestone-meet-title">{{ menteeReadingSessionsList[i].title }} - {{ i + 1 }}</div>
                                <div class="milestone-meet-date">{{ menteeReadingSessionsList[i].date | date:'d MMM, y' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentFeatureTab == 'weak-areas'">
                        <ng-container *ngFor="let task of menteeTaskList, let i = index">
                            <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                <div class="progress milestone-progress">
                                    <div class="progress-bar milestone-progress-bar bg-dark" role="progressbar" [ngStyle]="{'width': menteeTopicProgressList[i].score.toFixed(2) + '%'}" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{ menteeTopicProgressList[i].score.toFixed(2) }}%</div>
                                </div>
                            </div>
                            <ng-container *ngIf="task.isOpen">
                                <div class="graph cdk-virtual-scroll-data-source-example">
                                    <app-chart [option]="getPieOption(i)" (click)="toggleWeakArea(i)" style="cursor: pointer;"></app-chart>
                                    <br/>

                                    <ng-container *ngIf="isMenteeWeakAreaOpenedList[i]">
                                        <div class="topics-tab-group">
                                            <div class="progress-report-topics strong-topics">
                                                <div class="topic-category">
                                                    <div class="">Strong Topics</div>
                                                </div><br/>
                                                <ol>
                                                    <ng-container *ngFor="let topic of menteeWeakTopicSummaryDetails[i].greater_than_60_percent_subtopic_list">
                                                        <li>{{ topic }}</li>
                                                    </ng-container>
                                                </ol> 
                                            </div>
                                            <div class="progress-report-topics rivision-topics">
                                                <div class="topic-category">
                                                    <div class="">Revision Topics</div>
                                                </div><br/>
                                                <ol>
                                                    <ng-container *ngFor="let topic of menteeWeakTopicSummaryDetails[i].greater_than_equal_to_40_less_than_60_subtopic_list">
                                                        <li>{{ topic }}</li>
                                                    </ng-container>
                                                </ol>
                                            </div>
                                            <div class="progress-report-topics weak-topics">
                                                <div class="topic-category">
                                                    <div class="">Weak Topics</div>
                                                </div><br/>
                                                <ol>
                                                    <ng-container *ngFor="let topic of menteeWeakTopicSummaryDetails[i].less_than_40_percent_subtopic_list">
                                                        <li>{{ topic }}</li>
                                                    </ng-container>
                                                </ol>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>

                            <div class="milestone-meet">
                                <div class="milestone-meet-title">{{ menteeReadingSessionsList[i].title }} - {{ i + 1 }}</div>
                                <div class="milestone-meet-date">{{ menteeReadingSessionsList[i].date | date:'d MMM, y' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentFeatureTab == 'peer-comparison'">
                        <div class="rank-title-section">
                            <div class="rank-title">
                                Overall Rank
                            </div>
                        </div>
                        <div class="">
                            <div class="rank-row">
                                <div class="rank-row-header rank-col-number">Rank</div>
                                <div class="rank-row-header rank-col-name">Name</div>
                                <div class="rank-row-header rank-col-total">Total Attempted</div>
                                <div class="rank-row-header rank-col-accuracy">Accuracy</div>
                            </div>
                            <ng-container *ngFor="let peer of menteePeerComparisonList; let i = index">
                                <div class="rank-row">
                                    <div class="rank-col-number">{{i + 1}}</div>
                                    <div class="rank-col-name text-capitalize">{{peer.student_name}}</div>
                                    <div class="rank-col-total">{{peer.number_of_attempted}}</div>
                                    <div class="rank-col-accuracy">{{ peer.accuracy.toFixed(2) }}%</div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && !subscriptionPlanList.length">
    <div class="progress-report-page">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="progress-report-section">
                <div class="progress-report-details">
                    <div class="progress-report-details-title">PROGRESS REPORT</div>
                    <div class="tab-group">
                        <button type="button" [class.active-tab]="currentFeatureTab == 'syllabus-tracker'" class="progress-report-details-tab-button" (click)="currentFeatureTab = 'syllabus-tracker'">Syllabus Tracker</button>
                        <button type="button" [class.active-tab]="currentFeatureTab == 'weak-areas'" class="progress-report-details-tab-button"  (click)="currentFeatureTab = 'weak-areas'">Weak Areas</button>
                        <button type="button" [class.active-tab]="currentFeatureTab == 'peer-comparison'" class="progress-report-details-tab-button"  (click)="currentFeatureTab = 'peer-comparison'">Peer Comparison</button>
                    </div>
                </div>

                <div class="locked-screen">
                    <div class="background-image">
                        <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                    </div>
                    <div class="lock">
                        <div class="material-symbols-outlined lock-icon">lock</div>
                        <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get a Personal Mentor</button>
                    </div>
                </div>

                <!-- <ng-container *ngIf="currentFeatureTab == 'syllabus-tracker'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get a Personal Mentor</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentFeatureTab == 'weak-areas'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get a Personal Mentor</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentFeatureTab == 'peer-comparison'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get a Personal Mentor</button>
                        </div>
                    </div>
                </ng-container> -->
            </div>
        </div>
    </div>
</ng-container>