import { Component, OnInit } from '@angular/core';
import { MenteeProfileServiceAdapter } from './mentee-profile.service.adapter';
import { HttpClient } from '@angular/common/http';
import { Student } from '../common-classes/student';
import { Router } from '@angular/router';
import { MarketingText } from '../common-classes/marketing-text';
declare var $: any;  

type DropdownItem = {
    isSelected: boolean
    value: string
}

@Component({
    selector: 'app-mentee-profile',
    templateUrl: './mentee-profile.component.html',
    styleUrls: ['./mentee-profile.component.css']
})
export class MenteeProfileComponent implements OnInit {

    isLoading: boolean = false;
    checked = false;
    isLoggedIn: boolean = false;
    rollNumber: number = 10000000;

    marketingAddList: MarketingText[] = [];

    mobileNumberDefaultValue: string = "Mobile number";
    mobileNumber: string = this.mobileNumberDefaultValue;

    specialisedSubjectDefaultValue: string = "Specialised subject";
    specialisedSubject: string = this.specialisedSubjectDefaultValue;

    genderDefaultValue: string = "Gender";
    gender: string = this.genderDefaultValue;
    genderValueList: string[] = ['Male', 'Female', 'Transgender'];
    
    preparationStartedDefaultValue: string = "Preparation Started";
    preparationStarted: string = this.preparationStartedDefaultValue;
    preparationStartedValueList: string[] = [];

    anyCoachingJoinedDefaultValue: string = "Any Coaching Joined";
    anyCoachingJoined: string = this.anyCoachingJoinedDefaultValue;
    anyCoachingJoinedValueList: string[] = ['Yes', 'No'];

    optionalSubjectDefaultValue: string = "Optional Subject";
    optionalSubject: string = this.optionalSubjectDefaultValue;
    optionalSubjectValueList: string[] = [
        'Public Administration', 
        'Political Science & International Relations',
        'Sociology',
        'Geography',
        'Anthropology',
        'Agriculture',
        'Commerce & Accountancy',
        'Economics',
        'History ',
        'Law',
        'Philosophy',
        'Others',
    ];
    
    numberOfAttemptsDefaultValue: string = "No. of Attempts";
    numberOfAttempts: string = this.numberOfAttemptsDefaultValue;
    numberOfAttemptsValueList: string[] = [
        '1', 
        '2',
        '3',
        '4',
        '5',
        '6',
    ];

    nextExamAttemptDefaultValue: string = "Next Exam Attempt";
    nextExamAttempt: string = this.nextExamAttemptDefaultValue;
    nextExamAttemptValueList: string[] = [];

    numberOfPrelimsQualifiedDefaultValue: string = "No. of Prelims Qualified";
    numberOfPrelimsQualified: string = this.numberOfPrelimsQualifiedDefaultValue;
    numberOfPrelimsQualifiedValueList: string[] = [
        '1', 
        '2',
        '3',
        '4',
        '5',
        '6',
    ];

    numberOfMainsQualifiedDefaultValue: string = "No. of Mains Qualified";
    numberOfMainsQualified: string = this.numberOfMainsQualifiedDefaultValue;
    numberOfMainsQualifiedValueList: string[] = [
        '1', 
        '2',
        '3',
        '4',
        '5',
    ];

    weakSubjectAreaDefaultValue: string = "Weak Subject Area";
    weakSubjectArea: string = this.weakSubjectAreaDefaultValue;
    weakSubjectAreaValueList: DropdownItem[] = [
        {
            isSelected: false,
            value: 'CSAT',
        },
        {
            isSelected: false,
            value: 'Essay',
        },
        {
            isSelected: false,
            value: 'Ancient & Medieval History',
        },
        {
            isSelected: false,
            value: 'Art & Culture',
        },
        {
            isSelected: false,
            value: 'Modern History',
        },
        {
            isSelected: false,
            value: 'India & World Geography',
        },
        {
            isSelected: false,
            value: 'Indian Polity & Constitution',
        },
        {
            isSelected: false,
            value: 'Governance & Social Justice',
        },
        {
            isSelected: false,
            value: 'International Relations',
        },
        {
            isSelected: false,
            value: 'Indian Economy',
        },
        {
            isSelected: false,
            value: 'Ecology & Environment',
        },
        {
            isSelected: false,
            value: 'Science & Technology',
        },
        {
            isSelected: false,
            value: 'Internal Security',
        },
        {
            isSelected: false,
            value: 'Ethics, Integrity & Aptitude',
        },
    ];

    graduationYearDefaultValue: string = "Graduation Year";
    graduationYear: string = this.graduationYearDefaultValue;
    graduationYearValueList: string[] = [];

    graduationBackgroundDefaultValue: string = "Graduation Background";
    graduationBackground: string = this.graduationBackgroundDefaultValue;
    graduationBackgroundValueList: string[] = [
        'Engineering', 
        'Humanities',
        'Science',
        'Medical Science',
    ];

    currentPreparationLevelDefaultValue: string = "Current Prep. Level";
    currentPreparationLevel: string = this.currentPreparationLevelDefaultValue;
    currentPreparationLevelValueList: DropdownItem[] = [
        {
            isSelected: false,
            value: 'CSAT',
        },
        {
            isSelected: false,
            value: 'Essay',
        },
        {
            isSelected: false,
            value: 'Ancient & Medieval History',
        },
        {
            isSelected: false,
            value: 'Art & Culture',
        },
        {
            isSelected: false,
            value: 'Modern History',
        },
        {
            isSelected: false,
            value: 'India & World Geography',
        },
        {
            isSelected: false,
            value: 'Indian Polity & Constitution',
        },
        {
            isSelected: false,
            value: 'Governance & Social Justice',
        },
        {
            isSelected: false,
            value: 'International Relations',
        },
        {
            isSelected: false,
            value: 'Indian Economy',
        },
        {
            isSelected: false,
            value: 'Ecology & Environment',
        },
        {
            isSelected: false,
            value: 'Science & Technology',
        },
        {
            isSelected: false,
            value: 'Internal Security',
        },
        {
            isSelected: false,
            value: 'Ethics, Integrity & Aptitude',
        },
    ];

    menteeDetails: Student = new Student();
    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new MenteeProfileServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
        this.initializeDropdowns();
    }

    showBrTag(list: DropdownItem[]): boolean {
        let answer = false;
        list.forEach((element: DropdownItem) => {
            answer = answer || element.isSelected
        });
        return answer;
    }

    initializeDropdowns(): void {
        let currentYear: number = (new Date()).getFullYear();

        for (let yearI: number = 0; yearI < 6; yearI++) {
            let newYear = currentYear - yearI;
            this.preparationStartedValueList.push(newYear.toString());
        }
        this.preparationStartedValueList.push('Others');

        for (let yearI: number = 0; yearI < 3; yearI++) {
            let newYear = currentYear + yearI;
            this.nextExamAttemptValueList.push(newYear.toString());
        }
        this.nextExamAttemptValueList.push('Others');

        for (let yearI: number = 0; yearI < 5; yearI++) {
            let newYear = currentYear - yearI;
            this.graduationYearValueList.push(newYear.toString());
        }
        this.graduationYearValueList.push('Others');
    }

    scrollTo(className: string): void {
        $('html, body').animate({ scrollTop: $("." + className).offset().top });
    }

    updateStudentDetails() {
        if (this.mobileNumber != this.mobileNumberDefaultValue) this.menteeDetails.mobile_no = this.mobileNumber;
        if (this.specialisedSubject != this.specialisedSubjectDefaultValue) this.menteeDetails.specialised_subject = this.specialisedSubject;
        if (this.gender != this.genderDefaultValue) this.menteeDetails.gender = this.gender;
        if (this.preparationStarted != this.preparationStartedDefaultValue) this.menteeDetails.preparation_started = this.preparationStarted;
        if (this.anyCoachingJoined != this.anyCoachingJoinedDefaultValue) this.menteeDetails.any_coaching_joined = this.anyCoachingJoined;
        if (this.optionalSubject != this.optionalSubjectDefaultValue) this.menteeDetails.optional_subject = this.optionalSubject;
        if (this.numberOfAttempts != this.numberOfAttemptsDefaultValue) this.menteeDetails.no_of_attempts = this.numberOfAttempts;
        if (this.nextExamAttempt != this.nextExamAttemptDefaultValue) this.menteeDetails.next_exam_attempt = this.nextExamAttempt;
        if (this.numberOfPrelimsQualified != this.numberOfPrelimsQualifiedDefaultValue) this.menteeDetails.no_of_prelims_qualified = this.numberOfPrelimsQualified;
        if (this.numberOfMainsQualified != this.numberOfMainsQualifiedDefaultValue) this.menteeDetails.no_of_mains_qualified = this.numberOfMainsQualified;
        if (this.graduationYear != this.graduationYearDefaultValue) this.menteeDetails.graduation_year = this.graduationYear;
        if (this.graduationBackground != this.graduationBackgroundDefaultValue) this.menteeDetails.graduation_background = this.graduationBackground;

        let weakSubjectAreaList = [];
        for (let areaI = 0; areaI < this.weakSubjectAreaValueList.length; areaI++) {
            if (this.weakSubjectAreaValueList[areaI].isSelected) {
                weakSubjectAreaList.push({name: this.weakSubjectAreaValueList[areaI].value, id: 0});
            }
        }
        this.menteeDetails.weak_subject_areas = weakSubjectAreaList;

        let currentPrepLevelList = [];
        for (let areaI = 0; areaI < this.currentPreparationLevelValueList.length; areaI++) {
            if (this.currentPreparationLevelValueList[areaI].isSelected) {
                currentPrepLevelList.push({name: this.currentPreparationLevelValueList[areaI].value, id: 0});
            }
        }
        this.menteeDetails.current_level_of_preparation = currentPrepLevelList;
        // console.log("Student: ", this.menteeDetails);
        this.serviceAdapter.updateStudentDetails();
    }

    navigateToHome() {
        this.router.navigate(['/']);
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }
}
