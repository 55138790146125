import { MenteeProfileComponent } from './mentee-profile.component';
import { HttpClient } from '@angular/common/http';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class MenteeProfileServiceAdapter {
    
    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: MenteeProfileComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/student_profile/";
        const getStudentPromise = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentPromise,         // 0
            getMarketingAdds,          // 1
        ]).then(
            (value) => {
                // console.log(value);
                this.vm.menteeDetails = value[0];
                this.vm.marketingAddList = value[1];
                this.vm.isLoggedIn = true;

                for (let areaI = 0; areaI < this.vm.menteeDetails.weak_subject_areas.length; areaI++) {
                    for (let areaJ = 0; areaJ < this.vm.weakSubjectAreaValueList.length; areaJ++) {
                            if (this.vm.weakSubjectAreaValueList[areaJ].value == this.vm.menteeDetails.weak_subject_areas[areaI].name) {
                            this.vm.weakSubjectAreaValueList[areaJ].isSelected = true;
                            break;
                        }
                    }
                }

                for (let areaI = 0; areaI < this.vm.menteeDetails.current_level_of_preparation.length; areaI++) {
                    for (let areaJ = 0; areaJ < this.vm.currentPreparationLevelValueList.length; areaJ++) {
                            if (this.vm.currentPreparationLevelValueList[areaJ].value == this.vm.menteeDetails.current_level_of_preparation[areaI].name) {
                            this.vm.currentPreparationLevelValueList[areaJ].isSelected = true;
                            break;
                        }
                    }
                }

                // for (let areaI = 0; areaI < this.vm.menteeDetails.current_level_of_preparation.length; areaI++) {
                //     for (let areaJ = 0; areaJ < this.vm.currentPreparationLevelValueList.length; areaJ++) {
                //             if (this.vm.currentPreparationLevelValueList[areaJ].value == this.vm.menteeDetails.current_level_of_preparation[areaI].name) {
                //             this.vm.currentPreparationLevelValueList[areaJ].isSelected = true;
                //             break;
                //         }
                //     }
                // }
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.router.navigate(['/']);
            }
        );
        this.vm.isLoading = false;
    }

    async updateStudentDetails() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/student_profile/";
        const updateStudentPromise = this.http.put(apiString, this.vm.menteeDetails).toPromise();

        await Promise.all([
            updateStudentPromise         // 0
        ]).then(
            (value) => {
                // console.log(value);
                this.vm.menteeDetails = value[0];
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }
}
