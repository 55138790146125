export class CsatSummaryReport {
    accuracy: number = 0;
    id: number = 0;
    net_score: number = 0.0;
    number_of_correct: number = 0;
    number_of_incorrect: number = 0;
    number_of_unattempted: number = 0;
    student: number = 0;
    subtopic: number = 0;
    date: string = "";
}