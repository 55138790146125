import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FRONTEND } from 'src/app/environment/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {

    constructor(
        private router: Router,
    ) { }

    navigateToHome() {
        this.router.navigate(['/']);
    }

    navigateToBlogs() {
        this.router.navigate(['blog/']);
    }

    navigateToResources() {
        this.router.navigate(['resources']);
    }

    navigateToRefundPolicy() {
        let url = FRONTEND + "/refund-policy";
        window.open(url, '_blank');
    }

    navigateToPrivacyPolicy() {
        let url = FRONTEND + "/privacy-policy";
        window.open(url, '_blank');
    }

    navigateToTermsOfUse() {
        let url = FRONTEND + "/terms-of-use";
        window.open(url, '_blank');
    }

    navigateToTelegram() {
        window.open('https://t.me/freeiasprep', '_blank');
    }

    navigateToYouTube() {
        window.open('https://www.youtube.com/@LeagueofToppers', '_blank');
    }

    navigateToPricing() {
        this.router.navigate(['/'], { queryParams: { id: "subscription-plans" } });
    }
}
