import { HomepageComponent } from './homepage.component';
import { HttpClient } from '@angular/common/http';
import { DJANGO_SERVER } from 'src/app/environment/environment';

function _window(): any {
    return window;
}

export class HomepageServiceAdapter {
    
    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    get nativeWindow(): any {
        return _window();
    }

    initializeAdapter(vm: HomepageComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString =  DJANGO_SERVER + "/api/users/mentor_or_student_profile/";
        const getMentorOrStudentPromise = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/blog/get_all_testimonials/";
        const getTestiminials = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/get_all_plans/";
        const getPlans = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorOrStudentPromise,         // 0
        ]).then(
            (value) => {
                // console.log("User: ", value[0]);
                this.vm.isLoggedIn = true;
                this.vm.user = value[0];
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.gradientColor();
            }
        );

        await Promise.all([
            getMarketingAdds,                  // 0
            getTestiminials,                   // 1
            getPlans,                          // 2
        ]).then(
            (value) => {
                this.vm.marketingAddList = value[0];
                this.vm.testimonialList = value[1];
                this.vm.planList = value[2];
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
            }
        );

        this.vm.route.queryParamMap.subscribe(async (params: any) => {
            if (params.hasOwnProperty('params') && params.params.hasOwnProperty('payment_request_id')) {
                console.log('Id: ', params.params.payment_request_id);
                console.log('Status: ', params.params.payment_status);
                let status = params.params.payment_status;
                if (status == 'Credit') {
                    let transactionData = {
                        payment_id: params.params.payment_request_id,
                        payment_status: params.params.payment_status,
                    }

                    let apiString = DJANGO_SERVER + "/api/payment/create_payment/";
                    const updateTransactionDetails = this.http.put(apiString, transactionData).toPromise();

                    await Promise.all([
                        updateTransactionDetails         // 0
                    ]).then(
                        (value: any) => {
                            console.log(value);
                            this.vm.router.navigate(['study-plan/']);
                            this.vm.isLoading = false;
                            return;
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            }
        });

        this.vm.isLoading = false;
        this.vm.checkRouting();
    }

    // async getOrderId() {
    //     this.vm.isLoading = true;
    //     let apiString = DJANGO_SERVER + "/api/payment/create_transaction/";
    //     let data = {
    //         amount: this.vm.amount,
    //         plan_id: this.vm.plan_id,
    //         currency: "INR"
    //     };
    //     const postTransaction = this.http.post(apiString, data).toPromise();

    //     await Promise.all([
    //         postTransaction         // 0
    //     ]).then(
    //         (value: any) => {
    //             // console.log(value);
    //             this.vm.orderId = value[0].order_id;
    //             this.vm.payWithRazor(value[0].order_id);
    //         },
    //         (error) => {
    //             console.log(error);
    //         }
    //     );
    //     this.vm.isLoading = false;
    // }

    // async verifyTransaction(response: any) {
    //     this.vm.isLoading = true;
    //     let apiString = DJANGO_SERVER + "/api/payment/verify_txn/";
    //     const verifyTransaction = this.http.post(apiString, response).toPromise();

    //     await Promise.all([
    //         verifyTransaction         // 0
    //     ]).then(
    //         (value: any) => {
    //             // console.log(value);
    //         },
    //         (error) => {
    //             console.log(error);
    //         }
    //     );
    //     this.vm.isLoading = false;
    // }

    // async cancelTransaction(response: any) {
    //     this.vm.isLoading = true;
    //     let apiString = DJANGO_SERVER + "/api/payment/cancel_txn/";
    //     const cancelTransaction = this.http.post(apiString, response).toPromise();

    //     await Promise.all([
    //         cancelTransaction         // 0
    //     ]).then(
    //         (value: any) => {
    //             // console.log(value);
    //         },
    //         (error) => {
    //             console.log(error);
    //         }
    //     );
    //     this.vm.isLoading = false;
    // }

    async makePayment() {
        this.vm.isLoading = true;
        let planList = ["Prelims", "Mains", "CSAT"];
        let data = {
            amount: this.vm.amount,
            purpose: planList[this.vm.plan_id - 1] + ' Purchase',
            plan_id: this.vm.plan_id,
        }
        let apiString = DJANGO_SERVER + "/api/payment/create_payment/";
        const makeTransaction = this.http.post(apiString, data).toPromise();

        await Promise.all([
            makeTransaction         // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                window.open(value[0].longurl, "_self");
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }
}
