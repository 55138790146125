import { MentorshipComponent } from './mentorship.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { DJANGO_SERVER, WEBSOCKET_DOMAIN } from 'src/app/environment/environment';


export class MentorshipServiceAdapter {

    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: MentorshipComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/student_profile/";
        const getStudentPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentPromise         // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.student = value[0];
                this.vm.userId = value[0]['user']['id']; 
                this.vm.subscriptionPlanList = value[0].subscription_plan;
                this.vm.isLoggedIn = true;
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.isLoading = false;
                this.vm.router.navigate(['/']);
                return;
            }
        );

        apiString = DJANGO_SERVER + "/chat/get_room_for_student/";
        const getStudentRoom = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/upcoming_sessions/";
        const getUpcommigSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/chat/get_messages_in_room_for_student/";
        const getChatList = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/free_sessions/bookings/";
        const getFreeSessionList = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/student_past_sessions/";
        const getMenteePastSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/free_sessions/bookings_past/";
        const getFreePastSessionList = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentRoom,              // 0
            getUpcommigSessions,         // 1
            getChatList,                 // 2
            getFreeSessionList,          // 3
            getMenteePastSessions,       // 4
            getFreePastSessionList,      // 5
            getMarketingAdds,            // 6
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.roomId = value[0].id;
                this.vm.upcommingSessionList = value[1];
                this.vm.chatList = value[2];
                this.vm.socket = webSocket(WEBSOCKET_DOMAIN + '/ws/chat/' + value[0].id + '/');
                this.vm.socket.subscribe(
                    (message: any) => {
                        // console.log('Received message:', message['message']['content'], message["message"]['sender_id']);
                        this.vm.chatList.push({
                            content: message['message']['content'],
                            id: message['message']['message_id'],
                            sender: message['message']['sender_id'],
                            timestamp: "",
                        })
                    },
                    (error: any) => {
                        console.error('WebSocket error:', error);
                    },
                );

                this.vm.upcommingSessionList.sort(function(a: MentorshipSession, b: MentorshipSession) : number {
                    let dateA = a.date;
                    let dateB = b.date;
                    if (dateA < dateB) return -1;
                    else if (dateA > dateB) return 1;
                    else {
                        let timeA = a.time;
                        let timeB = b.time;
                        if (timeA <= timeB) return -1;
                        return 1;
                    }
                });

                this.vm.freeSessionList = value[3];
                this.vm.menteePastSessionList = value[4];
                this.vm.freePastSessionList = value[5];
                this.vm.marketingAddList = value[6];
            },
            (error) => {
                console.log(error);
            }
        );

        this.vm.route.queryParamMap.subscribe((params: any) => {
            if (params.hasOwnProperty('params') && params.params.hasOwnProperty('page')) {
                if (params.params.page == 'doubt') {
                    this.vm.currentTab = 'doubt-corner';
                }
            }
        });
        this.vm.isLoading = false;
    }

    send(message: any): void {
        // console.log("Message from student: ", message);
        this.vm.socket.next({ message: message, user_id: this.vm.userId });
    }

    onMessage(message: any): Observable<any> {
        // console.log("Message from mentor: ", message);
        return this.vm.socket;
    }

    async confirmSession(sessionIndex: number) {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/mentorship_session/accept_session/" + this.vm.upcommingSessionList[sessionIndex].id;
        let requestdata = {
            mode: this.vm.upcommingSessionList[sessionIndex].mode
        };
        const putUpcommigSessions = this.http.put(apiString, requestdata).toPromise();

        await Promise.all([
            putUpcommigSessions         // 0
        ]).then(
            (value) => {
                // console.log(value[0]);
                this.vm.upcommingSessionList[sessionIndex].is_confirmed = true;
                alert("Session Confirmed Successfully.");
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async confirmFreeSession(sessionIndex: number) {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/free_sessions/accept_free_session/" + this.vm.freeSessionList[sessionIndex].id;
        let requestdata = {
            mode: this.vm.freeSessionList[sessionIndex].mode
        };
        const putFreeSessions = this.http.put(apiString, requestdata).toPromise();

        await Promise.all([
            putFreeSessions         // 0
        ]).then(
            (value) => {
                // console.log(value[0]);
                this.vm.freeSessionList[sessionIndex].is_confirmed = true;
                alert("Session Confirmed Successfully.");
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async cancelSession(sessionIndex: number) {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/mentorship_session/cancel_session/" + this.vm.upcommingSessionList[sessionIndex].id;
        const putUpcommigSessions = this.http.put(apiString, {}).toPromise();

        await Promise.all([
            putUpcommigSessions         // 0
        ]).then(
            (value) => {
                // console.log(value[0]);
                this.vm.upcommingSessionList.splice(sessionIndex, 1);
                alert("Session Cancelled Successfully.");
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async cancelFreeSession(sessionIndex: number) {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/free_sessions/cancel_free_session/" + this.vm.freeSessionList[sessionIndex].id;
        const putFreeSessions = this.http.put(apiString, {}).toPromise();

        await Promise.all([
            putFreeSessions         // 0
        ]).then(
            (value) => {
                // console.log(value[0]);
                this.vm.freeSessionList.splice(sessionIndex, 1);
                alert("Session Cancelled Successfully.");
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async updateSession() {
        this.vm.isLoading = true;
        let sessionData = {
            mentorship_session_id: this.vm.sessionId,
            mode: this.vm.sessionMode
        }

        let apiString = DJANGO_SERVER + "/api/mentorship_session/upcoming_sessions/";
        const updateUpcomingSession = this.http.put(apiString, sessionData).toPromise();

        await Promise.all([
            updateUpcomingSession,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.upcommingSessionList[this.vm.sessionIdx].mode = this.vm.sessionMode;
                alert("Session Updated Successfully");
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );

        this.vm.sessionId = 0;
        this.vm.sessionIdx = 0;
        this.vm.sessionMode = "";
        this.vm.isLoading = false;
    }

    async updateFreeSession() {
        this.vm.isLoading = true;
        let sessionData = {
            mentorship_session_id: this.vm.sessionId,
            mode: this.vm.sessionMode
        }

        let apiString = DJANGO_SERVER + "/api/free_sessions/bookings/";
        const updateFreeSession = this.http.put(apiString, sessionData).toPromise();

        await Promise.all([
            updateFreeSession,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.freeSessionList[this.vm.sessionIdx].mode = this.vm.sessionMode;
                alert("Session Updated Successfully");
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );

        this.vm.sessionId = 0;
        this.vm.sessionIdx = 0;
        this.vm.sessionMode = "";
        this.vm.isLoading = false;
    }
}
