import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

import { SubmitAnswerModalComponent } from './submit-answer-modal/submit-answer-modal.component';
import { ViewEvaluatedCopyModalComponent } from './view-evaluated-copy-modal/view-evaluated-copy-modal.component';


@NgModule({
    declarations: [
        SubmitAnswerModalComponent,
        ViewEvaluatedCopyModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
    ],
    exports: [
        SubmitAnswerModalComponent,
        ViewEvaluatedCopyModalComponent,
    ],
})
export class LocalComponentsModule { }