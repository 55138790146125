import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider, GoogleInitOptions, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';

import { MatDialogModule } from '@angular/material/dialog';
import { GLOGIN_SECRETS } from '../environment/environment';


@NgModule({
    declarations: [LoginComponent],
    imports: [
        CommonModule,
        FormsModule,
        SocialLoginModule,
        MatDialogModule,
        GoogleSigninButtonModule,
    ],
    exports: [
        LoginComponent,
        FormsModule
    ],
    providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            GLOGIN_SECRETS,
                            {
                                oneTapEnabled: true,
                            }
                        )
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig
        }
    ],
    entryComponents: [],
})
export class LoginModule {}
