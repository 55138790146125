import { QuestionBankComponent } from './question-bank.component';
import { HttpClient } from '@angular/common/http';
import { SubTask } from 'src/app/common-classes/subtask';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class QuestionBankServiceAdapter {
    
    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: QuestionBankComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/student_profile/";
        const getStudentPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentPromise         // 0
        ]).then(
            (value: any) => {
                // console.log("Student: ", value);
                this.vm.student = value[0];
                this.vm.isLoggedIn = true;
                this.vm.subscriptionPlanList = value[0].subscription_plan;
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.isLoading = false;
                this.vm.router.navigate(['/']);
            }
        );

        apiString = DJANGO_SERVER + "/api/study_plan/personalized_plan/";
        const getStudentTopics = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/prelims_review_sessions/";
        const getStudentPrelimsSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/mains_review_sessions/";
        const getStudentMainsSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/csat_review_sessions/";
        const getStudentCSATSessions = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentTopics,               // 0
            getStudentPrelimsSessions,      // 1
            getStudentMainsSessions,        // 2
            getStudentCSATSessions,         // 3
            getMarketingAdds,               // 4
        ]).then(
            (value: any) => {
                // console.log("Response: ", value);
                this.vm.marketingAddList = value[4];
                if (value[0].hasOwnProperty('prelims') && value[0].prelims.hasOwnProperty('tasks')) {
                    this.vm.isPrelimsSubscribed = true;
                    this.vm.currentTab = "prelims";
                    let taskList = value[0].prelims.tasks;
                    let subtaskList = value[0].prelims.subtasks;

                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                        taskList[taskI]['subtaskList'].sort(function(a: SubTask, b: SubTask) : number {
                            let dateA = a.reading_end;
                            let dateB = b.reading_end;
                            if (dateA < dateB) return -1;
                            else return 1;
                        });
                    }
                    this.vm.prelimsTaskList = taskList;
                    // console.log("Prelims Task List: ", this.vm.prelimsTaskList);

                    let prelimsSessionList = value[1];
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let sessionI = 0; sessionI < prelimsSessionList.length; sessionI++) {
                            if (taskList[taskI].id == prelimsSessionList[sessionI].task.id) {
                                this.vm.prelimsQuestionsSessionsList.push(prelimsSessionList[sessionI]);
                                break;
                            }
                        }
                    }
                    // console.log("Prelims Sessions List: ", this.vm.prelimsQuestionsSessionsList);
                }

                if (value[0].hasOwnProperty('mains') && value[0].mains.hasOwnProperty('tasks')) {
                    this.vm.isMainsSubscribed = true;
                    if (!this.vm.currentTab) this.vm.currentTab = "mains";
                    let taskList = value[0].mains.tasks;
                    let subtaskList = value[0].mains.subtasks;

                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                        taskList[taskI]['subtaskList'].sort(function(a: SubTask, b: SubTask) : number {
                            let dateA = a.reading_end;
                            let dateB = b.reading_end;
                            if (dateA < dateB) return -1;
                            else return 1;
                        });
                    }
                    this.vm.mainsTaskList = taskList;
                    // console.log("Mains Task List: ", this.vm.mainsTaskList);

                    let mainsSessionList = value[2];
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let sessionI = 0; sessionI < mainsSessionList.length; sessionI++) {
                            if (taskList[taskI].id == mainsSessionList[sessionI].task.id) {
                                this.vm.mainsQuestionsSessionsList.push(mainsSessionList[sessionI]);
                                break;
                            }
                        }
                    }
                    // console.log("Mains Sessions List: ", this.vm.mainsQuestionsSessionsList);
                }
                
                if (value[0].hasOwnProperty('csat') && value[0].csat.hasOwnProperty('tasks')) {
                    this.vm.isCSATSubscribed = true;
                    if (!this.vm.currentTab) this.vm.currentTab = "csat";
                    let taskList = value[0].csat.tasks;
                    let subtaskList = value[0].csat.subtasks;

                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                        taskList[taskI]['subtaskList'].sort(function(a: SubTask, b: SubTask) : number {
                            let dateA = a.reading_end;
                            let dateB = b.reading_end;
                            if (dateA < dateB) return -1;
                            else return 1;
                        });
                    }
                    this.vm.csatTaskList = taskList;
                    // console.log("CSAT Task List: ", this.vm.csatTaskList);

                    let csatSessionList = value[3];
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let sessionI = 0; sessionI < csatSessionList.length; sessionI++) {
                            if (taskList[taskI].id == csatSessionList[sessionI].task.id) {
                                this.vm.csatQuestionsSessionsList.push(csatSessionList[sessionI]);
                                break;
                            }
                        }
                    }
                    // console.log("CSAT Sessions List: ", this.vm.csatQuestionsSessionsList);
                }

                if (!this.vm.currentTab) {
                    this.vm.currentTab = "prelims";
                }
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async fetchPrelimsQuestions() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/question_bank/prelims_questions/subtopic/" + this.vm.prelimsMicroTargetId;
        const getPrelimsQuestions = this.http.get(apiString).toPromise();

        await Promise.all([
            getPrelimsQuestions         // 0
        ]).then(
            (value: any) => {
                // console.log(value[0]);
                let questionsList = value[0];
                for (let questionI = 0; questionI < questionsList.length; questionI++) {
                    questionsList[questionI]['selectedOption'] = "";
                    questionsList[questionI]['ans'] = "";
                    questionsList[questionI]['is_correct'] = false;
                    questionsList[questionI]['is_answered'] = false;
                }
                this.vm.prelimsQuestionsList = questionsList;
                // console.log("Questions List: ", this.vm.prelimsQuestionsList);
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async fetchMainsQuestions() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/question_bank/mains_questions/subtopic/" + this.vm.mainsMicroTargetId;
        const getMainsQuestions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/question_bank/mains_responses/subtopic/" + this.vm.mainsMicroTargetId;
        const getMainsQuestionsResponse = this.http.get(apiString).toPromise();

        await Promise.all([
            getMainsQuestions,         // 0
            getMainsQuestionsResponse     // 1
        ]).then(
            (value: any) => {
                // console.log("Mains Response: ", value);
                let questionsList = value[0];
                let questionResponseList = value[1];
                for (let questionI = 0; questionI < questionsList.length; questionI++) {
                    questionsList[questionI]['answerLink'] = "";
                    questionsList[questionI]['answerFile'] = "";
                    questionsList[questionI]['evaluated_copy'] = "";
                    questionsList[questionI]['evaluated_file'] = "";
                    questionsList[questionI]['isDownloaded'] = false;
                    questionsList[questionI]['score'] = 0;
                }
                for (let questionI = 0; questionI < questionsList.length; questionI++) {
                    for (let questionResponseI = 0; questionResponseI < questionResponseList.length; questionResponseI++) {
                        if (questionsList[questionI].id == questionResponseList[questionResponseI].question.id) {
                            questionsList[questionI]['answerLink'] = questionResponseList[questionResponseI].response;
                            questionsList[questionI]['answerFile'] = questionResponseList[questionResponseI].response_file;
                            questionsList[questionI]['evaluated_copy'] = questionResponseList[questionResponseI].evaluated_copy;
                            questionsList[questionI]['evaluated_file'] = questionResponseList[questionResponseI].evaluated_file;
                            questionsList[questionI]['score'] = questionResponseList[questionResponseI].score;
                        }
                    }
                }
                this.vm.mainsQuestionsList = questionsList;
                // console.log("Questions List: ", this.vm.mainsQuestionsList);
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async fetchCsatQuestions() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/question_bank/csat_questions/subtopic/" + this.vm.csatMicroTargetId;
        const csatQuestionsList = this.http.get(apiString).toPromise();

        await Promise.all([
            csatQuestionsList         // 0
        ]).then(
            (value: any) => {
                // console.log(value[0]);
                let questionsList = value[0];
                for (let questionI = 0; questionI < questionsList.length; questionI++) {
                    questionsList[questionI]['selectedOption'] = "";
                    questionsList[questionI]['ans'] = "";
                    questionsList[questionI]['is_correct'] = false;
                    questionsList[questionI]['is_answered'] = false;
                }
                this.vm.csatQuestionsList = questionsList;
                // console.log("Questions List: ", this.vm.csatQuestionsList);
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async fetchPrelimsQuestionsSolution() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/question_bank/prelims_questions_for_solutions/subtopic/" + this.vm.prelimsMicroTargetId;
        const getPrelimsQuestions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/question_bank/prelims_responses/subtopic/" + this.vm.prelimsMicroTargetId;
        const getPrelimsQuestionsResponse = this.http.get(apiString).toPromise();

        await Promise.all([
            getPrelimsQuestions,         // 0
            getPrelimsQuestionsResponse        // 1
        ]).then(
            (value: any) => {
                // console.log(value);
                let questionsList = value[0];
                let questionResponseList = value[1];
                for (let questionI = 0; questionI < questionsList.length; questionI++) {
                    questionsList[questionI]['selectedOption'] = "";
                    questionsList[questionI]['is_correct'] = false;
                    questionsList[questionI]['is_answered'] = false;
                }

                for (let questionI = 0; questionI < questionsList.length; questionI++) {
                    for (let questionResponseI = 0; questionResponseI < questionResponseList.length; questionResponseI++) {
                        if (questionsList[questionI].id == questionResponseList[questionResponseI].question) {
                            questionsList[questionI]['selectedOption'] = questionResponseList[questionResponseI].response;
                            questionsList[questionI]['is_correct'] = questionResponseList[questionResponseI].is_correct;
                            questionsList[questionI]['is_answered'] = true;
                        }
                    }
                }
                this.vm.prelimsQuestionsList = questionsList;
                // console.log("Question List: ", this.vm.prelimsQuestionsList);
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async fetchCsatQuestionsSolution() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/question_bank/csat_questions_for_solutions/subtopic/" + this.vm.csatMicroTargetId;
        const getCsatQuestions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/question_bank/csat_responses/subtopic/" + this.vm.csatMicroTargetId;
        const getCsatQuestionsResponse = this.http.get(apiString).toPromise();

        await Promise.all([
            getCsatQuestions,         // 0
            getCsatQuestionsResponse        // 1
        ]).then(
            (value: any) => {
                // console.log(value);
                let questionsList = value[0];
                let questionResponseList = value[1];
                for (let questionI = 0; questionI < questionsList.length; questionI++) {
                    questionsList[questionI]['selectedOption'] = "";
                    questionsList[questionI]['is_correct'] = false;
                    questionsList[questionI]['is_answered'] = false;
                }

                for (let questionI = 0; questionI < questionsList.length; questionI++) {
                    for (let questionResponseI = 0; questionResponseI < questionResponseList.length; questionResponseI++) {
                        if (questionsList[questionI].id == questionResponseList[questionResponseI].question) {
                            questionsList[questionI]['selectedOption'] = questionResponseList[questionResponseI].response;
                            questionsList[questionI]['is_correct'] = questionResponseList[questionResponseI].is_correct;
                            questionsList[questionI]['is_answered'] = true;
                        }
                    }
                }
                this.vm.csatQuestionsList = questionsList;
                // console.log("Question List: ", this.vm.csatQuestionsList);
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async submitPrelimsTest() {
        this.vm.isLoading = true;
        let answerResponseList = [];
        for (let questionI = 0; questionI < this.vm.prelimsQuestionsList.length; questionI++) {
            if (this.vm.prelimsQuestionsList[questionI]['selectedOption']) {
                let answerResponse = {
                    question_id: this.vm.prelimsQuestionsList[questionI].id,
                    response: this.vm.prelimsQuestionsList[questionI].selectedOption,
                };
                answerResponseList.push(answerResponse);
            }
        }
        // console.log("Answer Response List: ", answerResponseList);

        let apiString = DJANGO_SERVER + "/api/question_bank/prelims_submit/";
        let responseData = {
            responses: answerResponseList,
            subtopic_id: this.vm.prelimsMicroTargetId
        };
        const postPrelimsQuestions = this.http.post(apiString, responseData).toPromise();

        await Promise.all([
            postPrelimsQuestions         // 0
        ]).then(
            (value: any) => {
                // console.log(value[0]);
                this.vm.prelimsTaskList[this.vm.prelimsMilestonetIdx].subtaskList[this.vm.prelimsMicroTargetIdx - 1].questions_completed = true;
                this.vm.prelimsSummaryDetails = value[0].report;
                this.vm.rank = value[0].rank;
                this.vm.total = value[0].total;
                this.vm.pageMode = "summary-prelims";
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async submitCsatTest() {
        this.vm.isLoading = true;
        let answerResponseList = [];
        for (let questionI = 0; questionI < this.vm.csatQuestionsList.length; questionI++) {
            if (this.vm.csatQuestionsList[questionI]['selectedOption']) {
                let answerResponse = {
                    question_id: this.vm.csatQuestionsList[questionI].id,
                    response: this.vm.csatQuestionsList[questionI].selectedOption,
                };
                answerResponseList.push(answerResponse);
            }
        }
        // console.log("Answer Response List: ", answerResponseList);

        let apiString = DJANGO_SERVER + "/api/question_bank/csat_submit/";
        let responseData = {
            responses: answerResponseList,
            subtopic_id: this.vm.csatMicroTargetId
        };
        const postCsatQuestions = this.http.post(apiString, responseData).toPromise();

        await Promise.all([
            postCsatQuestions         // 0
        ]).then(
            (value: any) => {
                // console.log(value[0])
                this.vm.mainsTaskList[this.vm.csatMilestonetIdx].subtaskList[this.vm.csatMicroTargetIdx - 1].questions_completed = true;
                this.vm.csatSummaryDetails = value[0].report;
                this.vm.rank = value[0].rank;
                this.vm.total = value[0].total;
                this.vm.pageMode = "summary-csat";
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async fetchPrelimsSummary() {
        this.vm.isLoading = true;

        let apiString = DJANGO_SERVER + "/api/question_bank/prelims_summary_report/subtopic/" + this.vm.prelimsMicroTargetId;
        const getPrelimsSummary = this.http.get(apiString).toPromise();

        await Promise.all([
            getPrelimsSummary         // 0
        ]).then(
            (value: any) => {
                // console.log(value[0]);
                this.vm.prelimsTaskList[this.vm.prelimsMilestonetIdx].subtaskList[this.vm.prelimsMicroTargetIdx - 1].questions_completed = true;
                this.vm.prelimsSummaryDetails = value[0].report;
                this.vm.rank = value[0].rank;
                this.vm.total = value[0].total;
                this.vm.pageMode = "summary-prelims";
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async fetchMainsSummary() {
        this.vm.isLoading = true;

        let apiString = DJANGO_SERVER + "/api/question_bank/mains_summary_report/subtopic/" + this.vm.mainsMicroTargetId;
        const getMainsSummary = this.http.get(apiString).toPromise();

        await Promise.all([
            getMainsSummary         // 0
        ]).then(
            (value: any) => {
                // console.log(value[0]);
                this.vm.mainsSummaryDetails = value[0];
                this.vm.pageMode = "summary-mains";
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async fetchCsatSummary() {
        this.vm.isLoading = true;

        let apiString = DJANGO_SERVER + "/api/question_bank/csat_summary_report/subtopic/" + this.vm.csatMicroTargetId;
        const getCsatSummary = this.http.get(apiString).toPromise();

        await Promise.all([
            getCsatSummary         // 0
        ]).then(
            (value: any) => {
                // console.log(value[0]);
                this.vm.mainsTaskList[this.vm.csatMilestonetIdx].subtaskList[this.vm.csatMicroTargetIdx - 1].questions_completed = true;
                this.vm.csatSummaryDetails = value[0].report;
                this.vm.rank = value[0].rank;
                this.vm.total = value[0].total;
                this.vm.pageMode = "summary-csat";
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async submitMainsAnswer() {
        this.vm.isLoading = true;
        // let answerResponseList = [];
        // let answerResponse = {
        //     question_id: this.vm.mainsQuestionsList[this.vm.currentMainsQuestion].id,
        //     response_file: this.vm.mainsQuestionsList[this.vm.currentMainsQuestion].answerFile,
        //     // response: this.vm.mainsQuestionsList[this.vm.currentMainsQuestion].answerLink,
        // };
        // answerResponseList.push(answerResponse);
        // console.log("Answer Response List: ", answerResponseList);

        // let responseData = {
        //     responses: answerResponseList,
        //     subtopic_id: this.vm.mainsMicroTargetId
        // };

        let answerResposeFormData = new FormData();
        answerResposeFormData.append('response_file', this.vm.mainsQuestionsList[this.vm.currentMainsQuestion].answerFile)
        answerResposeFormData.append('question_id', this.vm.mainsQuestionsList[this.vm.currentMainsQuestion].id)
        answerResposeFormData.append('response', '')
        answerResposeFormData.append('subtopic_id', this.vm.mainsMicroTargetId)
        
        let apiString = DJANGO_SERVER + "/api/question_bank/mains_submit/";
        const postMainsQuestions = this.http.post(apiString, answerResposeFormData).toPromise();

        await Promise.all([
            postMainsQuestions         // 0
        ]).then(
            (value: any) => {
                console.log(value);
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }
}
