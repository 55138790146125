import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { HomepageModule } from './homepage/homepage.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MentorsPageModule } from './mentors-page/mentors-page.module';
import { BlogModule } from './blog/blog.module';
import { DemoSessionModule } from './demo-session/demo-session.module';
import { MenteeProfileModule } from './mentee-profile/mentee-profile.module';
import { LoginModule } from './login/login.module';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { TabsModule } from './tabs/tabs.module';
import { BasicComponentsModule } from './basic-components/basic-components.module';
import { MentorProfileModule } from './mentor-profile/mentor-profile.module';
import { ResourcesModule } from './resources/resources.module';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HomepageModule,
        MentorsPageModule,
        BlogModule,
        DemoSessionModule,
        MenteeProfileModule,
        LoginModule,
        TabsModule,
        BasicComponentsModule,
        MentorProfileModule,
        ResourcesModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
