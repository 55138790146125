import { HttpClient } from '@angular/common/http';
import { DJANGO_SERVER } from 'src/app/environment/environment';
import { ResourcesComponent } from './resources.component';


export class ResourcesServiceAdapter {
    
    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: ResourcesComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString =  DJANGO_SERVER + "/api/users/mentor_or_student_profile/";
        const getMentorOrStudentPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorOrStudentPromise         // 0
        ]).then(
            (value) => {
                // console.log("User: ", value[0]);
                this.vm.isLoggedIn = true;
                this.vm.user = value[0];
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
            }
        );

        apiString = DJANGO_SERVER + "/api/resource/get_all_resources/";
        const getResourcesList = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getResourcesList,         // 0
            getMarketingAdds,         // 1
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.resourceList = value[0];
                this.vm.marketingAddList = value[1];
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }
}
