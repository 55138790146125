import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-upload-report',
    templateUrl: './upload-report.component.html',
    styleUrls: ['./upload-report.component.css']
})
export class UploadReportComponent {
    link: string = "";
    file: any;

    constructor(
        public dialogRef: MatDialogRef<UploadReportComponent>,
    ) {}

    submitModal(): void {
        this.dialogRef.close({file: this.file});
    }

    onFileSelected(event: any): void {
        const newFile: File = event.target.files[0];
        if (newFile) {
            this.file = newFile;
        }
    }
}
