import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewPlansServiceAdapter } from './view-plans.service.adapter';
import { SubscriptionPlan } from 'src/app/common-classes/subscription-plan';
import { Student } from 'src/app/common-classes/student';
import { MarketingText } from 'src/app/common-classes/marketing-text';


@Component({
    selector: 'app-view-plans',
    templateUrl: './view-plans.component.html',
    styleUrls: ['./view-plans.component.css']
})
export class ViewPlansComponent implements OnInit {

    isLoading: boolean = false;
    isLoggedIn: boolean = false;
    currentSidebarTab: string = "view-plans";
    currentTab: string = "";
    student: Student = new Student();

    isPrelimsSubscribed: boolean = false;
    isMainsSubscribed: boolean = false;
    isCSATSubscribed: boolean = false;
    subscriptionPlanList: SubscriptionPlan[] = [];
    marketingAddList: MarketingText[] = [];

    prelimsFeatureList: string[] = [
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        // '1. Syllabus Tracker',
    ];

    mainsFeatureList: string[] = [
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
    ];

    csatFeatureList: string[] = [
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
        '1. Syllabus Tracker',
    ];

    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new ViewPlansServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    navigateToStudyPlan(): void {
        this.router.navigate(['study-plan/']);
    }

    navigateToHome(): void {
        this.router.navigate(['/']);
    }

    navigateToPricing() {
        this.router.navigate(['/'], { queryParams: { id: "subscription-plans" } });
    }
}
