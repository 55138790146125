import { MenteeListingComponent } from './mentee-listing.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { Task } from 'src/app/common-classes/task';
import { DJANGO_SERVER, WEBSOCKET_DOMAIN } from 'src/app/environment/environment';


export class MenteeListingServiceAdapter {

    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: MenteeListingComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/mentor_profile/";
        const getMentorPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorPromise         // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.isLoggedIn = true;
                this.vm.userId = value[0]['user']['id']; 
                this.vm.mentor = value[0];
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.isLoading = false;
                this.vm.router.navigate(['/']);
                return;
            }
        );

        apiString = DJANGO_SERVER + "/api/users/mentees_of_mentor/";
        const getMenteeOfMentorPromise = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/topics/";
        const getTopics = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getMenteeOfMentorPromise,                 // 0
            getTopics,                                // 1
            getMarketingAdds,                         // 2
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.prelimsMenteeList = value[0].prelims;
                this.vm.mainsMenteeList = value[0].mains;
                this.vm.csatMenteeList = value[0].csat;

                this.vm.planTopicList = value[1];
                this.vm.marketingAddList = value[2];
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async getMenteeData(studentId: number) {
        this.vm.isLoading = true;
        
        let planId = 1;
        if (this.vm.currentTabMentor == "mains") planId = 2;
        if (this.vm.currentTabMentor == "csat") planId = 3;

        let apiString = DJANGO_SERVER + "/api/study_plan/personalized_plan/" + studentId + "/" + planId;
        const getStudentStudyPlan = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/chat/get_room_for_mentor/" + studentId;
        const getStudentRoom = this.http.get(apiString).toPromise();

        let topicProgressUrl = this.vm.currentTabMentor + "_topics_progress/" + studentId;
        let topicDailyTargetsUrl = this.vm.currentTabMentor + "_topics_daily_targets/" + studentId;
        let topicDailyAccuracyUrl = this.vm.currentTabMentor + "_topics_daily_accuracy/" + studentId;
        let weakAreaSummaryReportUrl = this.vm.currentTabMentor + "_weak_areas_summary_report/" + studentId;

        apiString = DJANGO_SERVER + "/api/study_plan/" + topicProgressUrl;
        const getStudentTopicProgress = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/" + topicDailyTargetsUrl;
        const getStudentTopicDailyTargets = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/" + topicDailyAccuracyUrl;
        const getStudentTopicDailyAccuracy = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/question_bank/" + weakAreaSummaryReportUrl;
        const getWeakSummaryReport = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentStudyPlan,                               // 0
            getStudentRoom,                                    // 1
            getStudentTopicProgress,                           // 2
            getStudentTopicDailyTargets,                       // 3
            getStudentTopicDailyAccuracy,                      // 4
            getWeakSummaryReport,                              // 5
        ]).then(
            (value: any) => {
                // console.log(value);

                this.vm.roomId = value[1].id;
                this.vm.socket = webSocket(WEBSOCKET_DOMAIN + '/ws/chat/' + this.vm.roomId + '/');
                this.vm.socket.subscribe(
                    (message: any) => {
                        // console.log('Received message:', message['message'], message['sender_id']);
                        this.vm.menteeChatList.push({
                            content: message['message']['content'],
                            id: message['message']['message_id'],
                            sender: message['message']['sender_id'],
                            timestamp: "",
                        })
                      // Process the message according to your needs
                    },
                    (error: any) => {
                        // console.error('WebSocket error:', error);
                    },
                );

                if (value[0].hasOwnProperty('tasks')) {
                    let taskList = value[0].tasks;
                    let subtaskList = value[0].subtasks;
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                    }
                    this.vm.menteeTaskList = taskList;
                    // console.log("Mentee Task List: ", this.vm.menteeTaskList);

                    let menteeSessionList = value[0].sessions;
                    this.vm.menteeSessionList = [];
                    this.vm.menteeReadingSessionList = [];
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let sessionI = 0; sessionI < menteeSessionList.length; sessionI++) {
                            if (taskList[taskI].id == menteeSessionList[sessionI].task.id) {
                                this.vm.menteeSessionList.push(menteeSessionList[sessionI]);
                                if (menteeSessionList[sessionI].category.includes("Reading")) {
                                    this.vm.menteeReadingSessionList.push(menteeSessionList[sessionI]);
                                }
                            }
                        }
                    }
                    // console.log("Mentee Sessions List: ", this.vm.menteeSessionList);
                }

                if (value[0].hasOwnProperty('chat_sessions')) {
                    this.vm.menteeChatSessionList = value[0].chat_sessions;
                }
                // console.log("Mentee Chat Session List: ", this.vm.menteeChatSessionList);

                if (value[0].hasOwnProperty('messages')) {
                    this.vm.menteeChatList = value[0].messages;
                }
                // console.log("Mentee Chat List: ", this.vm.menteeChatList);

                if (value[0].hasOwnProperty('mains_copies')) {
                    this.vm.menteeMainsCopyList = value[0].mains_copies;
                }
                // console.log("Mentee Mains Copies List: ", this.vm.menteeMainsCopyList);

                let menteeTopicProgressList = value[2];
                this.vm.menteeTopicProgressList = []
                for (let taskI = 0; taskI < this.vm.menteeTaskList.length; taskI++) {
                    for (let progressI = 0; progressI < menteeTopicProgressList.length; progressI++) {
                        if (this.vm.menteeTaskList[taskI].id == menteeTopicProgressList[progressI].task_id) {
                            this.vm.menteeTopicProgressList.push(menteeTopicProgressList[progressI]);
                            break;
                        }
                    }
                }
                // console.log("Progress List: ", this.vm.menteeTopicProgressList);

                let menteeTopicDailyTargetList = value[3];
                this.vm.menteeTopicDailyTargetList = [];
                for (let taskI = 0; taskI < this.vm.menteeTaskList.length; taskI++) {
                    for (let progressI = 0; progressI < menteeTopicDailyTargetList.length; progressI++) {
                        if (this.vm.menteeTaskList[taskI].id == menteeTopicDailyTargetList[progressI].task_id) {
                            this.vm.menteeTopicDailyTargetList.push(menteeTopicDailyTargetList[progressI]);
                            break;
                        }
                    }
                }
                // console.log("Progress Daily Target List: ", this.vm.menteeTopicDailyTargetList);

                let menteeTopicDailyAccuracyList = value[4];
                this.vm.menteeTopicDailyAccuracyList = [];
                for (let taskI = 0; taskI < this.vm.menteeTaskList.length; taskI++) {
                    for (let progressI = 0; progressI < menteeTopicDailyAccuracyList.length; progressI++) {
                        if (this.vm.menteeTaskList[taskI].id == menteeTopicDailyAccuracyList[progressI].task_id) {
                            this.vm.menteeTopicDailyAccuracyList.push(menteeTopicDailyAccuracyList[progressI]);
                            break;
                        }
                    }
                }
                // console.log("Progress Daily Accuracy List: ", this.vm.menteeTopicDailyAccuracyList);

                let weakTopicSummaryDetails = value[5];
                for (let taskI = 0; taskI < this.vm.menteeTaskList.length; taskI++) {
                    for (let progressI = 0; progressI < weakTopicSummaryDetails.length; progressI++) {
                        if (this.vm.menteeTaskList[taskI].id == weakTopicSummaryDetails[progressI].task_id) {
                            this.vm.menteeWeakTopicSummaryDetails.push(weakTopicSummaryDetails[progressI]);
                            break;
                        }
                    }
                }
                // console.log("Prelims Weak Area Summary List: ", this.vm.menteeWeakTopicSummaryDetails);
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    send(message: any): void {
        // console.log("Message sent by mentor: ", message)
        this.vm.socket.next({ message: message, user_id: this.vm.userId });
    }

    onMessage(): Observable<any> {
        return this.vm.socket.asObservable();;
    }

    async uploadEvaluatedCopy(copy: File, copyScore: number, copyId: number, copyIndex: number) {
        this.vm.isLoading = true;

        // let responseData = {
        //     evaluated_copy: copy,
        //     score: copyScore,
        //     response_id: copyId
        // };

        let responseFormData = new FormData();
        responseFormData.append('evaluated_file', copy)
        responseFormData.append('score', copyScore.toString())
        responseFormData.append('response_id', copyId.toString())

        let apiString = DJANGO_SERVER + "/api/question_bank/upload_evaluated_mains_copy/" + this.vm.mentee.id;
        const getStudentStudyPlan = this.http.put(apiString, responseFormData).toPromise();

        await Promise.all([
            getStudentStudyPlan,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.menteeMainsCopyList[copyIndex].evaluated_file = value[0].evaluated_file;
                this.vm.menteeMainsCopyList[copyIndex].score = value[0].score;
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async addSession() {
        // console.log("Title: ", this.vm.sessionTitle);
        // console.log("Date: ", this.vm.sessionDate);
        // console.log("Time: ", this.vm.sessionTime);

        this.vm.isLoading = true;
        let sessionData = {
            title: this.vm.sessionTitle,
            category: "Chat",
            date: this.vm.sessionDate,
            time: this.vm.sessionTime,
            student_id: this.vm.mentee.id
        };

        let apiString = DJANGO_SERVER + "/api/mentorship_session/upcoming_sessions/";
        const postMentorshipSession = this.http.post(apiString, sessionData).toPromise();

        await Promise.all([
            postMentorshipSession,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.menteeChatSessionList.push(value[0]);
                this.vm.menteeChatSessionList.sort(function(a: MentorshipSession, b: MentorshipSession) : number {
                    let dateA = a.date;
                    let dateB = b.date;
                    if (dateA < dateB) return -1;
                    else if (dateA > dateB) return 1;
                    else {
                        let timeA = a.time;
                        let timeB = b.time;
                        if (timeA <= timeB) return -1;
                        return 1;
                    }
                });

                this.vm.sessionTitle = "";
                this.vm.sessionDate = "";
                this.vm.sessionTime = "";
                this.vm.currentSessionTab = "sessions";
                alert("Session Added Successfully.");
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );
        this.vm.isLoading = false;
    }

    async updateSession() {
        this.vm.isLoading = true;
        let sessionData = {
            mentorship_session_id: this.vm.sessionId,
            date: this.vm.sessionDate,
            time: this.vm.sessionTime,
        }

        let apiString = DJANGO_SERVER + "/api/mentorship_session/upcoming_sessions/";
        const updateUpcomingSession = this.http.put(apiString, sessionData).toPromise();

        await Promise.all([
            updateUpcomingSession,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                if (this.vm.sessionType == 'review') {
                    this.vm.menteeSessionList[this.vm.sessionIdx].date = this.vm.sessionDate;
                    this.vm.menteeSessionList[this.vm.sessionIdx].time = this.vm.sessionTime;
    
                    this.vm.menteeSessionList.sort(function(a: MentorshipSession, b: MentorshipSession) : number {
                        let dateA = a.date;
                        let dateB = b.date;
                        if (dateA < dateB) return -1;
                        else if (dateA > dateB) return 1;
                        else {
                            let timeA = a.time;
                            let timeB = b.time;
                            if (timeA <= timeB) return -1;
                            return 1;
                        }
                    });
                } else if (this.vm.sessionType == 'chat') {
                    this.vm.menteeChatSessionList[this.vm.sessionIdx].date = this.vm.sessionDate;
                    this.vm.menteeChatSessionList[this.vm.sessionIdx].time = this.vm.sessionTime;

                    this.vm.menteeChatSessionList.sort(function(a: MentorshipSession, b: MentorshipSession) : number {
                        let dateA = a.date;
                        let dateB = b.date;
                        if (dateA < dateB) return -1;
                        else if (dateA > dateB) return 1;
                        else {
                            let timeA = a.time;
                            let timeB = b.time;
                            if (timeA <= timeB) return -1;
                            return 1;
                        }
                    });
                }
                alert("Session Updated Successfully");
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );

        this.vm.sessionId = 0;
        this.vm.sessionIdx = 0;
        this.vm.sessionDate = "";
        this.vm.sessionTime = "";
        this.vm.currentSessionTab = "sessions";
        this.vm.isLoading = false;
    }

    async addPlan() {
        this.vm.isLoading = true;
        let planData = {
            topic_id: this.vm.planTopicId,
            order: this.vm.planOrder,
            reading_start: this.vm.planReadingStartDate,
            reading_end: this.vm.planReadingEndDate,
            questions_start: this.vm.planQuestionsStartDate,
            questions_end: this.vm.planQuestionsEndDate
        };

        let apiString = DJANGO_SERVER + "/api/study_plan/assign_task/" + this.vm.mentee.id;
        const getStudentStudyPlan = this.http.post(apiString, planData).toPromise();

        await Promise.all([
            getStudentStudyPlan,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                let newTask: Task = value[0].tasks;
                newTask['isOpen'] = false;
                newTask['subtaskList'] = value[0].subtasks;
                this.vm.menteeTaskList.push(newTask);

                let sessionList = value[0].session;
                for (let sessionI = 0; sessionI < sessionList.length; sessionI++) {
                    if (newTask.id == sessionList[sessionI].task.id) {
                        this.vm.menteeSessionList.push(sessionList[sessionI]);
                        if (sessionList[sessionI].category.includes("Reading")) {
                            this.vm.menteeReadingSessionList.push(sessionList[sessionI]);
                        }
                    }
                }

                this.vm.planTopicId = 0;
                this.vm.planOrder = -1;
                this.vm.planReadingStartDate = "";
                this.vm.planReadingEndDate = "";
                this.vm.planQuestionsStartDate = "";
                this.vm.planQuestionsEndDate = "";
                this.vm.currentStudyPlanTab = "plan";
                alert("Task Added Successfully.");
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );
        this.vm.isLoading = false;
    }

    async uploadSessionReport(report: File, sessionId: number, sessionIdx: number, sessionType: string) {
        this.vm.isLoading = true;
        // let reportData = {
        //     analysis_report: reportLink,
        // };

        let reportFormData = new FormData();
        reportFormData.append('analysis_report_file', report)

        let apiString = DJANGO_SERVER + "/api/mentorship_session/upload_analysis_report/" + sessionId;
        const putSessionReport = this.http.put(apiString, reportFormData).toPromise();

        await Promise.all([
            putSessionReport,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                if (sessionType == 'review') {
                    this.vm.menteeSessionList[sessionIdx].analysis_report_file = value[0].analysis_report_file;
                } else if (sessionType == 'chat') {
                    this.vm.menteeChatSessionList[sessionIdx].analysis_report_file = value[0].analysis_report_file;
                }
                alert("Report Uploaded Successfully.");
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );
        this.vm.isLoading = false;
    }
}
