import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    @Input() currentSidebarTab: string = "";
    userType: string = "";
    isMobileScreen: boolean = false;
    isMobileSidebarOpen: boolean = true;

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
        this.userType = localStorage.getItem('mentorshut_user_data_type') ? JSON.parse(localStorage.getItem('mentorshut_user_data_type') as string) : '';
        this.checkScreenSize();
    }

      // Update the screen size variable on window resize
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkScreenSize();
    }

    // Toggle the mobile sidebar open/closed
    toggleMobileSidebar() {
        this.isMobileSidebarOpen = !this.isMobileSidebarOpen;
    }

    // Check the screen size and update the variable
    private checkScreenSize() {
        this.isMobileScreen = window.innerWidth < 768; // Adjust the breakpoint as needed
    }

    changeSidebarTab(path: string): void {
        this.router.navigate([path]);
    }
}
