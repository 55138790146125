import { NgModule } from '@angular/core';

// import { CountAllRoutingModule } from './count-all.routing';
import { MentorsPageComponent } from './mentors-page.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BasicComponentsModule } from '../basic-components/basic-components.module';

@NgModule({
    declarations: [MentorsPageComponent],
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        BasicComponentsModule
    ],
    exports: [
        MentorsPageComponent,
        MatFormFieldModule,
        MatSelectModule,
    ],
    providers: [],
    entryComponents: [],
})
export class MentorsPageModule {}
