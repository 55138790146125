import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainsCopiesServiceAdapter } from './mains-copies.service.adapter';
import { MainsResponse } from 'src/app/common-classes/mains-response';
import { MatDialog } from '@angular/material/dialog';
import { UploadMainsCopyComponent } from '../mentee-listing/local-components/upload-mains-copy/upload-mains-copy.component';
import { Mentor } from 'src/app/common-classes/mentor';
import { DJANGO_SERVER } from 'src/app/environment/environment';
import { MarketingText } from 'src/app/common-classes/marketing-text';


@Component({
    selector: 'app-mains-copies',
    templateUrl: './mains-copies.component.html',
    styleUrls: ['./mains-copies.component.css']
})
export class MainsCopiesComponent implements OnInit {

    isLoading: boolean = false;
    isLoggedIn: boolean = false;
    mentor: Mentor = new Mentor();
    currentSidebarTab: string = "mains-copies";
    currentTab: string = "unevaluated";

    menteeCopyList: MainsResponse[] = [];
    marketingAddList: MarketingText[] = [];

    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        public dialog: MatDialog,
    )  { }

    ngOnInit(): void {
        this.serviceAdapter = new MainsCopiesServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    changeTab(tabName: string) {
        this.currentTab = tabName;
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    downloadAnswer(copy: MainsResponse): void {
        if (copy.response) {
            window.open(copy.response, '_blank');
        } else if (copy.response_file) {
            window.open(DJANGO_SERVER + copy.response_file, '_blank');
        }
    }

    uploadAnswerDialog(copyIndex: number): void {
        const dialogRef = this.dialog.open(UploadMainsCopyComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.hasOwnProperty("file")) {
                this.serviceAdapter.uploadEvaluatedCopy(result.file, result.score, this.menteeCopyList[copyIndex].id, copyIndex, this.menteeCopyList[copyIndex].student.id);
            }
        });
    }
}
