import { SubTask } from 'src/app/common-classes/subtask';
import { StudyPlanComponent } from './study-plan.component';
import { HttpClient } from '@angular/common/http';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class StudyPlanServiceAdapter {
    
    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: StudyPlanComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/student_profile/";
        const getStudentPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentPromise         // 0
        ]).then(
            (value: any) => {
                // console.log("Student: ", value);
                this.vm.student = value[0];
                this.vm.isLoggedIn = true;
                this.vm.subscriptionPlanList = value[0].subscription_plan;
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.isLoading = false;
                this.vm.router.navigate(['/']);
                return;
            }
        );

        apiString = DJANGO_SERVER + "/api/study_plan/personalized_plan/";
        const getStudentTopics = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/prelims_review_sessions/";
        const getStudentPrelimsSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/mains_review_sessions/";
        const getStudentMainsSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/csat_review_sessions/";
        const getStudentCSATSessions = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentTopics,             // 0
            getStudentPrelimsSessions,    // 1
            getStudentMainsSessions,      // 2
            getStudentCSATSessions,       // 3
            getMarketingAdds,             // 4
        ]).then(
            (value: any) => {
                // console.log("Student Topics: ", value);
                this.vm.marketingAddList = value[4];
                if (value[0].hasOwnProperty('prelims') && value[0].prelims.hasOwnProperty('tasks')) {
                    this.vm.isPrelimsSubscribed = true;
                    this.vm.currentTab = "prelims";
                    let taskList = value[0].prelims.tasks;
                    let subtaskList = value[0].prelims.subtasks;

                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                        taskList[taskI]['subtaskList'].sort(function(a: SubTask, b: SubTask) : number {
                            let dateA = a.reading_end;
                            let dateB = b.reading_end;
                            if (dateA < dateB) return -1;
                            else return 1;
                        });
                    }
                    this.vm.prelimsTaskList = taskList;
                    // console.log("Prelims Task List: ", this.vm.prelimsTaskList);

                    let prelimsSessionList = value[1];
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let sessionI = 0; sessionI < prelimsSessionList.length; sessionI++) {
                            if (taskList[taskI].id == prelimsSessionList[sessionI].task.id) {
                                this.vm.prelimsSessionsList.push(prelimsSessionList[sessionI]);
                                break;
                            }
                        }
                    }
                    // console.log("Prelims Sessions List: ", this.vm.prelimsSessionsList);
                }

                if (value[0].hasOwnProperty('mains') && value[0].mains.hasOwnProperty('tasks')) {
                    this.vm.isMainsSubscribed = true;
                    if (!this.vm.currentTab) this.vm.currentTab = "mains";
                    let taskList = value[0].mains.tasks;
                    let subtaskList = value[0].mains.subtasks;

                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                        taskList[taskI]['subtaskList'].sort(function(a: SubTask, b: SubTask) : number {
                            let dateA = a.reading_end;
                            let dateB = b.reading_end;
                            if (dateA < dateB) return -1;
                            else return 1;
                        });
                    }
                    this.vm.mainsTaskList = taskList;
                    // console.log("Mains Task List: ", this.vm.mainsTaskList);

                    let mainsSessionList = value[2];
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let sessionI = 0; sessionI < mainsSessionList.length; sessionI++) {
                            if (taskList[taskI].id == mainsSessionList[sessionI].task.id) {
                                this.vm.mainsSessionsList.push(mainsSessionList[sessionI]);
                                break;
                            }
                        }
                    }
                    // console.log("Mains Sessions List: ", this.vm.mainsSessionsList);
                }
                
                if (value[0].hasOwnProperty('csat') && value[0].csat.hasOwnProperty('tasks')) {
                    this.vm.isCSATSubscribed = true;
                    if (!this.vm.currentTab) this.vm.currentTab = "csat";
                    let taskList = value[0].csat.tasks;
                    let subtaskList = value[0].csat.subtasks;

                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                        taskList[taskI]['subtaskList'].sort(function(a: SubTask, b: SubTask) : number {
                            let dateA = a.reading_end;
                            let dateB = b.reading_end;
                            if (dateA < dateB) return -1;
                            else return 1;
                        });
                    }
                    this.vm.csatTaskList = taskList;
                    // console.log("CSAT Task List: ", this.vm.csatTaskList);

                    let csatSessionList = value[3];
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let sessionI = 0; sessionI < csatSessionList.length; sessionI++) {
                            if (taskList[taskI].id == csatSessionList[sessionI].task.id) {
                                this.vm.csatSessionsList.push(csatSessionList[sessionI]);
                                break;
                            }
                        }
                    }
                    // console.log("CSAT Sessions List: ", this.vm.csatSessionsList);
                }

                if (!this.vm.currentTab) {
                    this.vm.currentTab = "milestones";
                }
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async doneSubtask(subTask: SubTask, i: number, j: number) {
        let apiString = DJANGO_SERVER + "/api/study_plan/toggle_subtask_status/" + subTask.id;
        const updateStudentSubtask = this.http.put(apiString, {}).toPromise();

        await Promise.all([
            updateStudentSubtask         // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                if (this.vm.currentTab == "prelims") {
                    this.vm.prelimsTaskList[i].subtaskList[j].reading_completed = !subTask.reading_completed;
                    this.vm.prelimsTaskList[i].subtaskList[j].reading_completion_date = value[0].reading_completion_date;
                } else if (this.vm.currentTab == "mains") {
                    this.vm.mainsTaskList[i].subtaskList[j].reading_completed = !subTask.reading_completed;
                    this.vm.mainsTaskList[i].subtaskList[j].reading_completion_date = value[0].reading_completion_date;
                } else if (this.vm.currentTab == "csat") {
                    this.vm.csatTaskList[i].subtaskList[j].reading_completed = !subTask.reading_completed;
                    this.vm.csatTaskList[i].subtaskList[j].reading_completion_date = value[0].reading_completion_date;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
}
