import { HttpClient } from '@angular/common/http';
import { LoginComponent } from './login.component';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class LoginServiceAdapter {
    
    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: LoginComponent): void {
        this.vm = vm;
    }

    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/mentor_or_student_profile/";
        const getMentorOrStudentPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorOrStudentPromise         // 0
        ]).then(
            (value) => {
                // console.log(value);
                this.vm.isLoggedin = true;
                this.vm.isLoading = false;
                this.vm.router.navigate(["/"]);
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedin = false;
                this.vm.isLoading = false;
            }
        );
    }

    async loggedInUser() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/login/";
        let idToken = {
            "auth_token": this.vm.socialUser['idToken']
        };
        
        this.http.post(apiString, idToken)
            .toPromise()
            .then(
                    (response: any) => {
                        // console.log("Response: ", response);
                        localStorage.setItem('mentorshut_user_data_access_token', JSON.stringify(response.access_token));
                        localStorage.setItem('mentorshut_user_data_refresh_token', JSON.stringify(response.refresh_token));
                        localStorage.setItem('mentorshut_user_data_type', JSON.stringify(response.type));
                        // this.vm.dialogRef.close({isLoggedIn: true});
                        // this.vm.router.navigate(['/']);

                        if (JSON.parse(localStorage.getItem('mentorshut_user_data_type') as string) == "Student") {
                            this.vm.router.navigate(['mentee-profile/']);
                        } else {
                            this.vm.router.navigate(['mentor-profile/']);
                        }
                    },
                    (error) => {
                        console.log("Error: ", error);
                    }
                );
        this.vm.isLoading = false;
    }
}
