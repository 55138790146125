import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/login/login.component';
// import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
// import { NavbarServiceAdapter } from './navbar.service.adapter';
import { HttpClient } from '@angular/common/http';
import { DJANGO_SERVER } from 'src/app/environment/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    @Input() isLoggedIn: boolean = false;
    @Input() userImage: string = "";
    examType: string = "UPSC";
    examTypeValueList: string[] = ["UPSC", "GPSC", "MPSC", "HPSC"];

    isLoading: boolean = false;
    pageMode: string = "current";
    mobileNumber: string = "";
    otp: string = "";
    isMenuOpen = false;

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private http: HttpClient,
    ) { }

    ngOnInit(): void {
    }

    navigateToBlog(): void {
        this.router.navigate(['blog/']);
    }

    navigateToUserProfile(): void {
        if (JSON.parse(localStorage.getItem('mentorshut_user_data_type') as string) == "Student") {
            this.router.navigate(['mentee-profile/']);
        } else {
            this.router.navigate(['mentor-profile/']);
        }
    }

    getUserImage(): string {
        if (JSON.parse(localStorage.getItem('mentorshut_user_data_type') as string) == "Student") {
            return this.userImage
        } else {
            return DJANGO_SERVER + this.userImage
        } 
    }

    openDialog(): void {
        // const dialogRef = this.dialog.open(LoginComponent);
        this.router.navigate(['login/']);
    }

    navigateToHome() {
        this.router.navigate(['/']);
    }

    navigateToPricing() {
        this.router.navigate(['/'], { queryParams: { id: "subscription-plans" } });
    }

    navigateToMentors(): void {
        this.router.navigate(['mentors-page']);
    }

    navigateToResources(): void {
        this.router.navigate(['resources']);
    }

    navigateToCourses(): void {
        window.open('https://www.iasmentorshut.com/', '_blank')
    }

    logOutUser() {
        let locationList = window.location.href.split('/');
        localStorage.removeItem("mentorshut_user_data_type");
        localStorage.removeItem("mentorshut_user_data_refresh_token");
        localStorage.removeItem("mentorshut_user_data_access_token");
        
        if (locationList.length < 4 || locationList[3] == '' || locationList[3] == 'upsc') {
            window.location.reload();
        } else {
            this.router.navigate(['/']);
        }
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }
}
