import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenteeListingServiceAdapter } from './mentee-listing.service.adapter';
import { Student } from 'src/app/common-classes/student';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { Task } from 'src/app/common-classes/task';
import { Chat } from 'src/app/common-classes/chat';
import { MainsResponse } from 'src/app/common-classes/mains-response';
import { MatDialog } from '@angular/material/dialog';
import { UploadMainsCopyComponent } from './local-components/upload-mains-copy/upload-mains-copy.component';
import { Topic } from 'src/app/common-classes/topic';
import { Mentor } from 'src/app/common-classes/mentor';
import { UploadReportComponent } from './local-components/upload-report/upload-report.component';
import { WeakTopicSummary } from 'src/app/common-classes/weak-topic-summary';
import { DJANGO_SERVER } from 'src/app/environment/environment';
import { MarketingText } from 'src/app/common-classes/marketing-text';


@Component({
    selector: 'app-mentee-listing',
    templateUrl: './mentee-listing.component.html',
    styleUrls: ['./mentee-listing.component.css']
})
export class MenteeListingComponent {

    isLoading: boolean = false;
    isLoggedIn: boolean = false;
    currentSidebarTab: string = "mentee-listing";
    currentTabMentor: string = "prelims";
    currentTabMentee: string = "";
    pageMode: string = "mentor";
    userId: number = 0;
    roomId: number = 0;
    mentor: Mentor = new Mentor();

    currentStudyPlanTab: string = "plan";
    planTopicId: number = -1;
    planOrder: number = 0;
    planReadingStartDate: string = "";
    planReadingEndDate: string = "";
    planQuestionsStartDate: string = "";
    planQuestionsEndDate: string = "";
    planTopicList: Topic[] = [];
    planTopicDefaultValue: string = "Topic";
    planTopic: string = this.planTopicDefaultValue;
    planTopicValueList: string[] = [];
    planTopicIdList: number[] = [];

    mentee: Student = new Student();
    menteeSessionList: MentorshipSession[] = [];
    menteeChatSessionList: MentorshipSession[] = [];
    menteeReadingSessionList: MentorshipSession[] = [];
    menteeTaskList: Task[] = [];
    menteeChatList: Chat[] = [];
    menteeMainsCopyList: MainsResponse[] = [];
    chatMessage: string = "";
    menteeTopicProgressList: any = [];
    menteeTopicDailyTargetList: any = [];
    menteeTopicDailyAccuracyList: any = [];
    menteeWeakTopicSummaryDetails: WeakTopicSummary[] = [];

    currentSessionTab: string = "sessions";
    sessionTitle: string = "";
    sessionDate: string = "";
    sessionTime: string = "";
    sessionId: number = 0;
    sessionIdx: number = 0;
    sessionType: string = "";
    selectedSessionMode: string = "Phone Call";
    sessionModeList: string[] = ['Google Meet', 'Phone Call'];

    prelimsMenteeList: Student[] = [];
    prelimsMenteeSessionList: [] = [];

    mainsMenteeList: Student[] = [];
    mainsMenteeSessionList: [] = [];

    csatMenteeList: Student[] = [];
    csatMenteeSessionList: [] = []; 

    option: any;
    colors: string[] = ['#FF9D0A', '#FF0AB3'];
    marketingAddList: MarketingText[] = [];

    socket: any;
    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new MenteeListingServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    checkEve(event: any) {
        event.preventDefault();
    }

    changeTabMentor(tabName: string) {
        this.currentTabMentor = tabName;
    }

    changeTabMentee(tabName: string) {
        this.currentTabMentee = tabName;
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    openMentee(mentee: Student, menteeIndex: number): void {
        this.pageMode = "mentee";
        this.currentTabMentee = "session";
        this.serviceAdapter.getMenteeData(mentee.id);
        this.mentee = mentee;
    }

    closeMentee(): void {
        this.pageMode = "mentor";
    }

    sendMessage() {
        if (this.chatMessage) {
            this.serviceAdapter.send(this.chatMessage);
            // this.menteeChatList.push({
            //     content: this.chatMessage,
            //     id: 0,
            //     sender: this.userId,
            //     timestamp: (new Date()).toString(),
            //     room: this.roomId
            // });
            this.chatMessage = "";
        }
    }

    getImageLink(image_link: string): string {
        return DJANGO_SERVER + image_link
    }

    downloadAnswer(copy: MainsResponse): void {
        if (copy.response) {
            window.open(copy.response, '_blank');
        } else if (copy.response_file) {
            window.open(DJANGO_SERVER + copy.response_file, '_blank');
        }
    }

    uploadAnswerDialog(copyIndex: number): void {
        const dialogRef = this.dialog.open(UploadMainsCopyComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.hasOwnProperty("file")) {
                this.serviceAdapter.uploadEvaluatedCopy(result.file, result.score, this.menteeMainsCopyList[copyIndex].id, copyIndex);
            }
        });
    }

    addSession(): void {
        this.sessionTitle = this.sessionTitle.trim();
        if (!this.sessionTitle) {
            alert("Please enter the title");
            return;
        }
        if (!this.sessionDate) {
            alert("Please select the date");
            return;
        }
        if (!this.sessionTime) {
            alert("Please select the time");
            return;
        }
        this.serviceAdapter.addSession();
    }

    editSession(sessionIdx: number, sessionType: string): void {
        if (sessionType == 'review') {
            this.sessionId = this.menteeSessionList[sessionIdx].id;
            this.sessionDate = this.menteeSessionList[sessionIdx].date;
            this.sessionTime = this.menteeSessionList[sessionIdx].time;
        } else if (sessionType == 'chat') {
            this.sessionId = this.menteeChatSessionList[sessionIdx].id;
            this.sessionDate = this.menteeChatSessionList[sessionIdx].date;
            this.sessionTime = this.menteeChatSessionList[sessionIdx].time;
        }
        this.sessionIdx = sessionIdx;
        this.sessionType = sessionType;
        this.currentSessionTab = 'update-session';
    }

    uploadReport(sessionIdx: number, sessionType: string) {
        const dialogRef = this.dialog.open(UploadReportComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.hasOwnProperty("file")) {
                console.log("result: ", result);
                if (sessionType == 'review') {
                    this.serviceAdapter.uploadSessionReport(result.file, this.menteeSessionList[sessionIdx].id, sessionIdx, sessionType);
                } else if (sessionType == 'chat') {
                    this.serviceAdapter.uploadSessionReport(result.file, this.menteeChatSessionList[sessionIdx].id, sessionIdx, sessionType);
                }
            }
        });
    }

    updateSession(): void {
        if (!this.sessionDate) {
            alert("Please select the date");
            return;
        }
        if (!this.sessionTime) {
            alert("Please select the time");
            return;
        }
        this.serviceAdapter.updateSession();
    }

    closeEditSession() : void {
        this.sessionId = 0;
        this.sessionIdx = 0;
        this.sessionTitle = "";
        this.sessionDate = "";
        this.sessionTime = "";
        this.currentSessionTab = "sessions";
    }

    addPlanButtonClicked(): void {
        let subscriptionId = 1;
        if (this.currentTabMentor == "mains") subscriptionId = 2;
        if (this.currentTabMentor == "csat") subscriptionId = 3;

        let topicValueList = [];
        let topicIdList = [];
        for (let topicI = 0; topicI < this.planTopicList.length; topicI++) {
            if (this.planTopicList[topicI].subscription_plan.plan_id == subscriptionId) {
                topicValueList.push(this.planTopicList[topicI].name);
                topicIdList.push(this.planTopicList[topicI].id);
            }
        }
        this.planTopicIdList = topicIdList;
        this.planTopicValueList = topicValueList;

        // console.log("Value List: ", this.planTopicValueList);
        // console.log("Value List: ", topicIdList);
        this.currentStudyPlanTab = 'add-plan';
    }

    addPlan(): void {
        if (this.planTopicId <= 0) {
            alert("Please select the topic.");
            return;
        }
        if (this.planOrder <= 0) {
            alert("Please select the order.");
            return;
        }
        if (!this.planReadingStartDate) {
            alert("Please select the Reading Start Date.");
            return;
        }
        if (!this.planReadingEndDate) {
            alert("Please select the Reading End Date.");
            return;
        }
        if (!this.planQuestionsStartDate) {
            alert("Please select the Question Start Date.");
            return;
        }
        if (!this.planQuestionsEndDate) {
            alert("Please select the Question End Date.");
            return;
        }
        this.serviceAdapter.addPlan();
    }

    closeAddPlan(): void {
        this.planTopicId = 0;
        this.planOrder = -1;
        this.planReadingStartDate = "";
        this.planReadingEndDate = "";
        this.planQuestionsStartDate = "";
        this.planQuestionsEndDate = "";
        this.currentStudyPlanTab = "plan";
    }

    getLinearOption(taskIndex: number): any {
        let dayList: string[] = [];
        let targetList: number[] = [];
        let accuracyList: number[] = [];

        for (let date in this.menteeTopicDailyTargetList[taskIndex].timeline) {
            dayList.push(date);
        }

        for (let date in this.menteeTopicDailyTargetList[taskIndex].timeline) {
            targetList.push(this.menteeTopicDailyTargetList[taskIndex].timeline[date]);
        }

        for (let date in this.menteeTopicDailyAccuracyList[taskIndex].timeline) {
            accuracyList.push(this.menteeTopicDailyAccuracyList[taskIndex].timeline[date]);
        }

        this.option = {
            color: this.colors,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            grid: {
                right: '0%'
            },
            toolbox: {
                feature: {
                    dataView: { show: true, readOnly: false },
                    restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            legend: {
                data: ['Targets', 'Accuracy']
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true
                    },
                    // prettier-ignore
                    data: dayList
                    // data: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10', 'Day 11', 'Day 12']
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'Accuracy %',
                    position: 'right',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: this.colors[1]
                        }
                    },
                    axisLabel: {
                        formatter: '{value}'
                    }
                },
                {
                    type: 'value',
                    name: 'Targets',
                    position: 'left',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: this.colors[0]
                        }
                    },
                    axisLabel: {
                        formatter: '{value}'
                    }
                }
            ],
            series: [
                {
                    name: 'Targets',
                    type: 'line',
                    data: targetList
                    // data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
                },
                {
                    name: 'Accuracy',
                    type: 'line',
                    yAxisIndex: 1,
                    data: accuracyList
                    // data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
                },
                {
                    name: 'Access From',
                    type: 'line',
                    width: '50%',
                }
            ]
        };
        return this.option;
    }

    getPieOption(taskIndex: number): any {
        let less40Count = this.menteeWeakTopicSummaryDetails[taskIndex].less_than_40_percent_count;
        let between4060Count = this.menteeWeakTopicSummaryDetails[taskIndex].greater_than_equal_to_40_less_than_60_count;
        let more60Count = this.menteeWeakTopicSummaryDetails[taskIndex].greater_than_60_percent_count;
        let totalCount = less40Count + between4060Count + more60Count;

        this.option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: true,
                        formatter(param: any) {
                            // correct the percentage
                            return param.name + ' (' + param.percent + '%)';
                        },
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 15,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: (less40Count / totalCount) * 100, name: 'Accuracy < 40%' },
                        { value: (between4060Count / totalCount) * 100, name: '40% < Accuracy < 60%' },
                        { value: (more60Count / totalCount) * 100, name: 'Accuracy > 60%' },
                    ],
                    color: ['#7E3785', '#B44F96', '#E86CA4']
                }
            ]
        };
        return this.option;
    }
}
