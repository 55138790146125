<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length">
    <div class="study-plan-base-class">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="study-plan-section">
                <ng-container *ngIf="currentTab != 'topicsListing'">
                    <div class="study-plan-details">
                        <div class="study-plan-details-title">PERSONALISED STUDY PLAN: Milestones & Micro-targets</div>
                        <div class="tab-group">
                            <ng-container *ngIf="isPrelimsSubscribed">
                                <button type="button" class="study-plan-details-tab-button" [class.active-tab]="currentTab == 'prelims'" (click)="changeTab('prelims')">Prelims</button>
                            </ng-container>
                            <ng-container *ngIf="isMainsSubscribed">
                                <button type="button" class="study-plan-details-tab-button" [class.active-tab]="currentTab == 'mains'" (click)="changeTab('mains')">Mains</button>
                            </ng-container>
                            <ng-container *ngIf="isCSATSubscribed">
                                <button type="button" class="study-plan-details-tab-button" [class.active-tab]="currentTab == 'csat'" (click)="changeTab('csat')">CSAT</button>
                            </ng-container>

                            <ng-container *ngIf="!isPrelimsSubscribed">
                                <button type="button" class="study-plan-details-tab-button" [class.active-tab]="currentTab == 'prelims'" (click)="changeTab('prelims')">Prelims</button>
                            </ng-container>
                            <ng-container *ngIf="!isMainsSubscribed">
                                <button type="button" class="study-plan-details-tab-button" [class.active-tab]="currentTab == 'mains'" (click)="changeTab('mains')">Mains</button>
                            </ng-container>
                            <ng-container *ngIf="!isCSATSubscribed">
                                <button type="button" class="study-plan-details-tab-button" [class.active-tab]="currentTab == 'csat'" (click)="changeTab('csat')">CSAT</button>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'prelims'">
                    <ng-container *ngIf="isPrelimsSubscribed">
                        <ng-container *ngFor="let task of prelimsTaskList, let i = index">
                            <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                <div class="milestone-date">Upto {{ task.reading_end | date:'d MMM, y' }}</div>
                            </div>
                            <ng-container *ngIf="task.isOpen">
                                <div class="microtargets">
                                    <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                        <div class="microtarget">
                                            <div class="microtarget-details">
                                                <div class="microtarget-serial-number" style="cursor: pointer;" (click)="openTopicLIsting(subtask, j, 'Prelims')">MT - {{ j + 1 }}</div>
                                                <div class="microtarget-title">{{ subtask.subtopic.name }}</div>
                                            </div>
                                            <div class="microtarget-dates">
                                                <div class="microtarget-done-button" [class.done-true]="subtask.reading_completed" (click)="doneSubtask(subtask, i, j)">Mark Done</div>
                                                <div class="microtarget-date">{{ subtask.reading_end | date:'d MMM, y' }}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
    
                            <div class="milestone-meet">
                                <div class="milestone-meet-title">{{ prelimsSessionsList[i].title }} - {{ i + 1 }}</div>
                                <div class="milestone-meet-date">{{ prelimsSessionsList[i].date | date:'d MMM, y' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!isPrelimsSubscribed">
                        <div class="locked-screen">
                            <div class="background-image">
                                <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                            </div>
                            <div class="lock">
                                <div class="material-symbols-outlined lock-icon">lock</div>
                                <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Prelims Subscription</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'mains'">
                    <ng-container *ngIf="isMainsSubscribed">
                        <ng-container *ngFor="let task of mainsTaskList, let i = index">
                            <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                <div class="milestone-date">Upto {{ task.reading_end | date:'d MMM, y' }}</div>
                            </div>
                            <ng-container *ngIf="task.isOpen">
                                <div class="microtargets">
                                    <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                        <div class="microtarget">
                                            <div class="microtarget-details">
                                                <div class="microtarget-serial-number" style="cursor: pointer;" (click)="openTopicLIsting(subtask, j, 'Mains')">MT - {{ j + 1 }}</div>
                                                <div class="microtarget-title">{{ subtask.subtopic.name }}</div>
                                            </div>
                                            <div class="microtarget-dates">
                                                <div class="microtarget-done-button" [class.done-true]="subtask.reading_completed" (click)="doneSubtask(subtask, i, j)">Mark Done</div>
                                                <div class="microtarget-date">{{ subtask.reading_end | date:'d MMM, y' }}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
    
                            <div class="milestone-meet">
                                <div class="milestone-meet-title">{{ mainsSessionsList[i].title }} - {{ i + 1 }}</div>
                                <div class="milestone-meet-date">{{ mainsSessionsList[i].date | date:'d MMM, y' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!isMainsSubscribed">
                        <div class="locked-screen">
                            <div class="background-image">
                                <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                            </div>
                            <div class="lock">
                                <div class="material-symbols-outlined lock-icon">lock</div>
                                <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Mains Subscription</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'csat'">
                    <ng-container *ngIf="isCSATSubscribed">
                        <ng-container *ngFor="let task of csatTaskList, let i = index">
                            <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                <div class="milestone-date">Upto {{ task.reading_end | date:'d MMM, y' }}</div>
                            </div>
                            <ng-container *ngIf="task.isOpen">
                                <div class="microtargets">
                                    <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                        <div class="microtarget">
                                            <div class="microtarget-details">
                                                <div class="microtarget-serial-number" style="cursor: pointer;" (click)="openTopicLIsting(subtask, j, 'CSAT')">MT - {{ j + 1 }}</div>
                                                <div class="microtarget-title">{{ subtask.subtopic.name }}</div>
                                            </div>
                                            <div class="microtarget-dates">
                                                <div class="microtarget-done-button" [class.done-true]="subtask.reading_completed" (click)="doneSubtask(subtask, i, j)">Mark Done</div>
                                                <div class="microtarget-date">{{ subtask.reading_end | date:'d MMM, y' }}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
    
                            <div class="milestone-meet">
                                <div class="milestone-meet-title">{{ csatSessionsList[i].title }} - {{ i + 1 }}</div>
                                <div class="milestone-meet-date">{{ csatSessionsList[i].date | date:'d MMM, y' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!isCSATSubscribed">
                        <div class="locked-screen">
                            <div class="background-image">
                                <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                            </div>
                            <div class="lock">
                                <div class="material-symbols-outlined lock-icon">lock</div>
                                <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your CSAT Subscription</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'topicsListing'">
                    <div class="study-plan-details">
                        <div class="study-plan-details-title">PERSONALISED STUDY PLAN</div>
                        <div class="d-flex align-items-center mt-3">
                            <span class="material-symbols-outlined fw-bold" style="cursor: pointer;" (click)="backToStudyPlan()">
                                keyboard_backspace
                            </span>
                            <button type="button" class="mx-3 study-plan-details-tab-button active-tab" style="margin-top: 0; margin-right: 0;" (click)="changeTab('prelims')">{{ previousSubscriptionType }} - Topic Listing & Sources</button>
                        </div>
                    </div>

                    <div class="topic-listing-microtarget">
                        <div class="topic-listing-microtarget-title">
                            MT {{ openedSubtaskIdx }} - {{ openedSubtask.subtopic.name }}
                        </div>
                    </div>
                    <div class="topic-listing-source">
                        <div class="topic-listing">
                            <div class="topic-listing-title">
                                <div class="">Topic Listing</div>
                            </div><br/>
                            <!-- <ol>
                                <ng-container *ngFor="let task of prelimsTaskList, let i = index">
                                    <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                        <li> {{ subtask.subtopic.name }} </li>
                                    </ng-container>
                                </ng-container>
                            </ol>  -->
                            <div [innerHTML]="openedSubtask.subtopic.topic_listing"></div>
                        </div>
                        <div class="source">
                            <div class="source-title">
                                <div class="">Sources</div>
                            </div><br/>
                            <!-- <ol>
                                <ng-container *ngFor="let task of prelimsTaskList, let i = index">
                                    <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                        <li> {{ subtask.subtopic.source }} </li>
                                    </ng-container>
                                </ng-container>
                            </ol> -->
                            <div [innerHTML]="openedSubtask.subtopic.sources"></div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && !subscriptionPlanList.length">
    <div class="study-plan-base-class">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="study-plan-section">
                <div class="study-plan-details">
                    <div class="study-plan-details-title">PERSONALISED STUDY PLAN</div>
                    <div class="tab-group">
                        <button type="button" class="study-plan-details-tab-button" [class.active-tab]="currentTab == 'milestones'" (click)="changeTab('milestones')">Milestone & Micro Target</button>
                        <button type="button" class="study-plan-details-tab-button" [class.active-tab]="currentTab == 'topicsListing'" (click)="changeTab('topicsListing')">Topic Listing & Sources</button>
                    </div>
                </div>

                <div class="locked-screen">
                    <div class="background-image">
                        <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                    </div>
                    <div class="lock">
                        <div class="material-symbols-outlined lock-icon">lock</div>
                        <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Personalised Study Plan</button>
                    </div>
                </div>

                <!-- <ng-container *ngIf="currentTab == 'milestones'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Personalised Study Plan</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentTab == 'topicsListing'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Personalised Study Plan</button>
                        </div>
                    </div>
                </ng-container> -->
            </div>
        </div>
    </div>
</ng-container>