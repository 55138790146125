import { Component, OnInit } from '@angular/core';
import { MentorsPageServiceAdapter } from './mentors-page.service.adapter';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Mentor } from '../common-classes/mentor';
import { MarketingText } from '../common-classes/marketing-text';
import { DJANGO_SERVER } from '../environment/environment';

@Component({
    selector: 'app-mentors-page',
    templateUrl: './mentors-page.component.html',
    styleUrls: ['./mentors-page.component.css']
})
export class MentorsPageComponent implements OnInit {

    isLoading: boolean = false;
    isLoggedIn: boolean = false;
    user: any;

    mentorList: Mentor[] = [];
    marketingAddList: MarketingText[] = [];

    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new MentorsPageServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }
    
    getImageLink(image_link: string): string {
        return DJANGO_SERVER + image_link
    }
}
