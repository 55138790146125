<div class="footer">
    <div class="footer-details">
        <div class="logo">
            <img src="../../assets/Images/combined_logo_transparent.svg" alt="MentorsHut" (click)="navigateToHome()" style="cursor: pointer">
        </div>
        <div class="phone-number-details footer-content-title">
            <div class="material-symbols-outlined call-icon">call</div>
            <div class="footer-content-title">9821373633</div>
        </div>
    </div>

    <div class="footer-details mt-4 mt-sm-0">
        <div class="footer-content-title">
            Follow us on
        </div>
        <div class="follow-icon mt-2 mb-4">
            <i (click)="navigateToTelegram()" class="fa fa-telegram mx-3" style="color:#0088cc"></i>
            <i (click)="navigateToYouTube()" class="fa fa-youtube-play" style="color:red"></i>
        </div>
        <div class="policy-title">
            <span (click)="navigateToPrivacyPolicy()">Privacy Policy</span> | <span (click)="navigateToRefundPolicy()">Refund Policy</span> | <span (click)="navigateToTermsOfUse()">Terms of Use</span>
        </div>
        <div class="policy-title">
            <i class="fa fa-copyright"></i> 2023 MentQuest Private Ltd.
        </div>
    </div>

    <div class="footer-details mt-4 mt-sm-0">
        <div class="footer-content-title link" (click)="navigateToPricing()">
            Subscription Plan
        </div>
        <div class="footer-content-title link my-sm-3" (click)="navigateToBlogs()">
            Blogs
        </div>
        <div class="footer-content-title link" (click)="navigateToResources()">
            Resources
        </div>
    </div>
</div>