<ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length && pageMode == 'topic'">
    <div class="question-bank-base-class">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="question-bank-section">
                <div class="question-bank-details">
                    <div class="question-bank-details-title">QUESTION BANK</div>
                    <div class="tab-group">
                        <ng-container *ngIf="isPrelimsSubscribed">
                            <button type="button" class="question-bank-details-tab-button" [class.active-tab]="currentTab == 'prelims'" (click)="changeTab('prelims')">Prelims</button>
                        </ng-container>
                        <ng-container *ngIf="isMainsSubscribed">
                            <button type="button" class="question-bank-details-tab-button" [class.active-tab]="currentTab == 'mains'" (click)="changeTab('mains')">Mains</button>
                        </ng-container>
                        <ng-container *ngIf="isCSATSubscribed">
                            <button type="button" class="question-bank-details-tab-button" [class.active-tab]="currentTab == 'csat'" (click)="changeTab('csat')">CSAT</button>
                        </ng-container>

                        <ng-container *ngIf="!isPrelimsSubscribed">
                            <button type="button" class="question-bank-details-tab-button" [class.active-tab]="currentTab == 'prelims'" (click)="changeTab('prelims')">Prelims</button>
                        </ng-container>
                        <ng-container *ngIf="!isMainsSubscribed">
                            <button type="button" class="question-bank-details-tab-button" [class.active-tab]="currentTab == 'mains'" (click)="changeTab('mains')">Mains</button>
                        </ng-container>
                        <ng-container *ngIf="!isCSATSubscribed">
                            <button type="button" class="question-bank-details-tab-button" [class.active-tab]="currentTab == 'csat'" (click)="changeTab('csat')">CSAT</button>
                        </ng-container>
                    </div>
                </div>

                <ng-container *ngIf="currentTab == 'prelims'">
                    <ng-container *ngIf="isPrelimsSubscribed">
                        <ng-container *ngFor="let task of prelimsTaskList, let i = index">
                            <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                <div class="milestone-date">
                                    Upto {{ task.questions_end | date:'d MMM, y' }}
                                </div>
                            </div>
                            <ng-container *ngIf="task.isOpen">
                                <div class="microtargets">
                                    <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                        <div class="microtarget">
                                            <div class="microtarget-details">
                                                <div class="microtarget-serial-number">MT - {{ j + 1 }}</div>
                                                <div class="microtarget-title">{{ subtask.subtopic.name }}</div>
                                            </div>
    
                                            <ng-container *ngIf="!subtask.questions_completed">
                                                <div class="microtarget-practice">
                                                    <ng-container *ngIf="validDateOfSubtask(subtask); else validDateOfSubtaskElse">
                                                        <button type="button" class="microtarget-practice-button" (click)="practiceQuestions(subtask.subtopic.id, subtask.subtopic.name, j, i)">Practice</button>
                                                    </ng-container>
                                                    <ng-template #validDateOfSubtaskElse>
                                                        <button type="button" class="microtarget-practice-button">
                                                            {{ subtask.questions_end | date:'d MMM, y' }}
                                                        </button>
                                                        <div class="lock-icon material-symbols-outlined">lock</div>
                                                    </ng-template>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="subtask.questions_completed">
                                                <div class="microtarget-practice">
                                                    <button type="button" class="microtarget-practice-button" (click)="solutionQuestions(subtask.subtopic.id, subtask.subtopic.name, j, i)">Solution</button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
    
                            <div class="milestone-meet">
                                <div class="milestone-meet-title">{{ prelimsQuestionsSessionsList[i].title }} - {{ i + 1 }}</div>
                                <div class="milestone-meet-date">{{ prelimsQuestionsSessionsList[i].date | date:'d MMM, y' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!isPrelimsSubscribed">
                        <div class="locked-screen">
                            <div class="background-image">
                                <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                            </div>
                            <div class="lock">
                                <div class="material-symbols-outlined lock-icon2">lock</div>
                                <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Prelims Subscription</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'mains'">
                    <ng-container *ngIf="isMainsSubscribed">
                        <ng-container *ngFor="let task of mainsTaskList, let i = index">
                        <div class="milestone" (click)="task.isOpen = !task.isOpen">
                            <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                            <div class="milestone-date">
                                Upto {{ task.questions_end | date:'d MMM, y' }}
                            </div>
                        </div>
                        <ng-container *ngIf="task.isOpen">
                            <div class="microtargets">
                                <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                    <div class="microtarget">
                                        <div class="microtarget-details">
                                            <div class="microtarget-serial-number">MT - {{ j + 1 }}</div>
                                            <div class="microtarget-title">{{ subtask.subtopic.name }}</div>
                                        </div>

                                        <div class="microtarget-practice">
                                            <ng-container *ngIf="validDateOfSubtask(subtask); else validDateOfSubtaskElse">
                                                <button type="button" class="microtarget-practice-button" (click)="practiceQuestions(subtask.subtopic.id, subtask.subtopic.name, j, i)">Practice</button>
                                            </ng-container>
                                            <ng-template #validDateOfSubtaskElse>
                                                <button type="button" class="microtarget-practice-button">
                                                    {{ subtask.questions_end | date:'d MMM, y' }}
                                                </button>
                                                <div class="lock-icon material-symbols-outlined">lock</div>
                                            </ng-template>
                                        </div>

                                        <!-- <ng-container *ngIf="!subtask.questions_completed">
                                            <div class="microtarget-practice">
                                                <ng-container *ngIf="validDateOfSubtask(subtask); else validDateOfSubtaskElse">
                                                    <button type="button" class="microtarget-practice-button" (click)="practiceQuestions(subtask.subtopic.id, subtask.subtopic.name, j, i)">Practice</button>
                                                </ng-container>
                                                <ng-template #validDateOfSubtaskElse>
                                                    <button type="button" class="microtarget-practice-button">
                                                        {{ subtask.questions_end | date:'d MMM, y' }}
                                                    </button>
                                                    <div class="lock-icon material-symbols-outlined">lock</div>
                                                </ng-template>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="subtask.questions_completed">
                                            <div class="microtarget-practice">
                                                <button type="button" class="microtarget-practice-button" (click)="solutionQuestions(subtask.subtopic.id, subtask.subtopic.name, j, i)">Solution</button>
                                            </div>
                                        </ng-container> -->
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <div class="milestone-meet">
                            <div class="milestone-meet-title">{{ mainsQuestionsSessionsList[i].title }} - {{ i + 1 }}</div>
                            <div class="milestone-meet-date">{{ mainsQuestionsSessionsList[i].date | date:'d MMM, y' }}</div>
                        </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!isMainsSubscribed">
                        <div class="locked-screen">
                            <div class="background-image">
                                <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                            </div>
                            <div class="lock">
                                <div class="material-symbols-outlined lock-icon2">lock</div>
                                <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your Mains Subscription</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentTab == 'csat'">
                    <ng-container *ngIf="isCSATSubscribed">
                        <ng-container *ngFor="let task of csatTaskList, let i = index">
                            <div class="milestone" (click)="task.isOpen = !task.isOpen">
                                <div class="milestone-title">Milestone {{ i + 1 }} - {{ task.topic.name }}</div>
                                <div class="milestone-date">
                                    Upto {{ task.questions_end | date:'d MMM, y' }}
                                </div>
                            </div>
                            <ng-container *ngIf="task.isOpen">
                                <div class="microtargets">
                                    <ng-container *ngFor="let subtask of task.subtaskList, let j = index">
                                        <div class="microtarget">
                                            <div class="microtarget-details">
                                                <div class="microtarget-serial-number">MT - {{ j + 1 }}</div>
                                                <div class="microtarget-title">{{ subtask.subtopic.name }}</div>
                                            </div>

                                            <ng-container *ngIf="!subtask.questions_completed">
                                                <div class="microtarget-practice">
                                                    <ng-container *ngIf="validDateOfSubtask(subtask); else validDateOfSubtaskElse">
                                                        <button type="button" class="microtarget-practice-button" (click)="practiceQuestions(subtask.subtopic.id, subtask.subtopic.name, j, i)">Practice</button>
                                                    </ng-container>
                                                    <ng-template #validDateOfSubtaskElse>
                                                        <button type="button" class="microtarget-practice-button">
                                                            {{ subtask.questions_end | date:'d MMM, y' }}
                                                        </button>
                                                        <div class="lock-icon material-symbols-outlined">lock</div>
                                                    </ng-template>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="subtask.questions_completed">
                                                <div class="microtarget-practice">
                                                    <button type="button" class="microtarget-practice-button" (click)="solutionQuestions(subtask.subtopic.id, subtask.subtopic.name, j, i)">Solution</button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>

                            <div class="milestone-meet">
                                <div class="milestone-meet-title">{{ csatQuestionsSessionsList[i].title }} - {{ i + 1 }}</div>
                                <div class="milestone-meet-date">{{ csatQuestionsSessionsList[i].date | date:'d MMM, y' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!isCSATSubscribed">
                        <div class="locked-screen">
                            <div class="background-image">
                                <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                            </div>
                            <div class="lock">
                                <div class="material-symbols-outlined lock-icon2">lock</div>
                                <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Get Your CSAT Subscription</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length && pageMode == 'prelims-exam'">
    <div class="exam-section">
        <div class="exam-details">
            <div class="exam-title">MT - {{ prelimsMicroTargetIdx }} / {{ prelimsSubtopicName }}</div>
            <div class="exam-timer">
                <ng-container *ngIf="!prelimsSolutionOpened">
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ hours }}</div>
                        <div class="exam-timer-text">Hour</div>
                    </div>
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ minutes }}</div>
                        <div class="exam-timer-text">Minute</div>
                    </div>
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ seconds }}</div>
                        <div class="exam-timer-text">Second</div>
                    </div>
                </ng-container>

                <ng-container *ngIf="prelimsSolutionOpened">
                    <div class="d-none d-md-block">Solution</div>
                </ng-container>
            </div>
        </div>

        <div class="question-section">
            <div class="d-md-none mb-4 question-section-top-mobile-section">
                <button type="button" class="question-button submit-button" (click)="toggleBottomSheet()">
                    <div>1 to {{prelimsQuestionsList.length}}</div>
                    <div class="material-symbols-outlined">arrow_drop_down</div>
                </button>
                <div>
                    <ng-container *ngIf="!prelimsSolutionOpened">
                        <button type="button" class="question-button submit-button" (click)="submitPrelimsTest()">Submit</button>
                    </ng-container>
                    <ng-container *ngIf="prelimsSolutionOpened">
                        <button type="button" class="question-button submit-button" (click)="fetchPrelimsSummary()">Summary Report</button>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="prelimsSolutionOpened">
                <div class="d-md-none mb-4 question-section-top-mobile-section">
                    <div class="d-flex">
                        <button type="button" class="question-button quetion-list-right-button" (click)="askYourDoubt()">Ask your Doubts</button>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="button" class="question-button quetion-list-right-button" (click)="backToQuestionBank()">Back to Question bank</button>
                    </div>
                </div>
            </ng-container>

            <div class="question">
                <div class="question-number">Ques {{ currentPrelimsQuestion + 1 }} of {{ prelimsQuestionsList.length }}</div><br/>
                <div class="question-text" [innerHTML]="prelimsQuestionsList[currentPrelimsQuestion].description"></div><br/>

                <ng-container *ngIf="!prelimsSolutionOpened">
                    <div 
                        class="question-option" 
                        (click)="prelimsQuestionsList[currentPrelimsQuestion].selectedOption = prelimsQuestionsList[currentPrelimsQuestion].op1"
                        [class.selected-option]="prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op1"
                    >
                        <div class="question-option-index">A</div>
                        <div class="question-option-value">{{ prelimsQuestionsList[currentPrelimsQuestion].op1 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        (click)="prelimsQuestionsList[currentPrelimsQuestion].selectedOption = prelimsQuestionsList[currentPrelimsQuestion].op2"
                        [class.selected-option]="prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op2"
                    >
                        <div class="question-option-index">B</div>
                        <div class="question-option-value">{{ prelimsQuestionsList[currentPrelimsQuestion].op2 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        (click)="prelimsQuestionsList[currentPrelimsQuestion].selectedOption = prelimsQuestionsList[currentPrelimsQuestion].op3"
                        [class.selected-option]="prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op3"
                    >
                        <div class="question-option-index">C</div>
                        <div class="question-option-value">{{ prelimsQuestionsList[currentPrelimsQuestion].op3 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        (click)="prelimsQuestionsList[currentPrelimsQuestion].selectedOption = prelimsQuestionsList[currentPrelimsQuestion].op4"
                        [class.selected-option]="prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op4"
                    >
                        <div class="question-option-index">D</div>
                        <div class="question-option-value">{{ prelimsQuestionsList[currentPrelimsQuestion].op4 }}</div>
                    </div>
                </ng-container>

                <ng-container *ngIf="prelimsSolutionOpened">
                    <div 
                        class="question-option" 
                        [class.answered-correct-border]="(prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op1) || 
                                                        (!prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].ans == prelimsQuestionsList[currentPrelimsQuestion].op1)"
                        [class.answered-wrong-border]="!prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op1"
                    >
                        <div class="question-option-index">A</div>
                        <div class="question-option-value">{{ prelimsQuestionsList[currentPrelimsQuestion].op1 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        [class.answered-correct-border]="(prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op2) || 
                                                        (!prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].ans == prelimsQuestionsList[currentPrelimsQuestion].op2)"
                        [class.answered-wrong-border]="!prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op2"
                    >
                        <div class="question-option-index">B</div>
                        <div class="question-option-value">{{ prelimsQuestionsList[currentPrelimsQuestion].op2 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        [class.answered-correct-border]="(prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op3) || 
                                                        (!prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].ans == prelimsQuestionsList[currentPrelimsQuestion].op3)"
                        [class.answered-wrong-border]="!prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op3"
                    >
                        <div class="question-option-index">C</div>
                        <div class="question-option-value">{{ prelimsQuestionsList[currentPrelimsQuestion].op3 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        [class.answered-correct-border]="(prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op4) || 
                                                        (!prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].ans == prelimsQuestionsList[currentPrelimsQuestion].op4)"
                        [class.answered-wrong-border]="!prelimsQuestionsList[currentPrelimsQuestion].is_correct && prelimsQuestionsList[currentPrelimsQuestion].selectedOption == prelimsQuestionsList[currentPrelimsQuestion].op4"
                    >
                        <div class="question-option-index">D</div>
                        <div class="question-option-value">{{ prelimsQuestionsList[currentPrelimsQuestion].op4 }}</div>
                    </div>

                    <div class="">Solution:</div>
                    <div class="question-text" [innerHTML]="prelimsQuestionsList[currentPrelimsQuestion].solution"></div><br/>
                </ng-container>

                <div class="question-buttons">
                    <ng-container *ngIf="currentPrelimsQuestion > 0">
                        <button type="button" class="question-button" (click)="currentPrelimsQuestion = currentPrelimsQuestion - 1">
                            <div class="question-button-first-tag material-symbols-outlined">west</div>
                            <div class="question-button-name">Previous</div>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="currentPrelimsQuestion <= 0">
                        <div class=""></div>
                    </ng-container>

                    <ng-container *ngIf="currentPrelimsQuestion < (prelimsQuestionsList.length - 1)">
                        <button type="button" class="question-button" (click)="currentPrelimsQuestion = currentPrelimsQuestion + 1">
                            <div class="question-button-first-tag question-button-name">Next</div>
                            <div class="material-symbols-outlined">east</div>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="currentPrelimsQuestion >= (prelimsQuestionsList.length - 1)">
                        <div class=""></div>
                    </ng-container>
                </div>
            </div>
            <div class="question-list-right bottom-sheet-content d-md-block" *ngIf="isBottomSheetOpen">
                <div class="question-list">
                    <div class="question-list-title">
                        Questions
                    </div><br/>

                    <div class="questions-group">
                        <ng-container *ngFor="let prelimsQuestion of prelimsQuestionsList; let i = index">
                            <div 
                                [class.answered-question]="prelimsQuestion.selectedOption" 
                                [class.answered-correct]="prelimsQuestion.is_answered && prelimsQuestion.is_correct" 
                                [class.answered-wrong]="prelimsQuestion.is_answered && !prelimsQuestion.is_correct" 
                                class="questions-group-question" 
                                (click)="openPrelimsQuestion(i)"
                            >
                                <ng-container *ngIf="i < 9">0{{i + 1}}</ng-container>
                                <ng-container *ngIf="i >= 9">{{i + 1}}</ng-container>
                            </div>
                        </ng-container>
                    </div><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/>

                    <div class="d-none d-md-flex justify-content-center">
                        <ng-container *ngIf="!prelimsSolutionOpened">
                            <button type="button" class="question-button submit-button" (click)="submitPrelimsTest()">Submit</button>
                        </ng-container>
                        <ng-container *ngIf="prelimsSolutionOpened">
                            <button type="button" class="question-button submit-button" (click)="fetchPrelimsSummary()">Summary Report</button>
                        </ng-container>
                    </div><br/>
                </div><br/><br/>

                <ng-container *ngIf="prelimsSolutionOpened">
                    <div class="d-none d-md-flex justify-content-center">
                        <button type="button" class="question-button quetion-list-right-button" (click)="askYourDoubt()">Ask your Doubts</button>
                    </div><br/><br/>
                    <div class="d-none d-md-flex justify-content-center">
                        <button type="button" class="question-button quetion-list-right-button" (click)="backToQuestionBank()">Back to Question bank</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length && pageMode == 'mains-exam'">
    <div class="exam-section">
        <div class="exam-details">
            <div class="exam-title">MT - {{ mainsMicroTargetIdx + 1 }} / {{ mainsSubtopicName }}</div>
            <div class="exam-timer">
                <ng-container *ngIf="!mainsSolutionOpened">
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ hours }}</div>
                        <div class="exam-timer-text">Hour</div>
                    </div>
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ minutes }}</div>
                        <div class="exam-timer-text">Minute</div>
                    </div>
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ seconds }}</div>
                        <div class="exam-timer-text">Second</div>
                    </div>
                </ng-container>

                <ng-container *ngIf="mainsSolutionOpened">
                    <div class="d-none d-md-block">Solution</div>
                </ng-container>
            </div>
        </div>

        <div class="question-section">
            <div class="d-md-none mb-4 question-section-top-mobile-section">
                <button type="button" class="question-button submit-button" (click)="toggleBottomSheet()">
                    <div>1 to {{mainsQuestionsList.length}}</div>
                    <div class="material-symbols-outlined">arrow_drop_down</div>
                </button>
                <div>
                    <button type="button" class="question-button submit-button" (click)="fetchMainsSummary()">Summary Report</button>
                </div>
            </div>

            <div class="d-md-none mb-4 question-section-top-mobile-section">
                <div class="d-flex">
                    <button type="button" class="question-button quetion-list-right-button" (click)="askYourDoubt()">Ask your Doubts</button>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="button" class="question-button quetion-list-right-button" (click)="backToQuestionBank()">Back to Question bank</button>
                </div>
            </div>

            <div class="question-left">
                <div class="question">
                    <div class="question-number">Ques {{ currentMainsQuestion + 1 }} of {{ mainsQuestionsList.length }}</div><br/>
                    <div class="question-text" [innerHTML]="mainsQuestionsList[currentMainsQuestion].description"></div><br/>
                </div><br/>

                <div class="question-buttons">
                    <button type="button" [class.answered-question]="mainsQuestionsList[currentMainsQuestion].answerLink || mainsQuestionsList[currentMainsQuestion].answerFile" class="question-button" (click)="submitAnswerDialog()">Submit Answer</button>
                    <button type="button" [class.answered-question]="mainsQuestionsList[currentMainsQuestion].model_answer || mainsQuestionsList[currentMainsQuestion].model_answer_file" class="question-button" (click)="downloadModelAnswer()">Download Model Answer</button>
                    <button type="button" [class.answered-question]="mainsQuestionsList[currentMainsQuestion].evaluated_copy || mainsQuestionsList[currentMainsQuestion].evaluated_file" class="question-button" (click)="viewEvaluatedCopy()">View Evaluated Copy</button>
                </div><br/><br/><br/>

                <ng-container *ngIf="mainsQuestionsList[currentMainsQuestion].evaluated_copy">
                    <div class="air">
                        <div class="air-rank">
                            <div class="mains-score">
                                {{ mainsQuestionsList[currentMainsQuestion].score }} / {{ mainsQuestionsList[currentMainsQuestion].marks }}
                            </div>
                            <div class="">
                                Score
                            </div>
                        </div>
                    </div><br/><br/>
                </ng-container>
            </div>
            <div class="question-list bottom-sheet-content d-md-block" *ngIf="isBottomSheetOpen">
                <div class="question-list-title">
                    Questions
                </div><br/>

                <div class="questions-group">
                    <ng-container *ngFor="let mainsQuestion of mainsQuestionsList; let i = index">
                        <div 
                            [class.answered-question]="mainsQuestion.answerLink || mainsQuestion.answerFile" 
                            class="questions-group-question" 
                            (click)="openMainsQuestion(i)"
                        >
                            <ng-container *ngIf="i < 9">0{{i + 1}}</ng-container>
                            <ng-container *ngIf="i >= 9">{{i + 1}}</ng-container>
                        </div>
                    </ng-container>
                </div><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/>

                <div class="d-none d-md-flex justify-content-center">
                    <button type="button" class="question-button quetion-list-right-button" (click)="fetchMainsSummary()">Summary Report</button>
                </div><br/><br/>
                <div class="d-none d-md-flex justify-content-center">
                    <button type="button" class="question-button quetion-list-right-button" (click)="askYourDoubt()">Ask your Doubts</button>
                </div><br/><br/>
                <div class="d-none d-md-flex justify-content-center">
                    <button type="button" class="question-button quetion-list-right-button" (click)="backToQuestionBank()">Back to Question bank</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length && pageMode == 'csat-exam'">
    <div class="exam-section">
        <div class="exam-details">
            <div class="exam-title">MT - {{ csatMicroTargetIdx }} / {{ csatSubtopicName }}</div>
            <div class="exam-timer">
                <ng-container *ngIf="!csatSolutionOpened">
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ hours }}</div>
                        <div class="exam-timer-text">Hour</div>
                    </div>
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ minutes }}</div>
                        <div class="exam-timer-text">Minute</div>
                    </div>
                    <div class="exam-timer-time">
                        <div class="exam-timer-number">{{ seconds }}</div>
                        <div class="exam-timer-text">Second</div>
                    </div>
                </ng-container>

                <ng-container *ngIf="csatSolutionOpened">
                    <div class="d-none d-md-block">Solution</div>
                </ng-container>
            </div>
        </div>

        <div class="question-section">
            <div class="d-md-none mb-4 question-section-top-mobile-section">
                <button type="button" class="question-button submit-button" (click)="toggleBottomSheet()">
                    <div>1 to {{csatQuestionsList.length}}</div>
                    <div class="material-symbols-outlined">arrow_drop_down</div>
                </button>
                <div>
                    <ng-container *ngIf="!csatSolutionOpened">
                        <button type="button" class="question-button submit-button" (click)="submitCsatTest()">Submit</button>
                    </ng-container>
                    <ng-container *ngIf="csatSolutionOpened">
                        <button type="button" class="question-button submit-button" (click)="fetchPrelimsSummary()">Summary Report</button>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="csatSolutionOpened">
                <div class="d-md-none mb-4 question-section-top-mobile-section">
                    <div class="d-flex">
                        <button type="button" class="question-button quetion-list-right-button" (click)="askYourDoubt()">Ask your Doubts</button>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="button" class="question-button quetion-list-right-button" (click)="backToQuestionBank()">Back to Question bank</button>
                    </div>
                </div>
            </ng-container>

            <div class="question">
                <div class="question-number">Ques {{ currentCsatQuestion + 1 }} of {{ csatQuestionsList.length }}</div><br/>
                <div class="question-text" [innerHTML]="csatQuestionsList[currentCsatQuestion].description"></div><br/>

                <ng-container *ngIf="!csatSolutionOpened">
                    <div 
                        class="question-option" 
                        (click)="csatQuestionsList[currentCsatQuestion].selectedOption = csatQuestionsList[currentCsatQuestion].op1"
                        [class.selected-option]="csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op1"
                    >
                        <div class="question-option-index">A</div>
                        <div class="question-option-value">{{ csatQuestionsList[currentCsatQuestion].op1 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        (click)="csatQuestionsList[currentCsatQuestion].selectedOption = csatQuestionsList[currentCsatQuestion].op2"
                        [class.selected-option]="csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op2"
                    >
                        <div class="question-option-index">B</div>
                        <div class="question-option-value">{{ csatQuestionsList[currentCsatQuestion].op2 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        (click)="csatQuestionsList[currentCsatQuestion].selectedOption = csatQuestionsList[currentCsatQuestion].op3"
                        [class.selected-option]="csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op3"
                    >
                        <div class="question-option-index">C</div>
                        <div class="question-option-value">{{ csatQuestionsList[currentCsatQuestion].op3 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        (click)="csatQuestionsList[currentCsatQuestion].selectedOption = csatQuestionsList[currentCsatQuestion].op4"
                        [class.selected-option]="csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op4"
                    >
                        <div class="question-option-index">D</div>
                        <div class="question-option-value">{{ csatQuestionsList[currentCsatQuestion].op4 }}</div>
                    </div>
                </ng-container>

                <ng-container *ngIf="csatSolutionOpened">
                    <div 
                        class="question-option" 
                        [class.answered-correct-border]="(csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op1) || 
                                                        (!csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].ans == csatQuestionsList[currentCsatQuestion].op1)"
                        [class.answered-wrong-border]="!csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op1"
                    >
                        <div class="question-option-index">A</div>
                        <div class="question-option-value">{{ csatQuestionsList[currentCsatQuestion].op1 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        [class.answered-correct-border]="(csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op2) || 
                                                        (!csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].ans == csatQuestionsList[currentCsatQuestion].op2)"
                        [class.answered-wrong-border]="!csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op2"
                    >
                        <div class="question-option-index">B</div>
                        <div class="question-option-value">{{ csatQuestionsList[currentCsatQuestion].op2 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        [class.answered-correct-border]="(csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op3) || 
                                                        (!csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].ans == csatQuestionsList[currentCsatQuestion].op3)"
                        [class.answered-wrong-border]="!csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op3"
                    >
                        <div class="question-option-index">C</div>
                        <div class="question-option-value">{{ csatQuestionsList[currentCsatQuestion].op3 }}</div>
                    </div>

                    <div 
                        class="question-option" 
                        [class.answered-correct-border]="(csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op4) || 
                                                        (!csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].ans == csatQuestionsList[currentCsatQuestion].op4)"
                        [class.answered-wrong-border]="!csatQuestionsList[currentCsatQuestion].is_correct && csatQuestionsList[currentCsatQuestion].selectedOption == csatQuestionsList[currentCsatQuestion].op4"
                    >
                        <div class="question-option-index">D</div>
                        <div class="question-option-value">{{ csatQuestionsList[currentCsatQuestion].op4 }}</div>
                    </div>

                    <div class="">Solution:</div>
                    <div class="question-text" [innerHTML]="csatQuestionsList[currentCsatQuestion].solution"></div><br/>
                </ng-container>

                <div class="question-buttons">
                    <ng-container *ngIf="currentCsatQuestion > 0">
                        <button type="button" class="question-button" (click)="currentCsatQuestion = currentCsatQuestion - 1">
                            <div class="question-button-first-tag material-symbols-outlined">west</div>
                            <div class="question-button-name">Previous</div>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="currentCsatQuestion <= 0">
                        <div class=""></div>
                    </ng-container>

                    <ng-container *ngIf="currentCsatQuestion < (csatQuestionsList.length - 1)">
                        <button type="button" class="question-button" (click)="currentCsatQuestion = currentCsatQuestion + 1">
                            <div class="question-button-first-tag question-button-name">Next</div>
                            <div class="material-symbols-outlined">east</div>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="currentCsatQuestion >= (csatQuestionsList.length - 1)">
                        <div class=""></div>
                    </ng-container>
                </div>
            </div>
            <div class="question-list-right bottom-sheet-content d-md-block" *ngIf="isBottomSheetOpen">
                <div class="question-list">
                    <div class="question-list-title">
                        Questions
                    </div><br/>

                    <div class="questions-group">
                        <ng-container *ngFor="let csatQuestion of csatQuestionsList; let i = index">
                            <div 
                                [class.answered-question]="csatQuestion.selectedOption" 
                                [class.answered-correct]="csatQuestion.is_answered && csatQuestion.is_correct" 
                                [class.answered-wrong]="csatQuestion.is_answered && !csatQuestion.is_correct" 
                                class="questions-group-question" 
                                (click)="openCsatQuestion(i)"
                            >
                                <ng-container *ngIf="i < 9">0{{i + 1}}</ng-container>
                                <ng-container *ngIf="i >= 9">{{i + 1}}</ng-container>
                            </div>
                        </ng-container>
                    </div><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/>

                    <div class="d-none d-md-flex justify-content-center">
                        <ng-container *ngIf="!csatSolutionOpened">
                            <button type="button" class="question-button submit-button" (click)="submitCsatTest()">Submit</button>
                        </ng-container>
                        <ng-container *ngIf="csatSolutionOpened">
                            <button type="button" class="question-button submit-button" (click)="fetchCsatSummary()">Summary Report</button>
                        </ng-container>
                    </div><br/>
                </div><br/><br/>

                <ng-container *ngIf="csatSolutionOpened">
                    <div class="d-none d-md-flex justify-content-center">
                        <button type="button" class="question-button quetion-list-right-button" (click)="askYourDoubt()">Ask your Doubts</button>
                    </div><br/><br/>
                    <div class="d-none d-md-flex justify-content-center">
                        <button type="button" class="question-button quetion-list-right-button" (click)="backToQuestionBank()">Back to Question bank</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length && pageMode == 'summary-prelims'">
    <div class="summary-section">
        <div class="summary-details">
            <div class="summary-title-left">MT - {{ prelimsMicroTargetIdx }} / {{ prelimsSubtopicName }}</div>
            <div class="d-none d-md-block summary-title-right">Summary Report</div>
        </div>

        <div class="scores">
            <div class="score">
                <div class="score-value">{{ prelimsSummaryDetails.number_of_correct }}</div>
                <div class="score-name">Correct</div>
            </div>
            <div class="score">
                <div class="score-value">{{ prelimsSummaryDetails.number_of_incorrect }}</div>
                <div class="score-name">Incorrect</div>
            </div>
            <div class="score">
                <div class="score-value">{{ prelimsSummaryDetails.number_of_unattempted }}</div>
                <div class="score-name">Unattempted</div>
            </div>
            <div class="score">
                <div class="score-value">{{ prelimsSummaryDetails.net_score.toFixed(2) }}</div>
                <div class="score-name">Net score</div>
            </div>
            <div class="score">
                <div class="score-value">{{ prelimsSummaryDetails.accuracy.toFixed(2) }} %</div>
                <div class="score-name">Accuracy</div>
            </div>
        </div>

        <div class="air">
            <div class="air-rank">AIR - {{ rank }}/{{ total }}</div>
        </div><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/>

        <div class="summary-buttons">
            <button type="button" class="summary-button" (click)="solutionQuestions(prelimsMicroTargetId, prelimsSubtopicName, prelimsMicroTargetIdx - 1, prelimsMilestonetIdx)">View Solution</button>
            <button type="button" class="summary-button" (click)="backToQuestionBank()">Back to Question bank</button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length && pageMode == 'summary-mains'">
    <div class="summary-section">
        <div class="summary-details">
            <div class="summary-title-left">MT - {{ mainsMicroTargetIdx }} / {{ mainsSubtopicName }}</div>
            <div class="d-none d-md-block summary-title-right">Summary Report</div>
        </div>

        <div class="scores">
            <div class="score">
                <div class="score-value">{{ mainsSummaryDetails.number_of_attempted }}</div>
                <div class="score-name">Attempted</div>
            </div>
            <div class="score">
                <div class="score-value">{{ mainsSummaryDetails.number_of_unattempted }}</div>
                <div class="score-name">Unattempted</div>
            </div>
            <div class="score">
                <div class="score-value">{{ mainsSummaryDetails.net_score.toFixed(2) }}</div>
                <div class="score-name">Net score</div>
            </div>
            <div class="score">
                <div class="score-value">{{ mainsSummaryDetails.accuracy.toFixed(2) }} %</div>
                <div class="score-name">Accuracy</div>
            </div>
        </div><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/>

        <div class="summary-buttons">
            <button type="button" class="summary-button" (click)="solutionQuestions(mainsMicroTargetId, mainsSubtopicName, mainsMicroTargetIdx - 1, mainsMicroTargetIdx)">View Solution</button>
            <button type="button" class="summary-button" (click)="backToQuestionBank()">Back to Question bank</button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && subscriptionPlanList.length && pageMode == 'summary-csat'">
    <div class="summary-section">
        <div class="summary-details">
            <div class="summary-title-left">MT - {{ csatMicroTargetIdx }} / {{ csatSubtopicName }}</div>
            <div class="d-none d-md-block summary-title-right">Summary Report</div>
        </div>

        <div class="scores">
            <div class="score">
                <div class="score-value">{{ csatSummaryDetails.number_of_correct }}</div>
                <div class="score-name">Correct</div>
            </div>
            <div class="score">
                <div class="score-value">{{ csatSummaryDetails.number_of_incorrect }}</div>
                <div class="score-name">Incorrect</div>
            </div>
            <div class="score">
                <div class="score-value">{{ csatSummaryDetails.number_of_unattempted }}</div>
                <div class="score-name">Unattempted</div>
            </div>
            <div class="score">
                <div class="score-value">{{ csatSummaryDetails.net_score.toFixed(2) }}</div>
                <div class="score-name">Net score</div>
            </div>
            <div class="score">
                <div class="score-value">{{ csatSummaryDetails.accuracy.toFixed(2) }} %</div>
                <div class="score-name">Accuracy</div>
            </div>
        </div>

        <div class="air">
            <div class="air-rank">AIR - {{ rank }}/{{ total }}</div>
        </div><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/><br class="d-none d-md-block"/>

        <div class="summary-buttons">
            <button type="button" class="summary-button" (click)="solutionQuestions(csatMicroTargetId, csatSubtopicName, csatMicroTargetIdx - 1, csatMilestonetIdx)">View Solution</button>
            <button type="button" class="summary-button" (click)="backToQuestionBank()">Back to Question bank</button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading && !subscriptionPlanList.length">
    <div class="question-bank-base-class">
        <ng-container *ngIf="isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn" [userImage]="student.profile_photo"></app-navbar>
        </ng-container>
        <ng-container *ngIf="!isLoggedIn">
            <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>
        </ng-container>

        <ng-container *ngFor="let add of marketingAddList">
            <ng-container *ngIf="add.type == 'Small Banner'">
                <div class="register-details">
                    <div>
                        <ng-container *ngFor="let str of add.text.split('/n')">
                            <div class="register-text">{{str}}</div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-light register-button" (click)="registerFreeSession(add)">{{add.button_label}}</button>
                </div>
            </ng-container>
        </ng-container>

        <div class="main-section">
            <app-sidebar [currentSidebarTab]="currentSidebarTab"></app-sidebar>

            <div class="question-bank-section">
                <div class="question-bank-details">
                    <div class="question-bank-details-title">QUESTION BANK</div>
                    <div class="tab-group">
                        <button type="button" [class.active-tab]="currentTab == 'prelims'" class="question-bank-details-tab-button" (click)="changeTab('prelims')">Prelims</button>
                        <button type="button" [class.active-tab]="currentTab == 'mains'" class="question-bank-details-tab-button" (click)="changeTab('mains')">Mains</button>
                        <button type="button" [class.active-tab]="currentTab == 'csat'" class="question-bank-details-tab-button" (click)="changeTab('csat')">CSAT</button>
                    </div>
                </div>

                <ng-container *ngIf="pageMode == 'topic'">
                    <div class="locked-screen">
                        <div class="background-image">
                            <img src="../../assets/Images/locked-study-plan-milestone.png" alt="locked-screen">
                        </div>
                        <div class="lock">
                            <div class="material-symbols-outlined lock-icon2">lock</div>
                            <button type="button" class="btn btn-dark lock-button" (click)="navigateToPricing()">Unlock 10000+ Pre Qs and Mains Qs</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
