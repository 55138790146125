import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressReportServiceAdapter } from './progress-report.service.adapter';
import { WeakTopicSummary } from 'src/app/common-classes/weak-topic-summary';
import { Task } from 'src/app/common-classes/task';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { TopicProgress } from 'src/app/common-classes/topic-progress';
import { PeerComparison } from 'src/app/common-classes/peer-comparison';
import { SubscriptionPlan } from 'src/app/common-classes/subscription-plan';
import { Student } from 'src/app/common-classes/student';
import { MarketingText } from 'src/app/common-classes/marketing-text';


@Component({
    selector: 'app-progress-report',
    templateUrl: './progress-report.component.html',
    styleUrls: ['./progress-report.component.css'],
    // encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressReportComponent implements OnInit {

    isLoading: boolean = false;
    isLoggedIn: boolean = true;
    currentSidebarTab: string = "progress-report";
    currentTab: string = "";
    currentFeatureTab: string = "syllabus-tracker";
    pageMode: string = "subscription";
    subscriptionPlanList: SubscriptionPlan[] = [];
    student: Student = new Student();

    items = Array.from({length: 100000}).map((_, i) => `Item #${i}`);
    option: any;
    colors: string[] = ['#7D74DD', '#FF914D'];

    isPrelimsSubscribed: boolean = false;
    prelimsTaskList: Task[] = [];
    prelimsReadingSessionsList: MentorshipSession[] = [];
    prelimsTopicProgressList: any = [];
    prelimsTopicDailyTargetList: any = [];
    prelimsWeakTopicSummaryDetails: WeakTopicSummary[] = [];
    prelimsTopicDailyAccuracyList: any = [];
    prelimsPeerComparisonList: PeerComparison[] = [];
    isPrelimsWeakAreaOpenedList: boolean[] = [];

    isMainsSubscribed: boolean = false;
    mainsTaskList: Task[] = [];
    mainsReadingSessionsList: MentorshipSession[] = [];
    mainsTopicProgressList: any = [];
    mainsTopicDailyTargetList: any = [];
    mainsWeakTopicSummaryDetails: WeakTopicSummary[] = [];
    mainsTopicDailyAccuracyList: any = [];
    mainsPeerComparisonList: PeerComparison[] = [];
    isMainsWeakAreaOpenedList: boolean[] = [];

    isCSATSubscribed: boolean = false;
    csatTaskList: Task[] = [];
    csatReadingSessionsList: MentorshipSession[] = [];
    csatTopicProgressList: any = [];
    csatTopicDailyTargetList: any = [];
    csatWeakTopicSummaryDetails: WeakTopicSummary[] = [];
    csatTopicDailyAccuracyList: any = [];
    csatPeerComparisonList: PeerComparison[] = [];
    isCSATWeakAreaOpenedList: boolean[] = [];

    menteeTaskList: Task[] = [];
    menteeReadingSessionsList: MentorshipSession[] = [];
    menteeTopicProgressList: any = [];
    menteeTopicDailyTargetList: any = [];
    menteeWeakTopicSummaryDetails: any = [];
    // menteeWeakTopicSummaryDetails: WeakTopicSummary[] = [];
    menteeTopicDailyAccuracyList: any = [];
    menteePeerComparisonList: PeerComparison[] = [];
    isMenteeWeakAreaOpenedList: boolean[] = [];

    marketingAddList: MarketingText[] = [];

    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new ProgressReportServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    changeTab(tabName: string) {
        this.currentTab = tabName;
    }

    initializeData(): void {
        for (let planI = 0; planI < this.subscriptionPlanList.length; planI++) {
            if (this.subscriptionPlanList[planI].plan_id == 1) {
                this.serviceAdapter.initializePrelimsData();
            } else if (this.subscriptionPlanList[planI].plan_id == 2) {
                this.serviceAdapter.initializeMainsData();
            } else if (this.subscriptionPlanList[planI].plan_id == 3) {
                this.serviceAdapter.initializeCSATData();
            }
        }
    }

    initializePrelimsData(value: any): void {
        let taskList = this.prelimsTaskList;
        let prelimsSessionList = value[0];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let sessionI = 0; sessionI < prelimsSessionList.length; sessionI++) {
                if (taskList[taskI].id == prelimsSessionList[sessionI].task.id) {
                    this.prelimsReadingSessionsList.push(prelimsSessionList[sessionI]);
                    break;
                }
            }
        }
        // console.log("Prelims Sessions List: ", this.prelimsReadingSessionsList);

        let prelimsTopicProgressList = value[1];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < prelimsTopicProgressList.length; progressI++) {
                if (taskList[taskI].id == prelimsTopicProgressList[progressI].task_id) {
                    this.prelimsTopicProgressList.push(prelimsTopicProgressList[progressI]);
                    break;
                }
            }
        }
        // console.log("Prelims Progress List: ", this.prelimsTopicProgressList);

        let prelimsTopicDailyTargetList = value[2];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < prelimsTopicDailyTargetList.length; progressI++) {
                if (taskList[taskI].id == prelimsTopicDailyTargetList[progressI].task_id) {
                    this.prelimsTopicDailyTargetList.push(prelimsTopicDailyTargetList[progressI]);
                    break;
                }
            }
        }
        // console.log("Prelims Progress Daily Target List: ", this.prelimsTopicDailyTargetList);

        let prelimsWeakTopicSummaryDetails = value[3];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < prelimsWeakTopicSummaryDetails.length; progressI++) {
                if (taskList[taskI].id == prelimsWeakTopicSummaryDetails[progressI].task_id) {
                    this.prelimsWeakTopicSummaryDetails.push(prelimsWeakTopicSummaryDetails[progressI]);
                    break;
                }
            }
        }
        // console.log("Prelims Weak Area Summary List: ", this.prelimsWeakTopicSummaryDetails);

        let prelimsTopicDailyAccuracyList = value[4];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < prelimsTopicDailyAccuracyList.length; progressI++) {
                if (taskList[taskI].id == prelimsTopicDailyAccuracyList[progressI].task_id) {
                    this.prelimsTopicDailyAccuracyList.push(prelimsTopicDailyAccuracyList[progressI]);
                    break;
                }
            }
        }
        // console.log("Prelims Progress Daily Accuracy List: ", this.prelimsTopicDailyAccuracyList);

        this.prelimsPeerComparisonList = value[5];
    }

    initializeMainsData(value: any): void {
        let taskList = this.mainsTaskList;
        let mainsSessionList = value[0];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let sessionI = 0; sessionI < mainsSessionList.length; sessionI++) {
                if (taskList[taskI].id == mainsSessionList[sessionI].task.id) {
                    this.mainsReadingSessionsList.push(mainsSessionList[sessionI]);
                    break;
                }
            }
        }
        // console.log("mains Sessions List: ", this.mainsReadingSessionsList);

        let mainsTopicProgressList = value[1];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < mainsTopicProgressList.length; progressI++) {
                if (taskList[taskI].id == mainsTopicProgressList[progressI].task_id) {
                    this.mainsTopicProgressList.push(mainsTopicProgressList[progressI]);
                    break;
                }
            }
        }
        // console.log("mains Progress List: ", this.mainsTopicProgressList);

        let mainsTopicDailyTargetList = value[2];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < mainsTopicDailyTargetList.length; progressI++) {
                if (taskList[taskI].id == mainsTopicDailyTargetList[progressI].task_id) {
                    this.mainsTopicDailyTargetList.push(mainsTopicDailyTargetList[progressI]);
                    break;
                }
            }
        }
        // console.log("mains Progress Daily Target List: ", this.mainsTopicDailyTargetList);

        let mainsWeakTopicSummaryDetails = value[3];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < mainsWeakTopicSummaryDetails.length; progressI++) {
                if (taskList[taskI].id == mainsWeakTopicSummaryDetails[progressI].task_id) {
                    this.mainsWeakTopicSummaryDetails.push(mainsWeakTopicSummaryDetails[progressI]);
                    break;
                }
            }
        }
        // console.log("mains Weak Area Summary List: ", this.mainsWeakTopicSummaryDetails);

        let mainsTopicDailyAccuracyList = value[4];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < mainsTopicDailyAccuracyList.length; progressI++) {
                if (taskList[taskI].id == mainsTopicDailyAccuracyList[progressI].task_id) {
                    this.mainsTopicDailyAccuracyList.push(mainsTopicDailyAccuracyList[progressI]);
                    break;
                }
            }
        }
        // console.log("mains Progress Daily Accuracy List: ", this.mainsTopicDailyAccuracyList);
        
        this.mainsPeerComparisonList = value[5];
    }

    initializeCSATData(value: any): void {
        let taskList = this.csatTaskList;
        let csatSessionList = value[0];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let sessionI = 0; sessionI < csatSessionList.length; sessionI++) {
                if (taskList[taskI].id == csatSessionList[sessionI].task.id) {
                    this.csatReadingSessionsList.push(csatSessionList[sessionI]);
                    break;
                }
            }
        }
        // console.log("csat Sessions List: ", this.csatReadingSessionsList);

        let csatTopicProgressList = value[1];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < csatTopicProgressList.length; progressI++) {
                if (taskList[taskI].id == csatTopicProgressList[progressI].task_id) {
                    this.csatTopicProgressList.push(csatTopicProgressList[progressI]);
                    break;
                }
            }
        }
        // console.log("csat Progress List: ", this.csatTopicProgressList);

        let csatTopicDailyTargetList = value[2];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < csatTopicDailyTargetList.length; progressI++) {
                if (taskList[taskI].id == csatTopicDailyTargetList[progressI].task_id) {
                    this.csatTopicDailyTargetList.push(csatTopicDailyTargetList[progressI]);
                    break;
                }
            }
        }
        // console.log("csat Progress Daily Target List: ", this.csatTopicDailyTargetList);

        let csatWeakTopicSummaryDetails = value[3];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < csatWeakTopicSummaryDetails.length; progressI++) {
                if (taskList[taskI].id == csatWeakTopicSummaryDetails[progressI].task_id) {
                    this.csatWeakTopicSummaryDetails.push(csatWeakTopicSummaryDetails[progressI]);
                    break;
                }
            }
        }
        // console.log("csat Weak Area Summary List: ", this.csatWeakTopicSummaryDetails);

        let csatTopicDailyAccuracyList = value[4];
        for (let taskI = 0; taskI < taskList.length; taskI++) {
            for (let progressI = 0; progressI < csatTopicDailyAccuracyList.length; progressI++) {
                if (taskList[taskI].id == csatTopicDailyAccuracyList[progressI].task_id) {
                    this.csatTopicDailyAccuracyList.push(csatTopicDailyAccuracyList[progressI]);
                    break;
                }
            }
        }
        // console.log("csat Progress Daily Accuracy List: ", this.csatTopicDailyAccuracyList);
        
        this.csatPeerComparisonList = value[5];
    }

    toggleWeakArea(taskIndex: number) {
        if (this.isMenteeWeakAreaOpenedList[taskIndex]) this.isMenteeWeakAreaOpenedList[taskIndex] = false;
        else if (!this.isMenteeWeakAreaOpenedList[taskIndex]) this.isMenteeWeakAreaOpenedList[taskIndex] = true;
    }

    openFeatures(): void {
        if (this.currentTab == "prelims") {
            this.menteeTaskList = this.prelimsTaskList;
            this.menteeReadingSessionsList = this.prelimsReadingSessionsList;
            this.menteeTopicProgressList = this.prelimsTopicProgressList;
            this.menteeTopicDailyTargetList = this.prelimsTopicDailyTargetList;
            this.menteeWeakTopicSummaryDetails = this.prelimsWeakTopicSummaryDetails;
            this.menteeTopicDailyAccuracyList = this.prelimsTopicDailyAccuracyList;
            this.menteePeerComparisonList = this.prelimsPeerComparisonList;
            this.isMenteeWeakAreaOpenedList = this.isPrelimsWeakAreaOpenedList;
        } else if (this.currentTab == "mains") {
            this.menteeTaskList = this.mainsTaskList;
            this.menteeReadingSessionsList = this.mainsReadingSessionsList;
            this.menteeTopicProgressList = this.mainsTopicProgressList;
            this.menteeTopicDailyTargetList = this.mainsTopicDailyTargetList;
            this.menteeWeakTopicSummaryDetails = this.mainsWeakTopicSummaryDetails;
            this.menteeTopicDailyAccuracyList = this.mainsTopicDailyAccuracyList;
            this.menteePeerComparisonList = this.mainsPeerComparisonList;
            this.isMenteeWeakAreaOpenedList = this.isMainsWeakAreaOpenedList;
        } else if (this.currentTab == "csat") {
            this.menteeTaskList = this.csatTaskList;
            this.menteeReadingSessionsList = this.csatReadingSessionsList;
            this.menteeTopicProgressList = this.csatTopicProgressList;
            this.menteeTopicDailyTargetList = this.csatTopicDailyTargetList;
            this.menteeWeakTopicSummaryDetails = this.csatWeakTopicSummaryDetails;
            this.menteeTopicDailyAccuracyList = this.csatTopicDailyAccuracyList;
            this.menteePeerComparisonList = this.csatPeerComparisonList;
            this.isMenteeWeakAreaOpenedList = this.isCSATWeakAreaOpenedList;
        }
        this.pageMode = 'feature';
        this.currentFeatureTab = 'syllabus-tracker';
    }

    closeFeatures(): void {
        this.menteeTaskList = [];
        this.menteeReadingSessionsList = [];
        this.menteeTopicProgressList = [];
        this.menteeTopicDailyTargetList = [];
        this.menteeWeakTopicSummaryDetails = [];
        this.menteeTopicDailyAccuracyList = [];
        this.menteePeerComparisonList = [];
        this.isMenteeWeakAreaOpenedList = [];
        this.pageMode = 'subscription';
    }

    getLinearOption(taskIndex: number): any {

        let dayList: string[] = [];
        let targetList: number[] = [];
        let accuracyList: number[] = [];

        for (let date in this.menteeTopicDailyTargetList[taskIndex].timeline) {
            dayList.push(date);
        }

        for (let date in this.menteeTopicDailyTargetList[taskIndex].timeline) {
            targetList.push(this.menteeTopicDailyTargetList[taskIndex].timeline[date]);
        }

        for (let date in this.menteeTopicDailyAccuracyList[taskIndex].timeline) {
            accuracyList.push(this.menteeTopicDailyAccuracyList[taskIndex].timeline[date]);
        }

        // console.log("dayList: ", dayList);
        // console.log("targetList: ", targetList);
        // console.log("accuracyList: ", accuracyList);

        this.option = {
            color: this.colors,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            // grid: {
            //     right: '10%'
            // },
            toolbox: {
                feature: {
                    dataView: { show: true, readOnly: false },
                    restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            legend: {
                data: ['Targets', 'Accuracy']
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true
                    },
                    // prettier-ignore
                    data: dayList
                    // data: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10', 'Day 11', 'Day 12']
                }
            ],
            yAxis: [
                
                {
                    type: 'value',
                    name: 'Targets',
                    position: 'left',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: this.colors[0]
                        }
                    },
                    axisLabel: {
                        formatter: '{value}'
                    }
                },
                {
                    type: 'value',
                    name: 'Accuracy %',
                    position: 'right',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: this.colors[1]
                        }
                    },
                    axisLabel: {
                        formatter: '{value}'
                    }
                }
            ],
            series: [
                {
                    name: 'Targets',
                    type: 'line',
                    data: targetList
                    // data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
                },
                {
                    name: 'Accuracy',
                    type: 'line',
                    yAxisIndex: 1,
                    data: accuracyList
                    // data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
                },
                {
                    name: 'Access From',
                    type: 'line',
                    width: '50%',
                }
            ]
        };
        return this.option;
    }

    getPieOption(taskIndex: number): any {
        let less40Count = this.menteeWeakTopicSummaryDetails[taskIndex].less_than_40_percent_count;
        let between4060Count = this.menteeWeakTopicSummaryDetails[taskIndex].greater_than_equal_to_40_less_than_60_count;
        let more60Count = this.menteeWeakTopicSummaryDetails[taskIndex].greater_than_60_percent_count;
        let totalCount = less40Count + between4060Count + more60Count;

        this.option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: true,
                        formatter(param: any) {
                            // correct the percentage
                            return param.name + ' (' + param.percent + '%)';
                        },
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 15,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: (less40Count / totalCount) * 100, name: 'Accuracy < 40%' },
                        { value: (between4060Count / totalCount) * 100, name: '40% < Accuracy < 60%' },
                        { value: (more60Count / totalCount) * 100, name: 'Accuracy > 60%' },
                    ],
                    color: ['#FF3131', '#FF914D', '#00BF63']
                }
            ]
        };
        return this.option;
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    navigateToPricing() {
        this.router.navigate(['/'], { queryParams: { id: "subscription-plans" } });
    }
}