import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomepageServiceAdapter } from './homepage.service.adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { MarketingText } from '../common-classes/marketing-text';
import { Testimonial } from '../common-classes/testimonial';
import { DJANGO_SERVER } from '../environment/environment';
import { SubscriptionPlan } from '../common-classes/subscription-plan';


@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, OnDestroy {

    isLoading: boolean = true;
    isLoggedIn: boolean = false;
    subscriptionTimerId: any;

    amount: number = 0;
    plan_id: number = 0;
    orderId: string = "";
    currency: string = "";

    gradientPercentage: number = 0;
    gradientReverse: boolean = false;
    gradientIntervalId: any;

    marketingAddList: MarketingText[] = [];
    testimonialList: Testimonial[] = [];
    planList: SubscriptionPlan[] = [];

    serviceAdapter: any;
    user: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new HomepageServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();
    }

    ngOnDestroy(): void {
        this.stopGradientColor();
    }

    navigateToPricing() {
        let element = document.getElementById("subscription-plans");
        if (element) {
            element.scrollIntoView();
        }
    }

    checkRouting() {
        this.route.queryParamMap.subscribe((params: any) => {
            if (params.hasOwnProperty('params') && params.params.hasOwnProperty('id')) {
                this.subscriptionTimerId = setInterval(() => {
                    const element = document.getElementById(params.params.id);
                    // console.log("Element: ", element);
                    if (element) {
                        element.scrollIntoView();
                        this.stopSubscriptionTimer();
                    }
                }, 10); // Update every 1 semi-second
            }
        });
    }

    gradientColor() {
        this.gradientIntervalId = setInterval(() => {
            if (this.gradientReverse) {
                this.gradientPercentage -= 1;
            } else {
                this.gradientPercentage += 1;
            }

            if (this.gradientPercentage >= 70) {
                this.gradientReverse = true;
            } else if (this.gradientPercentage <= 0) {
                this.gradientReverse = false;
            }
        }, 50);
    }

    stopGradientColor() {
        clearInterval(this.gradientIntervalId);
    }

    stopSubscriptionTimer(): void {
        clearInterval(this.subscriptionTimerId);
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    checkPlanId(plan_id: number) {
        for(let i = 0; i < this.user.subscription_plan.length; i++) {
            if (this.user.subscription_plan[i].plan_id == plan_id) {
                return true;
            }
        }
        return false;
    }

    createRzpayOrder(amount: number, plan_id: number): void {
        if (!this.isLoggedIn) {
            // this.router.navigate(['login']);
            const dialogRef = this.dialog.open(LoginComponent);
            dialogRef.afterClosed().subscribe(result => {
                // console.log("Response: ", result);
                if (result && result.hasOwnProperty('isLoggedIn') && result.isLoggedIn) {
                    this.amount = amount;
                    this.plan_id = plan_id;
                    if (!this.checkPlanId(plan_id)) {
                        this.serviceAdapter.makePayment();
                    } else {
                        alert('You have already subscribed to this plan.');
                    }
                } else {
                    alert("Please Refresh & Log-in Again!");
                }
            });
        } else {
            this.amount = amount;
            this.plan_id = plan_id;
            if (!this.checkPlanId(plan_id)) {
                this.serviceAdapter.makePayment();
            } else {
                alert('You have already subscribed to this plan.');
            }
        }
    }

    getTestimonialImage(imageLink: string): string {
        return DJANGO_SERVER + imageLink;
    }

    // payWithRazor(order_id: any) {
    //     const options: any = {
    //         key: 'rzp_test_oGEl7xFT79Jw9i',
    //         amount: this.amount,
    //         currency: this.currency,
    //         name: 'MentorsHut', // company name or product name
    //         description: 'Jay Shree Swaminarayan',  // product description
    //         image: '../../assets/Images/combined_logo.svg', // company logo or product image
    //         order_id: order_id,
    //         modal: {
    //             // We should prevent closing of the form when esc key is pressed.
    //             escape: false,
    //         },
    //         notes: {
    //             // include notes if any
    //         },
    //         theme: {
    //             color: '#DCDAF1'
    //         }
    //     };
    //     options.handler = ((response: any, error: any) => {
    //         options.response = response;
    //         // console.log(response);
    //         console.log(error);
    //         // console.log(options);
    //         response['amount'] = this.amount;
    //         this.serviceAdapter.verifyTransaction(response);
    //         alert("Subscription Plan Added Successfully.");
    //     });
    //     options.modal.ondismiss = (() => {
    //         let data = {
    //             order_id: this.orderId
    //         }
    //         this.serviceAdapter.cancelTransaction(data);
    //         // console.log('Transaction cancelled.');
    //     });
    //     const rzp = new this.serviceAdapter.nativeWindow.Razorpay(options);
    //     rzp.open();
    // }
}
