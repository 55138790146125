import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-view-evaluated-copy-modal',
    templateUrl: './view-evaluated-copy-modal.component.html',
    styleUrls: ['./view-evaluated-copy-modal.component.css']
})
export class ViewEvaluatedCopyModalComponent {
    link: string = "";
    constructor(
        public dialogRef: MatDialogRef<ViewEvaluatedCopyModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.link = data.link;
    }
}
