import { User } from './user';

export class Mentor {
    id: number = 0;
    user: User = new User();
    name: string = "";
    profile_photo: string = "";
    mobile_no: string = "";
    email_id: string = "";
    category: string = "";
    dob: string = "";
    residence_state: string = "";
    description: string = "";
    expertise: string = "";

    gender: string = "";
    preparation_started: string = "";
    optional_subject: string = "";
    next_exam_attempt: string = "";
    previous_coaching: string = "";
    no_of_attempts: string = "";
    no_of_prelims_qualified: string = "";
    no_of_mains_qualified: string = "";
    last_mains_score: number = 0;
    graduation_year: string = "";
    graduation_background: string = "";
    specialised_subject: string = "";
}
