import { HttpClient } from '@angular/common/http';
import { UpcomingSessionsComponent } from './upcoming-sessions.component';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class UpcomingSessionsServiceAdapter {

    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: UpcomingSessionsComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/mentor_profile/";
        const getMentorPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorPromise         // 0
        ]).then(
            (value: any) => {
                this.vm.isLoggedIn = true;
                this.vm.mentor = value[0];
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.isLoading = false;
                this.vm.router.navigate(['/']);
                return;
            }
        );

        apiString = DJANGO_SERVER + "/api/mentorship_session/mentors_upcoming_sessions/";
        const getMentorSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/free_sessions/bookings/";
        const getFreeSessionList = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/mentorship_session/mentors_past_sessions/";
        const getMentorPastSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/free_sessions/bookings_past/";
        const getFreePastSessionList = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorSessions,         // 0
            getFreeSessionList,        // 1
            getMentorPastSessions,     // 2
            getFreePastSessionList,    // 3
        ]).then(
            (value: any) => {
                // console.log("Session List: ", value);
                this.vm.menteeSessionList = value[0];
                this.vm.freeSessionList = value[1];

                this.vm.menteePastSessionList = value[2];
                this.vm.freePastSessionList = value[3];
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.isLoading = false;
    }

    async updateSession() {
        this.vm.isLoading = true;
        let sessionData = {
            mentorship_session_id: this.vm.sessionId,
            date: this.vm.sessionDate,
            time: this.vm.sessionTime,
        }

        let apiString = DJANGO_SERVER + "/api/mentorship_session/upcoming_sessions/";
        const updateUpcomingSession = this.http.put(apiString, sessionData).toPromise();

        await Promise.all([
            updateUpcomingSession,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.menteeSessionList[this.vm.sessionIdx].date = this.vm.sessionDate;
                this.vm.menteeSessionList[this.vm.sessionIdx].time = this.vm.sessionTime;
                alert("Session Updated Successfully");

                this.vm.menteeSessionList.sort(function(a: MentorshipSession, b: MentorshipSession) : number {
                    let dateA = a.date;
                    let dateB = b.date;
                    if (dateA < dateB) return -1;
                    else if (dateA > dateB) return 1;
                    else {
                        let timeA = a.time;
                        let timeB = b.time;
                        if (timeA <= timeB) return -1;
                        return 1;
                    }
                });
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );

        this.vm.sessionId = 0;
        this.vm.sessionIdx = 0;
        this.vm.sessionDate = "";
        this.vm.sessionTime = "";
        this.vm.currentSessionTab = "sessions";
        this.vm.isLoading = false;
    }

    async updateFreeSession() {
        this.vm.isLoading = true;
        let sessionData = {
            mentorship_session_id: this.vm.sessionId,
            date: this.vm.sessionDate,
            time: this.vm.sessionTime,
        }

        let apiString = DJANGO_SERVER + "/api/free_sessions/bookings/";
        const updateFreeSession = this.http.put(apiString, sessionData).toPromise();

        await Promise.all([
            updateFreeSession,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.freeSessionList[this.vm.sessionIdx].date = this.vm.sessionDate;
                this.vm.freeSessionList[this.vm.sessionIdx].time = this.vm.sessionTime;
                alert("Session Updated Successfully");

                this.vm.freeSessionList.sort(function(a: any, b: any) : number {
                    let dateA = a.date;
                    let dateB = b.date;
                    if (dateA < dateB) return -1;
                    else if (dateA > dateB) return 1;
                    else {
                        let timeA = a.time;
                        let timeB = b.time;
                        if (timeA <= timeB) return -1;
                        return 1;
                    }
                });
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );

        this.vm.sessionId = 0;
        this.vm.sessionIdx = 0;
        this.vm.sessionDate = "";
        this.vm.sessionTime = "";
        this.vm.currentSessionTab = "sessions";
        this.vm.isLoading = false;
    }

    async uploadSessionReport(report: File, sessionId: number, sessionIdx: number) {
        this.vm.isLoading = true;
        // let reportData = {
        //     analysis_report: reportLink,
        // };

        let reportFormData = new FormData();
        reportFormData.append('analysis_report_file', report)

        let apiString = DJANGO_SERVER + "/api/mentorship_session/upload_analysis_report/" + sessionId;
        const putSessionReport = this.http.put(apiString, reportFormData).toPromise();

        await Promise.all([
            putSessionReport,                      // 0
        ]).then(
            (value: any) => {
                console.log(value);
                this.vm.menteePastSessionList[sessionIdx].analysis_report_file = value[0].analysis_report_file;
                alert("Report Uploaded Successfully.");
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );
        this.vm.isLoading = false;
    }

    async uploadFreeSessionReport(report: File, sessionId: number, sessionIdx: number) {
        this.vm.isLoading = true;
        // let reportData = {
        //     analysis_report: reportLink,
        // };

        let reportFormData = new FormData();
        reportFormData.append('analysis_report_file', report)

        let apiString = DJANGO_SERVER + "/api/free_sessions/upload_free_session_analysis_report/" + sessionId;
        const putSessionReport = this.http.put(apiString, reportFormData).toPromise();

        await Promise.all([
            putSessionReport,                      // 0
        ]).then(
            (value: any) => {
                // console.log(value);
                this.vm.freePastSessionList[sessionIdx].analysis_report_file = value[0].analysis_report_file;
                alert("Report Uploaded Successfully.");
            },
            (error) => {
                console.log(error);
                alert("An error has occured.");
            }
        );
        this.vm.isLoading = false;
    }
}
