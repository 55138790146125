import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { QuestionBankServiceAdapter } from './question-bank.service.adapter';
import { Task } from 'src/app/common-classes/task';
import { SubTask } from 'src/app/common-classes/subtask';
import { PrelimsQuestion } from 'src/app/common-classes/prelims-question';
import { PrelimsSummaryReport } from 'src/app/common-classes/prelims-summary-report';
import { MainsQuestion } from 'src/app/common-classes/mains-question';
import { MatDialog } from '@angular/material/dialog';
import { SubmitAnswerModalComponent } from './local-components/submit-answer-modal/submit-answer-modal.component';
import { CsatSummaryReport } from 'src/app/common-classes/csat-summary-report';
import { CsatQuestion } from 'src/app/common-classes/csat-question';
import { MentorshipSession } from 'src/app/common-classes/mentorship-session';
import { Student } from 'src/app/common-classes/student';
import { MainsSummaryReport } from 'src/app/common-classes/mains-summary-report';
import { DJANGO_SERVER } from 'src/app/environment/environment';
import { MarketingText } from 'src/app/common-classes/marketing-text';


@Component({
    selector: 'app-question-bank',
    templateUrl: './question-bank.component.html',
    styleUrls: ['./question-bank.component.css']
})
export class QuestionBankComponent implements OnInit {

    isLoading: boolean = true;
    currentSidebarTab: string = "question-bank";
    student: Student = new Student();

    pageMode: string = "topic";
    currentTab: string = "";
    isLoggedIn: boolean = false;
    subscriptionPlanList: number[] = [];

    currentPrelimsQuestion: number = -1;
    prelimsSolutionOpened: boolean = false;
    prelimsMicroTargetIdx: number = 0;
    prelimsMilestonetIdx: number = 0;
    prelimsMicroTargetId: number = 0;
    prelimsSubtopicName: string = "";
    prelimsSummaryDetails: PrelimsSummaryReport = new PrelimsSummaryReport();
    prelimsQuestionsList: PrelimsQuestion[] = [];
    isPrelimsSubscribed: boolean = false;
    prelimsTaskList: Task[] = [];
    prelimsQuestionsSessionsList: MentorshipSession[] = [];

    currentMainsQuestion: number = -1;
    mainsSolutionOpened: boolean = false;
    mainsMicroTargetIdx: number = 0;
    mainsMilestonetIdx: number = 0;
    mainsMicroTargetId: number = 0;
    mainsSubtopicName: string = "";
    mainsSummaryDetails: MainsSummaryReport = new MainsSummaryReport();
    mainsQuestionsList: MainsQuestion[] = [];
    isMainsSubscribed: boolean = false;
    mainsTaskList: Task[] = [];
    mainsQuestionsSessionsList: MentorshipSession[] = [];

    currentCsatQuestion: number = -1;
    csatSolutionOpened: boolean = false;
    csatMicroTargetIdx: number = 0;
    csatMilestonetIdx: number = 0;
    csatMicroTargetId: number = 0;
    csatSubtopicName: string = "";
    csatSummaryDetails: CsatSummaryReport = new CsatSummaryReport();
    csatQuestionsList: CsatQuestion[] = [];
    isCSATSubscribed: boolean = false;
    csatTaskList: Task[] = [];
    csatQuestionsSessionsList: MentorshipSession[] = [];

    hours: string = "";
    minutes: string = "";
    seconds: string = "";
    timerId: any;
    rank: number = 0;
    total: number = 0;
    marketingAddList: MarketingText[] = [];
    isBottomSheetOpen = false;
    serviceAdapter: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.serviceAdapter = new QuestionBankServiceAdapter(this.http);
        this.serviceAdapter.initializeAdapter(this);
        this.serviceAdapter.initializeData();

        if (window.screen.width > 768) {
            this.isBottomSheetOpen = true;
        }
    }

    toggleBottomSheet(): void {
        this.isBottomSheetOpen = !this.isBottomSheetOpen;
    }

    changeTab(tabName: string) {
        this.currentTab = tabName;
    }

    checkClick(): void {
        // console.log("Hello");
    }

    showTime(timer: number): void {
        let hours: number = 0, minutes: number = 0, seconds: number = 0;
        hours = Math.floor(timer / 3600);
        minutes = Math.floor((timer % 3600) / 60);
        seconds = timer % 60;

        if (hours < 10) {
            this.hours = "0" + hours;
        } else {
            this.hours = "" + hours;
        }

        if (minutes < 10) {
            this.minutes = "0" + minutes;
        } else {
            this.minutes = "" + minutes;
        }

        if (seconds < 10) {
            this.seconds = "0" + seconds;
        } else {
            this.seconds = "" + seconds;
        }
    }

    startTimer(topicIdx: number, subtopicIdx: number, examType: string) {
        let duration: number = 0;
        if (examType == "prelims") {
            duration = 60 * (this.prelimsTaskList[topicIdx].subtaskList[subtopicIdx].subtopic.questionbank_time_limit);
        } else if (examType == "mains") {
            duration = 60 * (this.mainsTaskList[topicIdx].subtaskList[subtopicIdx].subtopic.questionbank_time_limit);
        } else if (examType == "csat") {
            duration = 60 * (this.csatTaskList[topicIdx].subtaskList[subtopicIdx].subtopic.questionbank_time_limit);
        }

        let timer: number = duration;
        this.showTime(timer); timer--;
        this.timerId = setInterval(() => {
            this.showTime(timer);
            if (--timer <= 0) {
                this.stopTimer();
            }
        }, 1000); // Update every 1 second
    }

    stopTimer(): void {
        clearInterval(this.timerId);
        if (this.pageMode == "prelims-exam") {
            this.submitPrelimsTest();
        } else if (this.pageMode == "csat-exam") {
            this.submitCsatTest();
        } else {
            this.pageMode = "topic";
            alert("Test is completed.");
        }
    }

    practiceQuestions(subtopicId: number, subtopicName: string, subtopicIdx: number, topicIdx: number): void {
        if (this.currentTab == "prelims") {
            this.prelimsMicroTargetIdx = subtopicIdx + 1;
            this.prelimsMilestonetIdx = topicIdx;
            this.prelimsMicroTargetId = subtopicId;
            this.prelimsSubtopicName = subtopicName;
            this.currentPrelimsQuestion = 0;
            this.serviceAdapter.fetchPrelimsQuestions();
            this.pageMode = "prelims-exam";
            this.startTimer(topicIdx, subtopicIdx, "prelims");
        } else if (this.currentTab == "mains") {
            this.mainsMicroTargetIdx = subtopicIdx + 1;
            this.mainsMilestonetIdx = topicIdx;
            this.mainsMicroTargetId = subtopicId;
            this.mainsSubtopicName = subtopicName;
            this.currentMainsQuestion = 0;
            this.serviceAdapter.fetchMainsQuestions();
            this.pageMode = "mains-exam";
            this.startTimer(topicIdx, subtopicIdx, "mains");
        } else if (this.currentTab == "csat") {
            this.csatMicroTargetIdx = subtopicIdx + 1;
            this.csatMilestonetIdx = topicIdx;
            this.csatMicroTargetId = subtopicId;
            this.csatSubtopicName = subtopicName;
            this.currentCsatQuestion = 0;
            this.serviceAdapter.fetchCsatQuestions();
            this.pageMode = "csat-exam";
            this.startTimer(topicIdx, subtopicIdx, "csat");
        }
    }

    solutionQuestions(subtopicId: number, subtopicName: string, subtopicIdx: number, topicIdx: number): void {
        if (this.currentTab == "prelims") {
            this.prelimsMicroTargetIdx = subtopicIdx + 1;
            this.prelimsMilestonetIdx = topicIdx;
            this.prelimsMicroTargetId = subtopicId;
            this.prelimsSubtopicName = subtopicName;
            this.prelimsSolutionOpened = true;
            this.currentPrelimsQuestion = 0;
            this.serviceAdapter.fetchPrelimsQuestionsSolution();
            this.pageMode = "prelims-exam";
        } else if (this.currentTab == "mains") {
            this.csatMicroTargetIdx = subtopicIdx + 1;
            this.mainsMilestonetIdx = topicIdx;
            this.mainsMicroTargetId = subtopicId;
            this.mainsSubtopicName = subtopicName;
            this.mainsSolutionOpened = true;
            this.currentMainsQuestion = 0;
            this.serviceAdapter.fetchMainsQuestions();
            this.pageMode = "mains-exam";
        } else if (this.currentTab == "csat") {
            this.mainsMicroTargetIdx = subtopicIdx + 1;
            this.csatMilestonetIdx = topicIdx;
            this.csatMicroTargetId = subtopicId;
            this.csatSubtopicName = subtopicName;
            this.csatSolutionOpened = true;
            this.currentCsatQuestion = 0;
            this.serviceAdapter.fetchCsatQuestionsSolution();
            this.pageMode = "csat-exam";
        }
    }

    openPrelimsQuestion(questionIdx: number): void {
        this.currentPrelimsQuestion = questionIdx;
        if (window.screen.width <= 768) {
            this.isBottomSheetOpen = false;
        }
    }

    openMainsQuestion(questionIdx: number): void {
        this.currentMainsQuestion = questionIdx;
        if (window.screen.width <= 768) {
            this.isBottomSheetOpen = false;
        }
    }

    openCsatQuestion(questionIdx: number): void {
        this.currentCsatQuestion = questionIdx;
        if (window.screen.width <= 768) {
            this.isBottomSheetOpen = false;
        }
    }

    submitPrelimsTest(): void {
        clearInterval(this.timerId);
        this.serviceAdapter.submitPrelimsTest();
    }

    submitCsatTest(): void {
        clearInterval(this.timerId);
        this.serviceAdapter.submitCsatTest();
    }

    fetchPrelimsSummary(): void {
        this.serviceAdapter.fetchPrelimsSummary();
    }

    fetchMainsSummary(): void {
        this.serviceAdapter.fetchMainsSummary();
    }

    fetchCsatSummary(): void {
        this.serviceAdapter.fetchCsatSummary();
    }

    backToQuestionBank(): void {
        if (this.currentTab == "prelims") {
            this.currentPrelimsQuestion = 0;
            this.prelimsSolutionOpened = false;
            this.prelimsMicroTargetIdx = -1;
            this.prelimsMilestonetIdx = 0;
            this.prelimsMicroTargetId = 0;
            this.prelimsSubtopicName = "";
            this.prelimsSummaryDetails = new PrelimsSummaryReport();
        } else if (this.currentTab == "csat") {
            this.currentCsatQuestion = 0;
            this.csatSolutionOpened = false;
            this.csatMicroTargetIdx = -1;
            this.csatMilestonetIdx = 0;
            this.csatMicroTargetId = 0;
            this.csatSubtopicName = "";
            this.csatSummaryDetails = new CsatSummaryReport();
        } else {
            this.currentMainsQuestion = 0;
            this.mainsSolutionOpened = false;
            this.mainsMicroTargetIdx = -1;
            this.mainsMilestonetIdx = 0;
            this.mainsMicroTargetId = 0;
            this.mainsSubtopicName = "";
        }
        this.pageMode = "topic";
    }

    askYourDoubt(): void {
        this.router.navigate(['mentorship/'], { queryParams: { page: "doubt" } });
    }

    validDateOfSubtask(subtask: SubTask): boolean {
        let dateProperty: string = 'questions_start';
        let subtaskDate = subtask[dateProperty as keyof SubTask];

        let [month, date, year] = new Date().toLocaleDateString("en-US").split("/");
        if (parseInt(month) < 10) {
            month = "0" + month;
        }
        if (parseInt(date) < 10) {
            date = "0" + date;
        }
        let currentDate = year + "-" + month + "-" + date;
        return (currentDate.localeCompare(subtaskDate as string) >= 0);
    }

    downloadModelAnswer(): void {
        if (this.mainsQuestionsList[this.currentMainsQuestion].model_answer) {
            window.open(this.mainsQuestionsList[this.currentMainsQuestion].model_answer, '_blank');
        } else if (this.mainsQuestionsList[this.currentMainsQuestion].model_answer_file) {
            window.open(DJANGO_SERVER + this.mainsQuestionsList[this.currentMainsQuestion].model_answer_file, '_blank');
        }
        this.mainsQuestionsList[this.currentMainsQuestion].isDownloaded = true;
    }

    submitAnswerDialog(): void {
        if (this.mainsQuestionsList[this.currentMainsQuestion].answerLink || this.mainsQuestionsList[this.currentMainsQuestion].answerFile) {
            alert("You have already answer to this question.");
            return;
        }
        const dialogRef = this.dialog.open(SubmitAnswerModalComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.hasOwnProperty("file")) {
                // this.mainsQuestionsList[this.currentMainsQuestion].answerLink = result.link;
                this.mainsQuestionsList[this.currentMainsQuestion].answerFile = result.file;
                this.serviceAdapter.submitMainsAnswer();
            }
        });
    }

    viewEvaluatedCopy(): void {
        if (this.mainsQuestionsList[this.currentMainsQuestion].evaluated_copy) {
            window.open(this.mainsQuestionsList[this.currentMainsQuestion].evaluated_copy, '_blank');
        } else if (this.mainsQuestionsList[this.currentMainsQuestion].evaluated_file) {
            window.open(DJANGO_SERVER + this.mainsQuestionsList[this.currentMainsQuestion].evaluated_file, '_blank');
        } else {
            alert("Your answer is not evaluated yet, please check after sometime.");
        }
    }

    registerFreeSession(add: MarketingText): void {
        if (add.open_new_tab) {
            window.open(add.link, '_blank');
        } else {
            window.open(add.link, '_self');
        }
    }

    navigateToPricing() {
        this.router.navigate(['/'], { queryParams: { id: "subscription-plans" } });
    }
}
