import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { CountAllRoutingModule } from './count-all.routing';
import { MenteeProfileComponent } from './mentee-profile.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BasicComponentsModule } from '../basic-components/basic-components.module';

@NgModule({
    declarations: [MenteeProfileComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        MatCheckboxModule,
        BasicComponentsModule
    ],
    exports: [
        MenteeProfileComponent,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        MatCheckboxModule
    ],
    providers: [],
    entryComponents: [],
})
export class MenteeProfileModule {}
