import { DemoSessionComponent } from './demo-session.component';
import { HttpClient } from '@angular/common/http';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class DemoSessionServiceAdapter {
    
    vm: any;

    constructor(
        public http: HttpClient
    ) { }

    initializeAdapter(vm: DemoSessionComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/mentor_or_student_profile/";
        const getMentorOrStudentPromise = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/blog/get_all_testimonials/";
        const getTestiminials = this.http.get(apiString).toPromise();

        await Promise.all([
            getMentorOrStudentPromise         // 0
        ]).then(
            (value) => {
                // console.log(value);
                this.vm.user = value[0];
                this.vm.isLoggedIn = true;
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
            }
        );

        await Promise.all([
            getMarketingAdds,                  // 0
            getTestiminials,                   // 1
        ]).then(
            (value) => {
                this.vm.marketingAddList = value[0];
                this.vm.testimonialList = value[1];
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
            }
        );
        this.vm.isLoading = false;
    }

    async submitUserDetails() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/free_sessions/bookings/";
        let sessionData = {
            "name": this.vm.name,
            "mobile_no": this.vm.mobileNumber,
            "email_id": this.vm.emailId,
            "exam_year": this.vm.examYear
        };

        const bookFreeSession = this.http.post(apiString, sessionData).toPromise()
        await Promise.all([
            bookFreeSession         // 0
        ]).then(
            (value: any) => {
                if (value[0]['detail']) {
                    alert(value[0]['detail']);
                    return;
                }
                this.vm.name = "";
                this.vm.mobileNumber = "";
                this.vm.emailId = "";
                this.vm.examYear = this.vm.examYearDefault;
                alert("Session booked successfully.");
            },
            (error) => {
                console.log(error);
                alert("You have already booked a free session either with this email or mobile number.");
            }
        );
        this.vm.isLoading = false;
    }
}
