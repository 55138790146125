import { HttpClient } from '@angular/common/http';
import { ProgressReportComponent } from './progress-report.component';
import { DJANGO_SERVER } from 'src/app/environment/environment';


export class ProgressReportServiceAdapter {
    
    vm: any;

    constructor(
        private http: HttpClient
    ) { }

    initializeAdapter(vm: ProgressReportComponent): void {
        this.vm = vm;
    }

    /* Initialize Data */
    async initializeData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/users/student_profile/";
        const getStudentPromise = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentPromise         // 0
        ]).then(
            (value: any) => {
                // console.log("Student: ", value);
                this.vm.student = value[0];
                this.vm.isLoggedIn = true;
                this.vm.subscriptionPlanList = value[0].subscription_plan;
            },
            (error) => {
                console.log(error);
                this.vm.isLoggedIn = false;
                this.vm.isLoading = false;
                this.vm.router.navigate(['/']);
                return;
            }
        );

        apiString = DJANGO_SERVER + "/api/study_plan/personalized_plan/";
        const getStudentTopics = this.http.get(apiString).toPromise();

        apiString =  DJANGO_SERVER + "/api/users/marketing_text/";
        const getMarketingAdds = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentTopics,                        // 0
            getMarketingAdds,                        // 1
        ]).then(
            (value: any) => {
                // console.log("Student Topics: ", value);
                this.vm.marketingAddList = value[1];
                if (value[0].hasOwnProperty('prelims') && value[0].prelims.hasOwnProperty('tasks')) {
                    this.vm.isPrelimsSubscribed = true;
                    this.vm.currentTab = "prelims";
                    this.vm.isPrelimsWeakAreaOpenedList = [];
                    
                    let taskList = value[0].prelims.tasks;
                    let subtaskList = value[0].prelims.subtasks;
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                        this.vm.isPrelimsWeakAreaOpenedList.push(false);
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                    }
                    this.vm.prelimsTaskList = taskList;
                    // console.log("Prelims Task List: ", this.vm.prelimsTaskList);
                }
                if (value[0].hasOwnProperty('mains') && value[0].mains.hasOwnProperty('tasks')) {
                    this.vm.isMainsSubscribed = true;
                    if (!this.vm.currentTab) this.vm.currentTab = "mains";
                    this.vm.isMainsWeakAreaOpenedList = [];
                    
                    let taskList = value[0].mains.tasks;
                    let subtaskList = value[0].mains.subtasks;
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                        this.vm.isMainsWeakAreaOpenedList.push(false);
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                    }
                    this.vm.mainsTaskList = taskList;
                    // console.log("mains Task List: ", this.vm.mainsTaskList);
                }
                if (value[0].hasOwnProperty('csat') && value[0].csat.hasOwnProperty('tasks')) {
                    this.vm.isCSATSubscribed = true;
                    if (!this.vm.currentTab) this.vm.currentTab = "csat";
                    this.vm.isCSATWeakAreaOpenedList = [];
                    
                    let taskList = value[0].csat.tasks;
                    let subtaskList = value[0].csat.subtasks;
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        taskList[taskI]['subtaskList'] = [];
                        taskList[taskI]['isOpen'] = false;
                        this.vm.isCSATWeakAreaOpenedList.push(false);
                    }
                    for (let taskI = 0; taskI < taskList.length; taskI++) {
                        for (let subtaskI = 0; subtaskI < subtaskList.length; subtaskI++) {
                            if (taskList[taskI].id == subtaskList[subtaskI].task) {
                                taskList[taskI]['subtaskList'].push(subtaskList[subtaskI]);
                            }
                        }
                    }
                    this.vm.csatTaskList = taskList;
                    // console.log("csat Task List: ", this.vm.csatTaskList);
                }
            },
            (error) => {
                console.log(error);
            }
        );
        this.vm.initializeData();
        this.vm.isLoading = false;
    }

    async initializePrelimsData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/mentorship_session/prelims_review_sessions/";
        const getStudentPrelimsReadingSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/prelims_topics_progress/";
        const getStudentPrelimsTopicProgress = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/prelims_topics_daily_targets/";
        const getStudentPrelimsTopicDailyTargets = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/question_bank/prelims_weak_areas_summary_report/";
        const getPrelimsWeakSummary = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/prelims_topics_daily_accuracy/";
        const getStudentPrelimsTopicDailyAccuracy = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/prelims_peer_comparison/";
        const getStudentPrelimsPeerComparison = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentPrelimsReadingSessions,                       // 0
            getStudentPrelimsTopicProgress,                         // 1
            getStudentPrelimsTopicDailyTargets,                     // 2
            getPrelimsWeakSummary,                                  // 3
            getStudentPrelimsTopicDailyAccuracy,                    // 4
            getStudentPrelimsPeerComparison,                        // 5
        ]).then(
            (value: any) => {
                // console.log("Prelims Data: ", value);
                this.vm.initializePrelimsData(value);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    async initializeMainsData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/mentorship_session/mains_review_sessions/";
        const getStudentMainsReadingSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/mains_topics_progress/";
        const getStudentMainsTopicProgress = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/mains_topics_daily_targets/";
        const getStudentMainsTopicDailyTargets = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/question_bank/mains_weak_areas_summary_report/";
        const getMainsWeakSummary = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/mains_topics_daily_accuracy/";
        const getStudentMainsTopicDailyAccuracy = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/mains_peer_comparison/";
        const getStudentMainsPeerComparison = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentMainsReadingSessions,                       // 0
            getStudentMainsTopicProgress,                         // 1
            getStudentMainsTopicDailyTargets,                     // 2
            getMainsWeakSummary,                                  // 3
            getStudentMainsTopicDailyAccuracy,                    // 4
            getStudentMainsPeerComparison,                        // 5
        ]).then(
            (value: any) => {
                // console.log("Mains Data: ", value);
                this.vm.initializeMainsData(value);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    async initializeCSATData() {
        this.vm.isLoading = true;
        let apiString = DJANGO_SERVER + "/api/mentorship_session/csat_review_sessions/";
        const getStudentCSATReadingSessions = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/csat_topics_progress/";
        const getStudentCSATTopicProgress = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/csat_topics_daily_targets/";
        const getStudentCSATTopicDailyTargets = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/question_bank/csat_weak_areas_summary_report/";
        const getCSATWeakSummary = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/csat_topics_daily_accuracy/";
        const getStudentCSATTopicDailyAccuracy = this.http.get(apiString).toPromise();

        apiString = DJANGO_SERVER + "/api/study_plan/csat_peer_comparison/";
        const getStudentCSATPeerComparison = this.http.get(apiString).toPromise();

        await Promise.all([
            getStudentCSATReadingSessions,                       // 0
            getStudentCSATTopicProgress,                         // 1
            getStudentCSATTopicDailyTargets,                     // 2
            getCSATWeakSummary,                                  // 3
            getStudentCSATTopicDailyAccuracy,                    // 4
            getStudentCSATPeerComparison,                        // 5
        ]).then(
            (value: any) => {
                // console.log("CSAT Data: ", value);
                this.vm.initializeCSATData(value);
            },
            (error) => {
                console.log(error);
            }
        );
    }
}